import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
  namespaced: true,
  state: {
    redemptions: []
  },
  getters: {
    redemptions: state => state.redemptions
  },
  mutations: {
    commitRedemptions(state, redemptions) {
      state.redemptions = redemptions;
    }
  },
  actions: {
    async submitRedemption(context, redemptionData) {
      return new Promise((resolve, reject) => {
        let form = new FormData();
        for (const key in redemptionData) {
          form.append(key, redemptionData[key]);
        }
        axios
          .post(`${process.env.VUE_APP_ROOT_API_URL}/redemption`, form, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    async getRedemptions(context, redemptionFilters) {
      return new Promise((resolve, reject) => {
        let query = util.methods.getQueryStringFromObj(redemptionFilters);
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/redemptions${query}`, {
            withCredentials: true
          })
          .then(response => {
            context.commit("commitRedemptions", response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    async getRedemptionsReport(context, redemptionFilters) {
      return new Promise((resolve, reject) => {
        let query = util.methods.getQueryStringFromObj(redemptionFilters);
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/redemptions${query}`, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    async removeRedemption(context, redemptionID) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_ROOT_API_URL}/redemption/${redemptionID}`, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    }
  }
};
