<template>
  <div class="counter-cont c-t-a">
    <div class="sub-btn b-t-w btn" @click.stop="add(-1)">-</div>
    <input class="counter-input c-t-a" ref="inoutNum" type="number"
      :value="value"
      @input="$emit('input', parseInt($event.target.value))"
      @click.stop="inputClicked"
      min=0
    />
    <div class="add-btn b-t-w btn" @click.stop="add(1)">+</div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  name: 'counter',
  props: {
    value: Number,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Infinity
    },
  },
  methods:{
    ...mapMutations({
      pushAlert: 'pushAlert',
    }),
    add(num){
      this.$emit('valueChanged')
      const value = parseInt(this.$refs.inoutNum.value) + num
      if(value > this.max){
        this.pushAlert({text: `maximum value is ${this.max}`, type: 'fail'})
      }
      else if(value < this.min){
        this.pushAlert({text: `minimum value is ${this.min}`, type: 'fail'})
      }
      else{
        this.$refs.inoutNum.value = value
        this.$emit('input', value)
      }
    },
    inputClicked(){

    }
  }
}
</script>

<style scoped>
.counter-cont{
  display: flex;
}
.counter-cont>*{
  width: 100%;
}
.add-btn{
  background: #48ac50;
  border-radius:0 .4em .4em 0;
}
.sub-btn{
  background: #ff4747;
  border-radius: .4em 0 0 .4em;
}
.counter-input{
  border-radius: 0;
  outline: 0;
  border: 0;
  background: rgb(235, 235, 235);
  width: 3em;
}
.btn{
  width: 2em;
  color: white;
  user-select: none;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>