import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
	namespaced: true,
	state: {
		barcodes: [],
		selectedBarcode: null
	},
	getters: {
		barcodes: (state) => state.barcodes,
		selectedBarcode: (state) => state.selectedBarcode,
	},
	mutations: {
		commitBarcodes(state, barcodes) {
			console.log("MUTATION CALLED", barcodes);
			state.barcodes = barcodes;
		},
		commitSelectedBarcode(state, selectedBarcode) {
			console.log("MUTATION CALLED", selectedBarcode);
			state.selectedBarcode = selectedBarcode;
		},
	},
	actions: {
		async getBarcodes(context, query) {
			query = util.methods.getQueryStringFromObj(query)
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/barcode-scanners${query}`, {
					withCredentials: true,
				})
				.then((response) => {
					context.commit('commitBarcodes', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getBarcode(context, barcodeID) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/barcode-scanner/${barcodeID}`, {
					withCredentials: true,
				})
				.then((response) => {
					context.commit('commitSelectedBarcode', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async createBarcode(context, barcodeData) {
			return new Promise((resolve, reject)=>{
				axios
				.post(`${process.env.VUE_APP_ROOT_API_URL}/barcode-scanner`,
				barcodeData,
				{ withCredentials: true })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async editBarcode(context, {barcodeData, barcodeID}) {
			return new Promise((resolve, reject)=>{
				axios
				.put(`${process.env.VUE_APP_ROOT_API_URL}/barcode-scanner/${barcodeID}`,
				barcodeData,
				{ withCredentials: true })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
	},
};
