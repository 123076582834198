<template>
  <div class="l-t-a">
    <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
    <div class="b-t-w l-t-a">Select Location</div>
    <v-select v-if="locations" placeholder="Location" class="select-box ms-t-s"
    label="name" v-model="selectedLocation" :options="locations"  style="width: 100%;">
      <template v-slot:option="option">
        <div class="m-t-s">{{ option.name }}</div>
        <div class="ms-t-s gray-t-c product-list-name">{{option.address}}</div>
      </template>
    </v-select>
    <div v-if="far" class="gray-t-c s-t-s">
      {{far.toFixed(2)}} km Away
    </div>
    <br>
    <div v-if="selectedLocation" class="selected-location">
      <div class="m-t-s lb-t-w">{{selectedLocation.name}}</div>
      <div class="ms-t-s gray-t-c">{{selectedLocation.address}}</div>
    </div>
    <div v-if="alertMsg" class="alert-msg">
      <p class="m-t-s">{{alertMsg}}</p>
      <div class="alert-btns ms-t-s">
          <BtnSubmit :enabled="enabled" v-on:clicked="confirmCheckIn">Check In</BtnSubmit>
          <BtnEnter v-on:clicked="clearAlert">Cancel</BtnEnter>
      </div>
    </div>
    <br>
    <table class="ms-t-s">
      <tr>
        <td class="b-t-w">Temperature:</td>
        <td><TextBox :placeHolder="'Ex: 37.2'" :type="'number'" v-on:valueChanged="tempr=$event"></TextBox></td>
        <td class="gray-t-c">°C</td>
      </tr>
      <tr>
        <td class="b-t-w">Barcode Scanner:</td>
        <td colspan="3"><TextBox :placeHolder="'code'" :type="'text'" v-on:valueChanged="barcodeScanner=$event"></TextBox></td>
      </tr>
    </table>
    <BtnSubmit :enabled="enabled" class="camera-button" @clicked="takePicture">
      {{!this.picture?'Take Photo':'Change Photo'}}
    </BtnSubmit>
    <image-uploader
      id="check-in-pic"
      :debug="1"
      :maxWidth="1000"
      :maxHeight="1000"
      :quality="0.7"
      outputFormat="verbose"
      :preview=false
      capture="user"
      class="camera"
      accept="image/*"
      doNotResize="['gif', 'svg']"
      @input="picUpdate"
    ></image-uploader>
    <div class="flex-row justify-center align-center w-100">
      <img v-show="picture" src="#" ref="checkInImg" class="w-100">
    </div>
    <!-- <span v-if="$refs" -->
    <BtnSubmit :enabled="enableCheckIn" v-on:clicked="callCheckIn" class="checkin-btn">Check In</BtnSubmit>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ImageUploader from 'vue-image-upload-resize'
import ErrorComment from '@/components/ErrorComment'

export default {
  name: 'checkIn',
  components:{
    ErrorComment,
    ImageUploader
  },
  data() {
    return {
      selectedLocation: null,
      far: null,
      userLocation: null,
      alertMsg: null,
      tempr: null,
      barcodeScanner: null,
      enabled: true,
      errorMessage: null,
      picture: null
    }
  },
  computed:{
    ...mapGetters({
      locations: 'location/locations',
      checkIn: 'checkIn/checkIn'
    }),
    enableCheckIn() {
      return this.enabled && this.selectedLocation && this.tempr && this.barcodeScanner && this.picture;
    }
  },
  methods:{
    ...mapActions({
      getLocations: 'location/getLocations',
      requestCheckIn: 'checkIn/checkIn'
    }),
    callCheckIn(){
      if(this.far<0.2){
        this.confirmCheckIn()
      }
      else{
        this.alertMsg = `You are ${this.far.toFixed(2)} Km away from this location, Are you sure you're in the correct location?`
      }
    },
    takePicture() {
      document.getElementById('check-in-pic').click()
    },
    async picUpdate(value){
      this.picture = await this.formatImage(value)
      let img = this.$refs.checkInImg;
      img.src = value.dataUrl
    },
    async confirmCheckIn(){
      this.enabled = false
      let checkInData = {
        locationID: this.selectedLocation.ID,
        temperature: this.tempr,
        barcodeScannerCode: this.barcodeScanner.replace(/(\r\n|\n|\r| )/gm, ""),
        file: this.picture
      }
      if(this.userLocation){
        checkInData.locationLon = this.userLocation.lng
        checkInData.locationLat = this.userLocation.lat
      }
      this.requestCheckIn(checkInData)
      .then((res)=>{
        this.$router.push('/agent')
      })
      .catch(error=>{
        console.log("error!!!:" +error)
        this.errorMessage = error.response.data.message
        window.scroll(0,0)
        this.enabled = true
      })
      this.clearAlert()
    },
    getClosest(coordinates){
      const _this = this
      let closest = null
      let far = Infinity
      this.locations.forEach(location => {
        const distance = _this.getDistance(location.locationLat, coordinates.lat, location.locationLon, coordinates.lng);
        console.log(distance,far)
        if(distance<far){
          far = distance
          closest = location
        }
      });
      this.far = far
      this.selectedLocation = closest
    },
    callLogout(){
      this.backEnabled = false
      this.logout()
      this.$router.push('/login')
    },
    clearAlert(){
      this.alertMsg = null;
    }
  },
  async mounted() {
    const _this = this;
    await this.getLocations()
    this.$getLocation({
      enableHighAccuracy: false, //defaults to false
      timeout: Infinity, //defaults to Infinity
      maximumAge: 0 //defaults to 0
    })
    .then(coordinates => {
      console.log(coordinates)
      _this.getClosest(coordinates)
      this.userLocation = coordinates
    });
    // console.log('ref > ', this.$refs.cameraUpload.files) ;
  },
  watch:{
    selectedLocation(value){
      if(value){
        this.$getLocation({
          enableHighAccuracy: false, //defaults to false
          timeout: Infinity, //defaults to Infinity
          maximumAge: 0 //defaults to 0
        })
        .then(coordinates => {
          console.log(coordinates)
          this.far = this.getDistance(value.locationLat, coordinates.lat, value.locationLon, coordinates.lng);
          console.log(this.far)
          this.userLocation = coordinates
        });
      }
      else{
        this.far = null
      }
    }
  }
}
</script>

<style scoped>
.checkin-btn{
    margin: 1em auto;
    display: flex !important;
    justify-content: center;
    width: 100% !important;
}
.alert-msg{
    position: fixed;
    width: 80%;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    min-width: 13em;
    padding: 1em;
    background-color: white;
    border: 1px #ff9393 solid;
    border-radius: 8px;
    z-index: 9999;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}
.alert-msg p{
    margin-bottom: 1em;
}
.alert-btns{
    display: flex;
    justify-content: space-around;
}
.selected-location{
  background: white;
  border-radius: .3em;
  padding: .3em;
  border: 1px lightgray solid;
}

.camera-button {
  position: relative;
  margin: 1.5em auto;
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.camera {
  display: none;
}

</style>
