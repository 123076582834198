<template>
  <transition name="alert">
    <div class="alert-cont"
    :class="alertMsg.type == 'success'?'succ':'err'">
      <div class="w-100">{{alertMsg.text}}</div>
      <div style="padding-right: .5em;" @click="pushAlert({})">✖</div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name:'mobileAlert',
  computed: {
    ...mapGetters({
      alertMsg: 'alertMsg'
    })
  },
  methods: {
    ...mapMutations({
      pushAlert: 'pushAlert'
    })
  },
  mounted() {
    setTimeout(()=>{
      this.pushAlert({})
    }, this.alertMsg.type=='success'?1500:4000)
  },
}
</script>

<style scoped>
.alert-cont{
  width: 90%;
  max-width: 20em;
  position: fixed;
  transform: translate(-50%, 0);
  left: 50%;
  top: 0;
  z-index: 999;
  display: flex;
  
  margin-top: 1em;
  padding: .3em;
  border-radius: .5em;
  
}
.succ{
  color: #caffa2;
  background: #46a046ed;
}
.err{
  background: #c33636ed;
  color: #ffd0d0;
}

.alert-enter-active, .alert-leave-active{
    position: fixed;
    transition: all .2s ease-in-out;
    top: 0;
}
.alert-enter{
    top: -5em;
}
.alert-leave-to{
  opacity: 0;
}
</style>
