<template>
  <div v-if="getGiveawayProducts">
    <div class="b-t-w m-t-s l-t-a">Giveaway Product:</div>
    <BtnSubmit :enabled="!submitting" class="camera-button" @clicked="takePicture">
      {{!this.picture?'Take Photo':'Change Photo'}}
    </BtnSubmit>
    <image-uploader
      id="redemption-pic"
      :debug="1"
      :maxWidth="1000"
      :maxHeight="1000"
      :quality="0.7"
      outputFormat="verbose"
      :preview=false
      capture="user"
      class="camera"
      accept="image/*"
      doNotResize="['gif', 'svg']"
      @input="picUpdate"
    ></image-uploader>
    <div class="flex-row justify-center align-center w-100">
      <img v-show="picture" src="#" ref="redemptionImg" class="w-100">
    </div>
    <v-select :disabled="picture == null" placeholder="Select giveaway product" class="select-box ms-t-s"
      label="name" v-model="selectedGiveawayProduct" :options="giveawayProducts" style="width: 100%;" @input="addItem($event)"></v-select>
    <div v-for="(item, index) in selectedGiveawayProducts" :key="index" class="items-box">
      <div class="ms-t-s l-t-a item-txt">
        <div>
          <span>{{index + 1}}. </span>
          <span>{{item.name}}</span>
        </div>
        <span><counter v-model="item.quantity" style="margin-right: 1em;"></counter></span>
      </div>
      <div @click="removeItem(item, index)" class="del-btn">✖</div>
    </div>
    <text-box type="textarea" title="Remarks" v-on:valueChanged="remarks=$event" ></text-box>
    <btn-submit :enabled="enabled && picture" class="submit-btn" @clicked="requestSubmitRedemption">Submit</btn-submit>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import ImageUploader from 'vue-image-upload-resize'
import Counter from '@/components/Counter.vue'

export default {
  name: 'redemptionForm',
  components: {
    ImageUploader,
    Counter,
  },
  computed: {
    ...mapGetters({
      giveawayProducts: 'engagement/giveawayProducts'
    }),
    enabled(){
      return(this.selectedGiveawayProducts.length && !this.submitting)
    },
  },
  data() {
    return {
      selectedGiveawayProduct: null,
      selectedGiveawayProducts: [],
      giveawayList: [],
      picture: null,
      remarks: null,
      submitting: false,
    }
  },
  methods: {
    ...mapActions({
      getGiveawayProducts: 'engagement/getGiveawayProducts',
      submitRedemption: 'redemption/submitRedemption',
    }),
    ...mapMutations({
      pushAlert: 'pushAlert',
    }),
    takePicture() {
      document.getElementById('redemption-pic').click()
    },
    async picUpdate(value){
      this.picture = await this.formatImage(value)
      let img = this.$refs.redemptionImg;
      img.src = value.dataUrl
    },
    async requestSubmitRedemption(){
      this.submitting = true
      let items = []
      this.selectedGiveawayProducts.forEach(item => {
        items.push({
          giveawayProductID: item.ID,
          quantity: item.quantity,
        })
      });
      await this.submitRedemption({
        file: this.picture,
        ...(this.remarks && {remarks: this.remarks}),
        items: JSON.stringify(items)
      })
      .then(res => {
        this.pushAlert({text: res.message, type: 'success'})
        this.submitting = false
        this.$router.push('/agent/redemptions');
      })
      .catch(err => {
        this.pushAlert({text:err.response.data.message, type: 'fail'})
        this.submitting = false
      })
    },
    addItem(item){
      this.selectedGiveawayProduct=null
      for (let i = 0; i < this.giveawayList.length; i++) {
        if(this.giveawayList[i].ID == item.ID){
          this.giveawayList.splice(i, 1);
          break;
        }
      }
      this.selectedGiveawayProducts.push({...item, quantity: 1});
    },
    removeItem(item, index){
      this.giveawayList.push(item)
      this.selectedGiveawayProducts.splice(index, 1)
    },
  },
  async mounted(){
    if(!(this.giveawayProducts?.length)){
      await this.getGiveawayProducts()
    }
    this.giveawayList = this.giveawayProducts
  }
}
</script>

<style scoped>
.camera-button {
  position: relative;
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.camera {
  display: none;
}
.submit-btn{
  margin: 1em auto;
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}
.select-box{
  margin-top: 1em;
}
.items-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: .3em;
  margin-top: .5em;
  overflow: auto;
  box-shadow: 0 0 .3em rgba(0, 0, 0, 0.2);
  align-items: center;
}
.item-txt{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left:.6em;
}
.del-btn{
  background: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
}
</style>