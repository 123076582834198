var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav-bar',{attrs:{"selected":'locations'}}),_c('body-cont',[(_vm.myProfile.user_account.type == 'admin')?_c('btn-enter',{staticClass:"ms-t-s",staticStyle:{"float":"right","margin-bottom":"1em"},on:{"clicked":function($event){return _vm.$router.push('/cms/location-form/create')}}},[_vm._v("Create Location")]):_vm._e(),(_vm.locations.length)?_c('mod-table',{attrs:{"tableItems":_vm.locations,"cols":{
      ID:{class:'cell-mid', type:'text'},
      region:{class:'cell-mid', type:'text', alias:'Region'},
      name:{class:'cell-left', type:'text', alias:'Name'},
      account:{class:'cell-mid', type:'text', alias:'Account'},
      city:{class:'cell-mid', type:'text', alias:'City'},
      state:{class:'cell-mid', type:'text', alias:'State'},
      'manager.displayName':{class:'cell-mid', type:'text', alias:'Manager'},
      }},on:{"clickedItem":function($event){return _vm.$router.push(("/cms/location-form/edit/" + ($event.ID)))}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }