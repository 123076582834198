import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
	namespaced: true,
	state: {
		inventories: [],
		inventory: null,
	},
	getters: {
		inventories: (state) => state.inventories,
		inventory: (state) => state.inventory,
	},
	mutations: {
		commitInventories(state, inventories) {
			console.log("MUTATION CALLED", inventories);
			state.inventories = inventories;
		},
		commitInventory(state, inventory) {
			console.log("MUTATION CALLED", inventory);
			state.inventory = inventory;
		},
	},
	actions: {
		async getInventories(context, query) {
			context.commit('commitInventories', {})
			query = util.methods.getQueryStringFromObj(query)
			
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/inventories${query}`, {
					withCredentials: true,
				})
				.then((response) => {
					context.commit('commitInventories', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getInventoriesReport(context, query) {
			query = util.methods.getQueryStringFromObj(query)
			
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/inventories${query}`, {
					withCredentials: true,
				})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getStockEntriesReport(context, query) {
			query = util.methods.getQueryStringFromObj(query)
			
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/stock-entry${query}`, {
					withCredentials: true,
				})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getInventory(context, inventoryID) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/inventory/${inventoryID}`, {
					withCredentials: true,
				})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async countStock(context, {productID, inventoryData}) {
			return new Promise((resolve, reject)=>{
				let formData = new FormData();
				if (inventoryData.img) {
					formData.append("file", inventoryData.img, inventoryData.img.fileName);
				}
				delete inventoryData.img;
				for (let i = 0; i < Object.keys(inventoryData).length; i++) {
					formData.append(
						Object.keys(inventoryData)[i],
						Object.values(inventoryData)[i]
					);
				}
				axios
				.put(`${process.env.VUE_APP_ROOT_API_URL}/inventory/product/${productID}`,
				formData,
				{	withCredentials: true, })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getStock(context, {locationID, productID}) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/inventory/location/${locationID}/product/${productID}`,
				{	withCredentials: true, })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getStockEntries(context, inventoryID) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/stock-entry/inventory/${inventoryID}`,
				{	withCredentials: true, })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
	},
};
