import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
	namespaced: true,
	state: {
		checkIn: null,
		checkIns: {},
	},
	getters: {
		checkIn: (state) => state.checkIn,
		checkIns: (state) => state.checkIns,
	},
	mutations: {
		commitCheckIn(state, checkIn) {
			console.log("MUTATION CALLED", checkIn);
			state.checkIn = checkIn;
		},
		commitCheckIns(state, checkIns) {
			console.log("MUTATION CALLED", checkIns);
			state.checkIns = checkIns;
		},
	},
	actions: {
		async getOngoingCheckIn(context) {
			return new Promise(resolve => {
				axios
					.get(`${process.env.VUE_APP_ROOT_API_URL}/check-in`, {
						withCredentials: true,
					})
					.then((response) => {
						context.commit('commitCheckIn', response.data)
						resolve(response.data)
					})
					.catch((error) => {
						console.log(error);
						resolve(error)
					});
			})
		},
		async checkIn(context, checkInData) {
			let form = new FormData();
			for (const key in checkInData) {
				form.append(key, checkInData[key]);
			}
			return new Promise((resolve, reject) => {
				axios
					.post(`${process.env.VUE_APP_ROOT_API_URL}/check-in`, form, {
						withCredentials: true,
					})
					.then((response) => {
						context.commit("commitCheckIn", response.data.checkIn);
						resolve(response);
					})
					.catch((error) => {
						reject(error);
						console.log(error);
					});
			});
		},
		async checkOut(context, checkOutData) {
			let form = new FormData();
			for (const key in checkOutData) {
				form.append(key, checkOutData[key]);
			}
			return new Promise((resolve, reject) => {
				axios
					.put(`${process.env.VUE_APP_ROOT_API_URL}/check-out`, form, {
						withCredentials: true,
					})
					.then((response) => {
						context.commit('commitCheckIn', null)
						resolve(response.data)
					})
					.catch((error) => {
						console.log(error);
						reject(error)
					});
			})
		},
		async getCheckIns(context, query) {
			context.commit('commitCheckIns', {})
			return new Promise((resolve, reject)=>{
				query = util.methods.getQueryStringFromObj(query)
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/check-ins${query}`,
				{	withCredentials: true, })
				.then((response) => {
					context.commit('commitCheckIns', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getCheckInsReport(context, query) {
			return new Promise((resolve, reject)=>{
				query = util.methods.getQueryStringFromObj(query)
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/check-ins${query}`,
				{ withCredentials: true })
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					console.log('error > ', error);
					reject(error);
				})
			});
		}
	},
};
