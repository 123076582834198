<template>
  <div class="selector-cont">
    <div class="pages-cont s-t-s">
      <div @click="parseInt(selectedPage)>1?indexClicked(parseInt(selectedPage)-1):false" class="arrow-btn"><img :src="require('@/assets/simple-arrow-down.svg')" alt="" class="btn-left selectable"></div>
      <div v-for="(item, index) in pageIndexes" :key="index">
        <div class="g-t-c page-box" :class="{selected: item.value==parseInt(selectedPage), selectable: item.selectable}"
        @click="item.selectable?indexClicked(item.value):false">{{item.value}}</div>
      </div>
      <div @click="parseInt(selectedPage)<pages?indexClicked(parseInt(selectedPage)+1):false" class="arrow-btn"><img :src="require('@/assets/simple-arrow-down.svg')" alt="" class="btn-right selectable"></div>
    </div>
    <div class="index-cont s-t-s">
      <div class="only-large">Go to page</div>
      <div><input class="index-input c-t-a" v-model="pageInput" type="number" :max="pages" :min="1"></div>
      <div class="go-btn arrow-btn selectable" @click="indexClicked(pageInput)"><div class="only-large" style="margin-right: .4em">GO</div> <img :src="require('@/assets/simple-arrow-down.svg')" alt="" class="btn-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-selector',
  props:['count', 'selectedPage', 'pageLimit'],
  data() {
    return {
      pageIndexes:[],
      pageInput: 1
    }
  },
  computed:{
    pages(){
      let pagesCount = Math.ceil(this.count/this.pageLimit)
      this.calcPages(this.selectedPage, pagesCount)
      return(pagesCount)
    }
  },
  methods:{
    calcPages(selectedPage, pages){
      selectedPage = parseInt(selectedPage)
      this.pageIndexes = []
      let dotsBefore = true
      let dotsAfter = true
      for(let i = 1; i<pages+1; i++){
        if(i<3 || !(selectedPage+1<i || selectedPage-1>i) || i>pages-2){
          this.pageIndexes.push({value: i, selectable: true})
        }
        else if (selectedPage+1<i&&dotsBefore){
          this.pageIndexes.push({value: '...', selectable: false})
          dotsBefore = false
        }
        else if (selectedPage-1>i&&dotsAfter){
          this.pageIndexes.push({value: '...', selectable: false})
          dotsAfter = false
        }
      }
    },
    indexClicked(index){
      this.pageInput = index
      this.$emit('pageSelected', index)
      window.scroll(0,0)
    }
  },
  mounted(){
    this.calcPages(this.selectedPage)
    this.pageInput = this.selectedPage
  },
  watch:{
    selectedPage(value){
      this.calcPages(value, this.pages)
    }
  }
}
</script>

<style scoped>
.selector-cont{
  border: 1px lightgray solid;
  border-radius: .2em;
  display: flex;
  user-select: none;
  margin-top: 1em;
      float: right;
    width: fit-content;
}

.pages-cont{
  padding: .4em;
  border-right: 1px lightgray solid;
  display: flex;
  align-items: center;
}
.index-cont{
  padding: .4em;
  display: flex;
  align-items: center;
}
.index-cont>div{
  margin: 0 .4em;
}
.index-input{
  width: 2.5em;
  padding: .3em;
  border: 1px #F36711 solid;
  border-radius: .2em;
}
.go-btn{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn-left{
  transform: rotate(90deg) scale(1.3);
  transform-origin: center;
}
.btn-right{
  transform: rotate(-90deg) scale(1.3);
  transform-origin: center;
}
.page-box{
  border-radius: .3em;
  padding: 0 .5em;
  margin: 0 .2em;
}
.selectable{
  cursor: pointer;
}
.selectable:hover{
  background-color: rgb(236, 236, 236);
}

.selected{
  background-color: #F36711;
  color: white;
  font-weight: 700;
}
.arrow-btn{
  padding: .5em .8em;
  margin: 0 .4em;
  border-radius: .4em;
}
.arrow-btn:hover{
  background-color: rgb(236, 236, 236);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>