<template>
  <div>
    <NavBar></NavBar>
    <body-cont id="body-cont">
    <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
      <div v-if="myProfile">
        <table  class="m-t-s l-t-a">
          <tr>
            <td class="b-t-w">User ID: </td>
            <td>{{myProfile.ID}}</td>
          </tr>
          <tr>
            <td class="b-t-w">User Name: </td>
            <td>{{myProfile.displayName}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Email: </td>
            <td>{{myProfile.user_account.email}}</td>
          </tr>
          <tr>
            <td class="b-t-w">User Type: </td>
            <td>{{myProfile.user_account.type=='admin'?'Administrator':capitalizeFirstLetter(myProfile.user_account.type)}}</td>
          </tr>
          <br>
        </table>
        <btn-enter v-if="!changingPassword" v-on:clicked="changingPassword=true">Change Password</btn-enter>
        <div v-else>
          <TextBox :type="'password'" title="Old Password:" v-on:valueChanged="oldPassword=$event" :min=6 :placeHolder="'Enter same password'"></TextBox>
          <TextBox :type="'password'" title="New Password:" v-on:valueChanged="password=$event" :min=6 :placeHolder="'Enter a password'"></TextBox>
          <br>
          <BtnSubmit :enabled="enabled&&password&&oldPassword"
          v-on:clicked="requestChangePassword">Save Password</BtnSubmit>
        </div>
      </div>
    </body-cont>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar'
import ErrorComment from '@/components/ErrorComment'

export default {
  components:{
    ErrorComment,
    BodyCont,
    NavBar,
  },
  data() {
    return {
      changingPassword: false,
      password: null,
      oldPassword: null,
      errorMessage: null,
      enabled: true,
    }
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile'
    })
  },
  methods:{
    ...mapActions({
      changePassword: 'userProfile/changePassword',
      logout: 'logout',
    }),
    async requestChangePassword(){
      this.enabled = false
      if(!this.password||!this.oldPassword){
        this.enabled = true
        this.errorMessage = "Please enter old and new password"
        return
      }
      await this.changePassword({oldPassword: this.oldPassword, password:this.password})
      .then((res)=>this.logout())
      .catch(err=>{
        this.errorMessage = err.response.data.message
        window.scroll(0,0)
        this.enabled = true
      })
      if(res.status==200){
        // this.$router.push('/scp/stockEntry')
        this.logout()
      }
    }
  }
}
</script>

<style scoped>
#body-cont{
  max-width: 50em;
}
</style>