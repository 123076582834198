<template>
  <div>
    <nav-bar :selected="'barcodes'"></nav-bar>
    <body-cont>
      <btn-enter v-if="myProfile.user_account.type == 'admin'"
      style="float: right; margin-bottom: 1em" class="ms-t-s"
      @clicked="$router.push('/cms/barcode-form/create')">Create Barcode</btn-enter>

      <mod-table v-if="barcodes.length" :tableItems="barcodes"
      v-on:clickedItem="$router.push(`/cms/barcode-form/edit/${$event.ID}`)"
        :cols="{
        ID:{class:'cell-mid', type:'text'},
        code:{class:'cell-mid', type:'text', alias:'Code'},
        model:{class:'cell-left', type:'text', alias:'Model'},
        }">
      </mod-table>
    </body-cont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BodyCont from '@/components/BodyCont'
import NavBar from '../../components/NavBar.vue'
import ModTable from '../../components/ModTable.vue'
export default {
  name: 'barcodes',
  components:{
    NavBar,
    BodyCont,
    ModTable,
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile',
      barcodes: 'barcode/barcodes',
    })
  },
  methods:{
    ...mapActions({
      getBarcodes: 'barcode/getBarcodes'
    })

  },
  mounted() {
    this.getBarcodes()
  },
}
</script>

<style scoped>

</style>