import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import userProfile from "./modules/userProfile";
import checkIn from "./modules/checkIn";
import barcode from "./modules/barcodeScanner";
import location from "./modules/location";
import product from "./modules/product";
import inventory from "./modules/inventory";
import sale from "./modules/sale";
import engagement from "./modules/engagement";
import competitor from "./modules/competitor";
import redemption from "./modules/redemption";

const axios = Axios.create({
  withCredentials: true
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    userProfile,
    checkIn,
    barcode,
    location,
    product,
    inventory,
    sale,
    engagement,
    competitor,
    redemption
  },
  state: {
    isLoggedIn: false,
    alertMsg: {}
  },
  getters: {
    alertMsg: state => state.alertMsg
  },
  mutations: {
    logIn(state) {
      state.isLoggedIn = true;
    },
    logOut(state) {
      state.isLoggedIn = false;
    },
    pushAlert(state, msg) {
      state.alertMsg = msg;
    }
  },
  actions: {
    async login(context, loginData) {
      console.log("calling login");
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_ROOT_API_URL}/Login`, {
            userIdentifier: loginData.userIdentifier,
            password: loginData.password
          })
          .then(response => {
            if (response.status == 200) {
              context.commit("userProfile/commitMyProfile", response.data.userProfile, {
                root: true
              });
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async logout(context) {
      axios
        .get(`${process.env.VUE_APP_ROOT_API_URL}/logout`)
        .then(response => {
          //successful logout
          context.commit("userProfile/commitMyProfile", null, { root: true });
          context.commit("logOut");
          window.location.href = "/login";
        })
        .catch(error => {
          router.push("/");
          console.log(error);
        });
    }
  }
});
