import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
  namespaced: true,
  state: {
    agentEngagement: {
      competitorUsers: [],
      redemptions: []
    },
    engagements: [],
    competitorCompanies: [],
    giveawayProducts: []
  },
  getters: {
    agentEngagement: state => state.agentEngagement,
    competitorCompanies: state => state.competitorCompanies,
    giveawayProducts: state => state.giveawayProducts,
    engagements: state => state.engagements
  },
  mutations: {
    commitAgentEngagement(state, agentEngagement) {
      state.agentEngagement = agentEngagement;
    },
    commitCompetitorCompanies(state, competitorCompanies) {
      state.competitorCompanies = competitorCompanies;
    },
    commitGiveawayProducts(state, giveawayProducts) {
      state.giveawayProducts = giveawayProducts;
    },
    commitEngagements(state, engagements) {
      state.engagements = engagements;
    }
  },
  actions: {
    async getEngagement(context, engagementID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/engagement/${engagementID}`, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    async getCompetitorCompanies(context) {
      return new Promise(resolve => {
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/competitor-companies`, {
            withCredentials: true
          })
          .then(response => {
            context.commit("commitCompetitorCompanies", response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            resolve(error);
          });
      });
    },
    async getGiveawayProducts(context, query) {
      return new Promise(resolve => {
        query = util.methods.getQueryStringFromObj(query);
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/giveaway-products${query}`, {
            withCredentials: true
          })
          .then(response => {
            context.commit("commitGiveawayProducts", response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            resolve(error);
          });
      });
    },
    async submitEngagements(context, engagementsData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_ROOT_API_URL}/engagement`, engagementsData, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            // console.log(error);
            reject(error);
          });
      });
    },
    async editEngagements(context, { engagementData, engagementID }) {
      return new Promise((resolve, reject) => {
        console.log(engagementData);
        axios
          .put(`${process.env.VUE_APP_ROOT_API_URL}/engagement/${engagementID}`, engagementData, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            // console.log(error);
            reject(error);
          });
      });
    },
    async getEngagement(context, engagementID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/engagement/${engagementID}`, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            // console.log(error);
            reject(error);
          });
      });
    },
    async getAllEngagements(context, query) {
      return new Promise((resolve, reject) => {
        query = util.methods.getQueryStringFromObj(query);
        context.commit("commitEngagements", []);
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/engagement${query}`, {
            withCredentials: true
          })
          .then(response => {
            context.commit("commitEngagements", response.data);
            resolve(response.data);
          })
          .catch(error => {
            // console.log(error);
            reject(error);
          });
      });
    },
    async getEngagementsReport(context, query) {
      return new Promise((resolve, reject) => {
        query = util.methods.getQueryStringFromObj(query);
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/engagement-report/${query}`, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            // console.log(error);
            reject(error);
          });
      });
    }
  }
};
