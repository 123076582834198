<template>
  <div>
    <nav-bar :selected="'users'"></nav-bar>
    <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
    <body-cont style="max-width: 40em; overflow: visible;">
      <v-select v-if="userTypes" placeholder="User Type" class="select-box m-t-s" v-model="selectedType" :options="userTypes.create">
      </v-select>
      <br>
      <div v-if="this.selectedType">
        <text-box type="text" title="Display Name" v-on:valueChanged="name=$event" ></text-box><br>
        <div v-if="selectedType == 'sales_agent'">
          <div class="b-t-w l-t-a">Manager:</div>
          <v-select v-if="managers" placeholder="User Type" :label="'displayName'" class="select-box m-t-s" v-model="selectedManager" :options="managers">
          </v-select><br>
        </div>
        <text-box type="email" title="Email:" v-on:valueChanged="email=$event" ></text-box><br>
        <text-box type="password" title="Password:" v-on:valueChanged="password=$event" ></text-box><br>
      </div>
      <btn-submit
      :enabled="name&&email&&password&&(selectedType != 'sales_agent' || selectedManager)"
      @clicked="requestCreateUser"
      >Create User</btn-submit>
    </body-cont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ErrorComment from '@/components/ErrorComment'
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'createUser',
  components:{
    BodyCont,
    NavBar,
    ErrorComment,
  },
  computed:{
    ...mapGetters({
      userTypes: 'userProfile/userTypes',
    }),
  },
  data() {
    return {
      selectedType: null,
      name: null,
      email: null,
      password: null,
      selectedManager: null,
      managers: [],
      errorMessage: null
    }
  },
  methods: {
    ...mapActions({
      getUserTypes: 'userProfile/getUserTypes',
      fetchUsersByType: 'userProfile/fetchUsersByType',
      createUser: 'userProfile/createUser',
    }),
    requestCreateUser(){
      let userData = {
        displayName: this.name,
        email: this.email,
        password: this.password,
        type: this.selectedType,
        managerID: this.selectedManager?.ID,
      }
      this.createUser(userData)
      .then(res=>{
        this.$router.push('/cms/users/'+this.selectedType)
      })
      .catch(err=>{
        this.errorMessage = err.response.data.message
        window.scroll(0,0)
      })
    }
  },
  async mounted() {
    await this.getUserTypes()
    this.fetchUsersByType('manager')
    .then(res=>{
      this.managers = res
    })
  },
}
</script>

<style scoped>
</style>