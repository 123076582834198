<template>
  <div>
    <nav-bar :selected="'inventory'"></nav-bar>

    <body-cont>
      <div class="btns-cont">
        <BtnEnter class="ms-t-s" v-on:clicked="downloadCSV('inventory')">Inventory Report <font-awesome-icon :icon="['fas', 'download']"/></BtnEnter>
        <BtnEnter class="ms-t-s" v-on:clicked="downloadCSV('stock_entry')">Stock Entries Report <font-awesome-icon :icon="['fas', 'download']"/></BtnEnter>
      </div>
      <div class="filter-box ms-t-s">
        <v-select class="filter-item m-t-s" placeholder="Product" v-model="selectedProduct" label='name' :options="products" @input="updateQuery({page:1, productID: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Location" v-model="selectedLocation" label='name' :options="locations" @input="updateQuery({page:1, locationID: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Region" v-model="selectedRegion" :options="region" @input="updateQuery({page:1, region: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="State"  v-model="selectedState" :options="state" @input="updateQuery({page:1, state: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="City"  v-model="selectedCity" :options="city" @input="updateQuery({page:1, city: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Account" v-model="selectedAccount" :options="account" @input="updateQuery({page:1, account: $event})"></v-select>
      </div>
      <mod-table v-if="inventories.count" :tableItems="inventories.rows"
      v-on:clickedItem="selectInventory($event)"
        :cols="{
        ID:{class:'cell-mid', type:'text'},
        Product:{class:'cell-mid', type:'text', alias:'Product Name'},
        SKU:{class:'cell-left', type:'text'},
        Location:{class:'cell-mid', type:'text'},
        Account:{class:'cell-mid', type:'text'},
        Region:{class:'cell-mid', type:'text'},
        State:{class:'cell-mid', type:'text'},
        City:{class:'cell-mid', type:'text'},
        Quantity:{class:'cell-mid', type:'quantity'},
        Price:{class:'cell-mid', type:'text'},
        SoldToday:{class:'cell-mid', type:'text', alias:'Sold Today'},
        LastCounted:{class:'cell-mid', type:'datetime', alias:'Last Count'},
        }">
      </mod-table>
      <page-selector v-if="inventories&&inventories.count" :count="inventories.count" :selectedPage="page" :pageLimit="pageLimit"
        @pageSelected="updateQuery({page: $event})"></page-selector>
    </body-cont>
    <popup-full-screen v-if="selectedInvetntory" @exit="selectedInvetntory=null; stockEntries=[]" title='Stock Entries'>
      <div v-for="stockEntry in stockEntries" :key="stockEntry.ID" class="stock-entry-cont">
        <div>
          <div class="w-100 s-t-s l-t-a gray-t-c" style="border-bottom: 1px lightgray solid;">{{stockEntry.ID}}</div>
          <table class="l-t-a ms-t-s">
            <tr>
              <td class="b-t-w">Quantity: </td>
              <td>{{formatQuantity(stockEntry.quantity)}}</td>
            </tr>
            <tr>
              <td class="b-t-w">Price: </td>
              <td>RM{{stockEntry.price.toFixed(2)}}</td>
            </tr>
            <tr>
              <td class="b-t-w">Sales Agent: </td>
              <td>{{stockEntry.user_account.sales_agents[0].displayName}}</td>
            </tr>
            <tr>
              <td class="b-t-w">Counted At: </td>
              <td>{{formatDateTime(stockEntry.created_at)}}</td>
            </tr>
            <tr v-if="stockEntry.remarks&&stockEntry.remarks!='null'">
              <td class="b-t-w">Remarks: </td>
              <td>{{stockEntry.remarks}}</td>
            </tr>
            <tr v-else><td class="gray-t-c">No remarks</td></tr>
          </table>
        </div>
        <image-component :imgURL="stockEntry.image" imgWidth="10em"></image-component>
      </div>
    </popup-full-screen>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import utils from '@/lib/util';
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar.vue'
import ModTable from '../../components/ModTable.vue'
import PopupFullScreen from '@/components/PopupFullScreen'
import ImageComponent from '@/components/ImageComponent.vue'
import PageSelector from '@/components/PageSelector'

export default {
  name: 'inventory',
  components:{
    NavBar,
    BodyCont,
    ModTable,
    PopupFullScreen,
    ImageComponent,
    PageSelector,
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile',
      inventories: 'inventory/inventories',
      products: 'product/products',
      locations: 'location/locations',
      region: 'location/region',
      state: 'location/state',
      account: 'location/account',
      city: 'location/city',
    }),
    referenceLocationID(){
      return(this.$route.params.locationID)
    },
  },
  data() {
    return {
      selectedProduct: null,
      selectedLocation: null,
      selectedRegion: null,
      selectedState: null,
      selectedCity: null,
      selectedAccount: null,
      selectedInvetntory: null,
      stockEntries: [],
      pageLimit: 20,
      page: 1
    }
  },
  methods:{
    ...mapActions({
      getInventories: 'inventory/getInventories',
      getInventoriesReport: 'inventory/getInventoriesReport',
      getStockEntriesReport: 'inventory/getStockEntriesReport',
      getStockEntries: 'inventory/getStockEntries',
      getProducts: 'product/getProducts',
      getLocations: 'location/getLocations',
      getLocationKey: 'location/getKeys',
    }),
    async downloadCSV(type) {
      let report = []
      switch (type) {
        case 'inventory':
          report = await this.getInventoriesReport({...this.$route.query})
          break;
        case 'stock_entry':
          report = await this.getStockEntriesReport({...this.$route.query})
          break;
      
        default:
          break;
      }
      let filename = `${type}_report_${utils.methods.formatDate(new Date())}`;
      filename = filename.replace(/-/g, "_").replace(/:/g, "_");
      utils.methods.downloadCSV({ name: filename, data: report.rows });
    },
    updateQuery(newQuery){
      let query = {...this.$route.query}

      Object.keys(newQuery).forEach(queryKey => query[queryKey] = newQuery[queryKey] ?? undefined);
      this.$router.push({query}).catch(err=>{})
      this.page = query.page ?? 1
      query.limit = this.pageLimit
      query.offset = this.pageLimit*(this.page-1)
      delete query.page
      console.log(query)
      this.getInventories(query)
    },
    async selectInventory(selectedInvetntory){
      this.selectedInvetntory = selectedInvetntory
      this.stockEntries = await this.getStockEntries(this.selectedInvetntory.ID)
    },
  },
  async mounted() {
    this.updateQuery({})
    !this.products?.length?await this.getProducts():false
    !this.locations?.length?await this.getLocations():false
    !this.region?.length?this.getLocationKey('account'):false
    !this.region?.length?this.getLocationKey('region'):false
    !this.state?.length?this.getLocationKey('state'):false
    !this.city?.length?this.getLocationKey('city'):false
    this.$route.query?.productID ? this.selectedProduct = this.products.find(product=>product.ID == this.$route.query.productID) : null
    this.$route.query?.locationID ? this.selectedLocation = this.locations.find(location=>location.ID == this.$route.query.locationID) : null
    this.selectedAccount = this.$route.query?.account
    this.selectedRegion = this.$route.query?.region
    this.selectedState = this.$route.query?.state
    this.selectedCity = this.$route.query?.city
  }
}
</script>

<style scoped>
.inv-tbl{
  width: 100%;
  border-collapse: collapse;
}
.inv-tbl td, th{
  padding: .8em .2em;
  border: 1px #E3E3E3 solid;
}
.filter-box{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.7em;
  margin: 1em 0;
  justify-content: flex-start;
}
.filter-item{
    width:16%;
    min-width: 15em;
    margin-right: 0.5em;
}
.csv-btn{
  float: right;
  margin-bottom: 1em;
}
.stock-entry-cont{
  display: flex;
  justify-content: space-between;
  border: 1px lightgray solid;
  padding: .4em .8em;
  border-radius: .8em;
  margin-bottom: 1em;
  max-width: 80em;
  margin: 1em;
  box-shadow: 0 .3em .5em rgba(0, 0, 0, 0.3);
}
.btns-cont{
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-bottom: 1em;
}
.btns-cont>*{
  margin-left: .5em;
}
@media only screen and (max-width: 600px) {
  .stock-entry-cont{
    display: inline-block;
    font-size: .7em;
  }
}
</style>
