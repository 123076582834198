<template>
  <div class="box-cont" @click="toggleCheckBox">
    <div class="box" :style="checkStatus ? 'background: #F36711;' : 'background: white;'">
      <div v-if="checkStatus" class="m-t-s">✔</div>
    </div>
    <div class="gray-t-c">{{title}}</div>
  </div>
</template>

<script>
export default {
  name: 'checkBox2',
  props: ['value', 'title'],
  data () {
    return {
      checkStatus: this.value
    }
  },
  methods: {
    toggleCheckBox (e) {
      this.checkStatus = !this.checkStatus
      this.$emit('input', this.checkStatus)
    }
  },
  watch:{
    value(value){
      this.checkStatus = value
    }
  }
}
</script>

<style scoped>
.box-cont{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.box{
  width: 1.5em;
  height: 1.5em;
  margin-right: .5em;
  border-radius: .4em;
  border: 2px #F36711 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>