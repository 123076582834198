import axios from "axios";
import Vue from "vue";
import util from "../../lib/util";
import router from "@/router";

axios.defaults.withCredentials = true;

export default {
	namespaced: true,
	state: {
		myProfile: null,
		userTypes: null,
		createUserTypes: [],
		users: [],
		selectedUser: {},
	},
	getters: {
		myProfile: (state) => state.myProfile,
		userTypes: (state) => state.userTypes,
		selectedUser: (state) => state.selectedUser,
		users: (state) => state.users,
	},
	mutations: {
		commitMyProfile(state, userProfile) {
			console.log("MUTATION CALLED", userProfile);
			state.myProfile = userProfile;
		},
	},
	actions: {
		async getMyProfile(context) {
			return new Promise(resolve=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/my-profile`, {
					withCredentials: true,
				})
				.then((response) => {
					context.commit('commitMyProfile', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					resolve(error)
				});
			})
		},
		// async updateMyProfile(context, userData) {
		// 	return new Promise(resolve=>{
		// 		axios
		// 		.post(`${process.env.VUE_APP_ROOT_API_URL}/updateProfile`,
		// 		userData,
		// 		{withCredentials: true}
		// 		)
		// 		.then((response) => {
		// 			resolve(response)
		// 		})
		// 		.catch((error) => {
		// 			console.log(error);
		// 			resolve(error)
		// 		});
		// 	})
		// },
		async getUserTypes(context) {
			return new Promise(resolve=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/user-types`, {
					withCredentials: true,
				})
				.then((response) => {
					Vue.set(context.state, "userTypes", response.data);
					console.log(response.data);
					resolve(response)
				})
				.catch((error) => {
					console.log(error);
					resolve(error)
				});
			})
		},
		async fetchUsersByType(context, userType) {
			return new Promise((resolve, reject)=>{
				Vue.set(context.state, "usersList", null);
				axios
					.get(`${process.env.VUE_APP_ROOT_API_URL}/user-profile/${userType}`, {
						withCredentials: true,
					})
					.then((response) => {
						Vue.set(context.state, "users", response.data);
						console.log(response.data)
						resolve(response.data);
					})
					.catch((error) => {
						reject(error);
					});
			})
		},
		async fetchUserByTypeAndID(context, userData) {
			Vue.set(context.state, "selectedUser", null);
			return new Promise((resolve) => {
				axios
					.get(
						`${process.env.VUE_APP_ROOT_API_URL}/user-profile/${userData.type}/${userData.ID}`,
						{
							withCredentials: true,
						}
					)
					.then((response) => {
						Vue.set(context.state, "selectedUser", response.data);
						resolve(response.data);
					})
					.catch((error) => {
						console.log(error);
						resolve(error);
					});
			});
		},
		async createUser(context, userData) {
			return new Promise((resolve, reject)=>{
				axios
				.post(
					`${process.env.VUE_APP_ROOT_API_URL}/createAccount`,
					userData,
					{ withCredentials: true }
				)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
			})
		},
		async changePassword(context, password) {
			return new Promise((resolve, reject) => {
				axios
					.put(`${process.env.VUE_APP_ROOT_API_URL}/changePassword`,
					password,
					 {withCredentials: true})
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
    },
		async changeUserPassword(context, passwordDetails) {
			return new Promise((resolve, reject) => {
				axios
					.put(`${process.env.VUE_APP_ROOT_API_URL}/changePassword/${passwordDetails.type}/${passwordDetails.profileID}`,
					{password: passwordDetails.password},
					 {withCredentials: true})
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
    },
		async updateUserStatus(context, statusData) {
			return new Promise((resolve, reject) => {
				axios
					.put(`${process.env.VUE_APP_ROOT_API_URL}/user/${statusData.type}/${statusData.ID}/change-status`,
					{status: statusData.status},
					 {withCredentials: true})
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject(error);
					});
			});
    },
		async updateUserProfile(context, user) {
			return new Promise(resolve=>{
				axios
				.put(
					`${process.env.VUE_APP_ROOT_API_URL}/user-profile/${user.type}/${user.ID}`,
					user.data,
					{ withCredentials: true }
				)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					resolve(error);
				});
			})
		},
		// async disableAccount(context, user) {
		// 	return new Promise(resolve=>{
		// 		axios
		// 		.post(
		// 			`${process.env.VUE_APP_ROOT_API_URL}/disable-account`,
		// 			user,
		// 			{ withCredentials: true }
		// 		)
		// 		.then((response) => {
		// 			resolve(response);
		// 		})
		// 		.catch((error) => {
		// 			resolve(error);
		// 		});
		// 	})
		// },
	},
};
