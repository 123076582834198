<template>
  <div >
    <div id="agentView">
      <mobile-alert v-if="alertMsg.text"></mobile-alert>
      <transition name="fall">
        <router-view v-if="!loading"></router-view>
      </transition>
    </div>
    <menu-bar></menu-bar>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import MenuBar from '@/components/MenuBar'
import MobileAlert from '@/components/MobileAlert'

export default {
  name: 'agent',
  components:{
    MenuBar,
    MobileAlert
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile',
      checkIn: 'checkIn/checkIn',
      alertMsg: 'alertMsg',
    })
  },
  data() {
    return {
      loading: true,
    }
  },
  methods:{
    ...mapActions({
      getOngoingCheckIn: 'checkIn/getOngoingCheckIn'
    }),
    ...mapMutations({
      pushAlert: 'pushAlert'
    })
  },
  async mounted(){
    if(this.myProfile.user_account.type !=  'sales_agent'){
      this.$router.push('/cms')
    }
    await this.getOngoingCheckIn()
    if(!this.checkIn){
      this.$router.push('/agent/check-in')
    }
    this.loading = false
  }
}
</script>

<style scoped>
#agentView{
  padding: 0 .8em;
  max-width: 30em;
  margin: auto;
  margin-top: 3.5em;
  height: 100%;
  position: relative;
  height: calc(100vh - 3.5em);
}


.fall-enter-active, .fall-leave-active{
    position: absolute;
    transition: all .2s ease-in-out;
    transform: scale(1);
    opacity: 1;
}
.fall-enter{
    transform: scale(1.2);
    opacity: 0;
}.fall-leave-to{

    transform: scale(0.8);
    opacity: 0;
}
</style>