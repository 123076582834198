<template>
    <div id="drop-down-menu">
        <div v-if="expanded" class="close-back" @click="togleExpand"></div>
        <img class="arrow-btn" src="@/assets/simple-arrow-down.svg" alt="down arrow" @click="togleExpand" ref="arrow">
        <transition name="fade">
            <div  v-if="expanded" class="menu-box">
                <div v-for="(option, index) in options" :key="index" class="menu-items">
                    <div class="menu-item" @click="optionClicked(option.name);">
                        <span>{{option.title}}</span>
                        <img item :src="require(`@/assets/drop-down-menu-icons/${option.icon}`)" :alt="option.title" class="menu-icon">
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'dropDownMenu',
    props:['options'],
    data(){
        return{
            expanded: false
        }
    },
    methods:{
        togleExpand(){
            if (!this.expanded){
                this.expanded = true
                this.$refs['arrow'].classList.add('rotate-arrow')
            }
            else{
                this.expanded = false
                this.$refs['arrow'].classList.remove('rotate-arrow')
            }
        },
        optionClicked($event){
            this.$emit($event+'Clicked')
            this.togleExpand()
        }
    }
}
</script>

<style scoped>
    #drop-down-menu{
        position: relative;
    }
    .arrow-btn{
        width: 1.5em;
        height: 1.5em;
        margin: 0.4em;
        padding: 0.4em;
        transition: all .3s ease-in-out;
        cursor: pointer;
    }
    .arrow-btn:hover{
        background: #CCCCCC;
        border-radius: 50%;
    }
    .rotate-arrow{
        transform: rotate(180deg);
    }
    .menu-box{
        position: absolute;
        top: 2em;
        right: 0;
        padding: 1em 0;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 0 6px 0px rgba(0,0,0,0.3);
        z-index: 999;

    }
    .menu-items{
        width: max-content;
        min-width: 100%;
        text-align: center;
    }
    .menu-item{
        width: 100%;
        padding: 0.3em 1em;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    .menu-item:hover{
        background: #CCCCCC;
    }
    .menu-icon{
        width: 1.3em;
        padding-right: 0.5em;
    }
    .close-back{
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
    }
</style>