<template>
  <div>
    <nav-bar :selected="'sales'"></nav-bar>
    <body-cont>
      <BtnEnter class="ms-t-s csv-btn" v-on:clicked="downloadCSV">CSV Report <font-awesome-icon :icon="['fas', 'download']"/></BtnEnter>
      <div class="filter-box ms-t-s">
        <v-select class="filter-item m-t-s" placeholder="Sales Agent" v-model="selectedAgent" label='displayName' :options="salesAgents" @input="updateQuery({page:1, agentID: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Location" v-model="selectedLocation" label='name' :options="locations" @input="updateQuery({page:1, locationID: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Region" v-model="selectedRegion" :options="region" @input="updateQuery({page:1, region: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="State"  v-model="selectedState" :options="state" @input="updateQuery({page:1, state: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="City"  v-model="selectedCity" :options="city" @input="updateQuery({page:1, city: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Account" v-model="selectedAccount" :options="account" @input="updateQuery({page:1, account: $event})"></v-select>
      </div>
      <div v-for="sale in sales.rows" :key="sale.ID" class="sale-cont">
        <div class="sale-info">
          <div class="float-div s-t-s gray-t-c sale-tags">
            <div>{{sale.ID}}</div>
            <div>{{formatDateTime(sale.createdAt)}}</div>
          </div>
          <table class="l-t-a ms-t-s">
            <tr>
              <td class="lb-t-w">Location:</td>
              <td>{{sale.location}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">Sales Agent:</td>
              <td>{{sale.salesAgent}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">Region:</td>
              <td>{{sale.region}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">State:</td>
              <td>{{sale.state}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">City:</td>
              <td>{{sale.city}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">Account:</td>
              <td>{{sale.account}}</td>
            </tr>
            <tr v-if="sale.remarks">
              <td class="lb-t-w">Remarks</td>
              <td class="gray-t-c">{{sale.remarks}}</td>
            </tr>
          </table>
        </div>
        <div class="sales-box ms-t-s">
          <div class="tbl">
            <div class="tbl-row b-t-w">
              <div class="l-t-a">Item</div>
              <div>Quantity</div>
              <div>Price</div>
              <div>Total</div>
            </div>
            <div v-for="item in sale.sales" :key="item.ID" class="tbl-row">
              <div class="l-td">
                <div class="vs-t-s l-t-a gray-t-c">{{item.sku}}</div>
                <div class="l-t-a">{{item.product}}</div>
              </div>
              <div>{{item.quantity}}</div>
              <div>{{(item.price).toFixed(2)}}</div>
              <div>RM{{(item.price *  item.quantity).toFixed(2)}}</div>
            </div>
          </div>
          <div class="total-cost r-t-a">Total RM {{(sale.sales.reduce(((a, b) => ({price: a.price + (b.price * b.quantity)})))).price.toFixed(2)}}</div>
        </div>
      </div>
      <page-selector v-if="sales&&sales.count" :count="sales.count" :selectedPage="page" :pageLimit="pageLimit"
        @pageSelected="updateQuery({page: $event})"></page-selector>
    </body-cont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import utils from '@/lib/util';
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar.vue'
import PageSelector from '@/components/PageSelector'
export default {
  name: 'sales',
  components:{
    BodyCont,
    NavBar,
    PageSelector,
  },
  computed:{
    ...mapGetters({
      sales: 'sale/sales',
      locations: 'location/locations',
      region: 'location/region',
      state: 'location/state',
      account: 'location/account',
      city: 'location/city',
    }),
  },
  data() {
    return {
      selectedAgent: null,
      selectedLocation: null,
      selectedRegion: null,
      selectedState: null,
      selectedCity: null,
      selectedAccount: null,
      selectedInvetntory: null,
      salesAgents: [],
      pageLimit: 10,
      page: 1,
    }
  },
  methods: {
    ...mapActions({
      getSales: 'sale/getSales',
      getSalesReport: 'sale/getSalesReport',
      getLocations: 'location/getLocations',
      getLocationKey: 'location/getKeys',
      fetchUsersByType: 'userProfile/fetchUsersByType'
    }),
    downloadCSV() {
      let filename = "sales_report_" + utils.methods.formatDate(new Date()) + "_" + utils.methods.formatTime(new Date());
      filename = filename.replace(/-/g, "_").replace(/:/g, "_");
      this.getSalesReport()
        .then((result) => {
          utils.methods.downloadCSV({ name: filename, data: result.rows });
        })
        .catch((error) => {
          console.log('error > ', error);
        })
    },
    updateQuery(newQuery){
      let query = {...this.$route.query}

      Object.keys(newQuery).forEach(queryKey => query[queryKey] = newQuery[queryKey] ?? undefined);
      this.$router.push({query}).catch(err=>{})
      this.page = query.page ?? 1
      query.limit = this.pageLimit
      query.offset = this.pageLimit*(this.page-1)
      delete query.page
      console.log(query)
      this.getSales(query)
    },
  },
  async mounted() {
    this.updateQuery({})
    !this.locations?.length?await this.getLocations():false
    !this.region?.length?this.getLocationKey('account'):false
    !this.region?.length?this.getLocationKey('region'):false
    !this.state?.length?this.getLocationKey('state'):false
    !this.city?.length?this.getLocationKey('city'):false
    this.$route.query?.locationID ? this.selectedLocation = this.locations.find(location=>location.ID == this.$route.query.locationID) : null
    this.$route.query?.agentID ? this.selectedAgent = this.salesAgents.find(agent=>agent.ID == this.$route.query.agentID) : null
    this.selectedAccount = this.$route.query?.account
    this.selectedRegion = this.$route.query?.region
    this.selectedState = this.$route.query?.state
    this.selectedCity = this.$route.query?.city
    this.salesAgents = await this.fetchUsersByType('sales_agent')
  },
}
</script>

<style scoped>
.float-div{
  display: flex;
  justify-content: space-between;
}
.sale-cont{
  display: flex;
  justify-content: space-between;
  border: 1px lightgray solid;
  padding: .4em .8em;
  border-radius: .8em;
  margin-bottom: 1em;
  max-width: 80em;
  margin: 0 auto 1em auto;
  box-shadow: 0 .3em .5em rgba(0, 0, 0, 0.3);
}
.sale-info{
  width: 100%;
  max-width: 30em;
  margin-right: 1em;
}
.sale-info td{
  vertical-align: top;
}
.sale-tags{
  border-bottom: 1px lightgray solid;
}
.sales-box{
  width: 100%;
  max-width: 50em;
}
.tbl{
  background: lightgray;
  padding: 0.1em .5em;
  border-radius: .4em;
}
.tbl-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  margin: .5em 0;
  padding: .2em;
  border-radius: .3em;
}
.tbl-row>div{
  min-width: 5em;
  padding: 0 .2em;
}
.tbl-row>div:first-child{
  width: 100%;
}
.total-cost{
  border: 1px #F36711 solid;
  color: #F36711;
  display: inline-block;
  float: right;
  margin: .5em .1em;
  padding: .2em .5em;
  border-radius: .3em;
}

.csv-btn{
  float: right;
  margin-bottom: 1em;
}
.filter-box{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.7em;
  margin: 1em 0;
  justify-content: flex-start;
}
.filter-item{
    width:16%;
    min-width: 15em;
    margin-right: 0.5em;
}

@media only screen and (max-width: 600px) {
  .sale-cont{
    display: inline-block;
    font-size: .7em;
  }
}
</style>
