<template>
    <div @click="clicked"
    :class="{
        disabled: disabled,
        btnCont: !disabled
        }">
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'btnSubmit',
    props: ['disabled'],
    methods:{
        clicked(){
            if(!this.disabled){
                this.$emit('clicked')
            }
        }
    }
}
</script>

<style scoped>
    .btnCont{
        display: block;
        width: fit-content;
        border: 1px #F36711 solid;
        border-radius: 3px;
        padding: .5em 1.5em;
        font-weight: 400;
        color: #F36711;
        cursor: pointer;
        transition: background-color .3s, color .3s;
        height: fit-content;
    }
    .btnCont:hover{
        background-color: #F36711;
        color: white;
    }
    .disabled{
        display: block;
        width: fit-content;
        border: 1px gray solid;
        border-radius: 3px;
        padding: 8px 20px;
        font-weight: 400;
        color:gray;
        cursor: not-allowed;
        background-color: rgba(0,0,0,0);
    }
</style>