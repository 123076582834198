<template>
  <div>
    <nav-bar :selected="'locations'"></nav-bar>
    <body-cont>
      <btn-enter v-if="myProfile.user_account.type == 'admin'"
      style="float: right; margin-bottom: 1em" class="ms-t-s"
      @clicked="$router.push('/cms/location-form/create')">Create Location</btn-enter>

      <mod-table v-if="locations.length" :tableItems="locations"
      v-on:clickedItem="$router.push(`/cms/location-form/edit/${$event.ID}`)"
        :cols="{
        ID:{class:'cell-mid', type:'text'},
        region:{class:'cell-mid', type:'text', alias:'Region'},
        name:{class:'cell-left', type:'text', alias:'Name'},
        account:{class:'cell-mid', type:'text', alias:'Account'},
        city:{class:'cell-mid', type:'text', alias:'City'},
        state:{class:'cell-mid', type:'text', alias:'State'},
        'manager.displayName':{class:'cell-mid', type:'text', alias:'Manager'},
        }">
      </mod-table>
    </body-cont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BodyCont from '@/components/BodyCont'
import NavBar from '../../components/NavBar.vue'
import ModTable from '../../components/ModTable.vue'
export default {
  name: 'locations',
  components:{
    NavBar,
    BodyCont,
    ModTable,
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile',
      locations: 'location/locations',
    })
  },
  methods:{
    ...mapActions({
      getLocations: 'location/getLocations'
    })

  },
  mounted() {
    this.getLocations()
  },
}
</script>

<style scoped>

</style>