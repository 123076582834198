import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from "./lib/util.js";
import ContBox from "./components/ContBox";
import ErrorAlert from "./components/ErrorAlert";
import TextBox from "./components/TextBox";
import BtnSubmit from "./components/BtnSubmit";
import BtnEnter from "./components/BtnEnter";
import TopBar from "./components/TopBar";
import vSelect from "vue-select";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faDownload, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSearch)
library.add(faDownload)
library.add(faAngleDown)

import "vue-select/dist/vue-select.css";

import * as VueGoogleMaps from "vue2-google-maps";
import VueGeolocation from "vue-browser-geolocation";


Vue.use(VueGeolocation);
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_API_KEY,
		libraries: "places", // necessary for places input
	},
});

Vue.config.productionTip = false
Vue.mixin(mixins);

Vue.component("ContBox", ContBox);
Vue.component("TextBox", TextBox);
Vue.component("BtnSubmit", BtnSubmit);
Vue.component("BtnEnter", BtnEnter);
Vue.component("ErrorAlert", ErrorAlert);
Vue.component("TopBar", TopBar);
Vue.component("v-select", vSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  router,
  store,
  render: h => h(App),
  errorCaptured (err, vm, info) {
    console.error(`${err.stack}\n\nfound in ${info} of component`)
    return false
  },
  mixins: [mixins],
}).$mount('#app')
