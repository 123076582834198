<template>
  <div>
    <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
    <div class="user-profile-cont">
      <table class="l-t-a">
        <tr>
          <td class="ms-t-s b-t-w">User Name:</td>
          <td class="ms-t-s">{{myProfile.displayName}}</td>
        </tr>
        <tr v-if="myProfile.user_account.email">
          <td class="ms-t-s b-t-w">Email:</td>
          <td class="ms-t-s">{{myProfile.user_account.email}}</td>
        </tr>
        <tr>
          <td class="ms-t-s b-t-w">Manager:</td>
          <td class="ms-t-s">{{myProfile.Manager}}</td>
        </tr>
      </table>
      <br>
      <BtnEnter v-if="!passwordFormEnabled && myProfile.user_account.email" v-on:clicked="passwordFormEnabled=true">Change Password</BtnEnter>
      <div v-if="passwordFormEnabled">
        <TextBox :type="'text'" title="Old Password:" v-on:valueChanged="pass1=$event"></TextBox>
        <TextBox :type="'text'" title="New Password:" v-on:valueChanged="pass2=$event"></TextBox>
        <br>
        <BtnSubmit :enabled="enabled" v-on:clicked="requestChangePassword()">Change Password</BtnSubmit>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ErrorComment from '@/components/ErrorComment'

export default {
  name: 'MrMyprofile',
  components:{
    ErrorComment
  },
  data() {
    return {
      errorMessage: null,
      passwordFormEnabled: false,
      pass1: null,
      pass2: null,
      enabled: true
    }
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile'
    })
  },
  methods:{
    ...mapActions({
      getMyProfile: 'userProfile/getMyProfile',
      changePassword: 'userProfile/changePassword',
      logout: 'logout',
    }),
    async requestChangePassword(){
      this.enabled = false
      if(!this.pass1||!this.pass2){
        this.enabled = true
        this.errorMessage = "Please enter old and new password"
        return
      }
      await this.changePassword({oldPassword: this.pass1, password:this.pass2})
      .then((res)=>this.logout())
      .catch(err=>{
        this.errorMessage = err.response.data.message
        window.scroll(0,0)
        this.enabled = true
      })
      if(res.status==200){
        // this.$router.push('/scp/stockEntry')
        this.logout()
      }
    }
  },
  async mounted(){
    await this.getMyProfile()
  }
}
</script>

<style scoped>
.user-profile-cont{
  padding: 1em;
}
</style>