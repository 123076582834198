<template>
  <div>
    <nav-bar :selected="'users'"></nav-bar>
    <body-cont>
      <btn-enter v-if="myProfile.user_account.type=='admin'"
      class="ms-t-s" style="float: right; margin-bottom: 1em;"
      @clicked="$router.push('/cms/create-user')"
      >Create User</btn-enter>
      <div class="tabs" v-if="userTypes">
        <TabBtn v-for="(item, index) in userTypes.view" :key="index"
          :tabTitle="item=='admin'?'Administrator':item=='sales_agent'?'Sales Agent':capitalizeFirstLetter(item)"
          :selected="selectedTab==index?true:false"
          v-on:tabClicked="tabClicked(index)"
          ></TabBtn>
      </div>
      <table class="inv-tbl">
        <thead class="ms-t-s">
          <th>ID</th>
          <th>Display Name</th>
          <th>Email</th>
          <th v-if="selectedType == 'sales_agent'">Manager ID</th>
          <th>Created At</th>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.ID"
          @click="requestUser(user.ID)"
          class="s-t-s"
          :class="{'default-mgr': user.managerID == defaultManager}">
            <td>{{user.ID}}</td>
            <td>{{user.displayName}}</td>
            <td>{{user.user_account.email}}</td>
            <td v-if="selectedType == 'sales_agent'">{{user.managerID}}</td>
            <td>{{user.user_account.createdAt}}</td>
          </tr>
        </tbody>
      </table>
    </body-cont>
    <popup-full-screen v-if="userSelected" @exit="userSelected=false" title='User Detasils'>
      <div v-if="selectedUser">
        <table class="user-dets-tbl l-t-a">
          <tr>
            <td class="b-t-w">Type:</td>
            <td>{{selectedUser.user_account.type}}</td>
          </tr>
          <tr>
            <td class="b-t-w">ID:</td>
            <td>{{selectedUser.ID}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Display Name:</td>
            <td>{{selectedUser.displayName}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Email:</td>
            <td>{{selectedUser.user_account.email}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Status:</td>
            <td><div class="status"><div :class="selectedUser.user_account.status">{{selectedUser.user_account.status}}</div>
            <div v-if="myProfile.user_account.type=='admin'">
              <button-with-confirm class="ms-t-s" v-if="selectedUser.user_account.status == 'active'"
              @confirmed="editUserStatus(selectedUser.ID, 'disabled')">Disable User</button-with-confirm>
              <button-with-confirm class="ms-t-s" v-else
              @confirmed="editUserStatus(selectedUser.ID, 'active')">Activate User</button-with-confirm></div>
            </div>
            </td>
          </tr>
          <tr>
            <td><btn-enter @clicked="$router.push(`/cms/edit-user/${selectedType}/${selectedUser.ID}`)">Edit User</btn-enter></td>
          </tr>
        </table>
        
        <div class="action-btns">
          
          
        </div>
      </div>
    </popup-full-screen>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar.vue'
import TabBtn from '@/components/TabBtn'
import PopupFullScreen from '@/components/PopupFullScreen'
import ButtonWithConfirm from '@/components/ButtonWithConfirm'
export default {
  name: 'users',
  components:{
    BodyCont,
    NavBar,
    TabBtn,
    PopupFullScreen,
    ButtonWithConfirm,
  },
  computed:{
    ...mapGetters({
      userTypes: 'userProfile/userTypes',
      users: 'userProfile/users',
      myProfile: 'userProfile/myProfile',
      selectedUser: 'userProfile/selectedUser',
    }),
    selectedType(){
      return(this.$route.params.type ?? this.userTypes.view[0])
    },
    defaultManager(){
      console.log(process.env.VUE_APP_DEFAULT_MANAGER)
      return(process.env.VUE_APP_DEFAULT_MANAGER)
    }
  },
  data() {
    return {
      selectedTab: 0,
      userSelected: false
    }
  },
  methods: {
    ...mapActions({
      getUserTypes: 'userProfile/getUserTypes',
      fetchUsersByType: 'userProfile/fetchUsersByType',
      fetchUserByTypeAndID: 'userProfile/fetchUserByTypeAndID',
      updateUserStatus: 'userProfile/updateUserStatus',
    }),
    async tabClicked(index){
      this.selectedTab = index
      if(this.userTypes.view[index]){
        this.$router.push('/cms/users/' + this.userTypes.view[this.selectedTab])
        await this.fetchUsersByType(this.userTypes.view[index])
      }
    },
    requestUser(ID){
      this.fetchUserByTypeAndID({type: this.selectedType, ID: ID})
      this.userSelected = true
    },
    async editUserStatus(ID, status){
      await this.updateUserStatus({
        type: this.selectedType,
        ID: ID,
        status: status
      })
      this.fetchUserByTypeAndID({type: this.selectedType, ID: ID})
    },
  },
  async mounted() {
    await this.getUserTypes()
    this.selectedTab = this.userTypes.view.indexOf(this.selectedType) || 0
    this.fetchUsersByType(this.userTypes.view[this.selectedTab])
  },
}
</script>

<style scoped>
.tabs{
  display: flex;
  border: 1px #E3E3E3 solid;
  border-radius: 3px;
  overflow: hidden;
  width: fit-content;
}
.inv-tbl{
  width: 100%;
  border-collapse: collapse;
}
.inv-tbl td, th{
  padding: .8em .2em;
  border: 1px #E3E3E3 solid;
  
}
.inv-tbl tr{
  transition: background-color .2s;
  cursor: pointer;
}
.inv-tbl tr:hover{
  background: rgba(0, 0, 0, 0.03);
}
.user-dets-tbl{
  min-width: 10em;
  margin: auto;
}
.user-dets-tbl td{
  padding: .3em;
}
.action-btns{
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}
.active{
  color: green;
  padding: .2em .5em;
  border-radius: 10em;
  display: inline-block;
  background: #b8f1b8;
}
.disabled{
  color: red;
  padding: .2em .5em;
  border-radius: 10em;
  display: inline-block;
  background: #ff9999;
  display: flex;
}
.status{
  display: flex;
  align-items: center;
}
.status>*{
  margin-right: .5em;
  height: min-content;
}
.default-mgr{
  background-color: rgb(255, 246, 220);
}
</style>