<template>
  <div>HOME</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'home',
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile'
    })
  },
  methods:{
    ...mapActions({
      getMyProfile: 'userProfile/getMyProfile'
    })
  },
  async mounted(){
    if(!this.myProfile){
      await this.getMyProfile()
    }
    if(this.myProfile.user_account.type ==  'sales_agent'){
      this.$router.push('/agent')
    }
    else{
      this.$router.push('/cms')
    }
  }
}
</script>