<template>
  <div>
    <nav-bar :selected="'checkIns'"></nav-bar>
    <body-cont>
      <BtnEnter class="ms-t-s csv-btn" v-on:clicked="downloadCSV">CSV Report <font-awesome-icon :icon="['fas', 'download']"/></BtnEnter>
      <div class="filter-box ms-t-s">
        <v-select class="filter-item m-t-s" placeholder="Sales Agent" v-model="selectedAgent" label='displayName' :options="salesAgents" @input="updateQuery({page:1, agentID: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Location" v-model="selectedLocation" label='name' :options="locations" @input="updateQuery({page:1, location: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Region" v-model="selectedRegion" :options="region" @input="updateQuery({page:1, region: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="State"  v-model="selectedState" :options="state" @input="updateQuery({page:1, state: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="City"  v-model="selectedCity" :options="city" @input="updateQuery({page:1, city: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Account" v-model="selectedAccount" :options="account" @input="updateQuery({page:1, account: $event})"></v-select>
      </div>
      <div v-for="checkIn in checkIns.rows" :key="checkIn.ID" class="check-in-cont">
        <div class="check-in-info">
          <div class="s-t-s gray-t-c l-t-a w-100" style="border-bottom: 1px lightgray solid;">{{checkIn.ID}}</div>
          <table class="l-t-a ms-t-s">
            <tr>
              <td class="lb-t-w">Sales Agent:</td>
              <td>{{checkIn.salesAgent}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">Location:</td>
              <td>{{checkIn.location}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">Account:</td>
              <td>{{checkIn.account}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">Region:</td>
              <td>{{checkIn.region}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">State:</td>
              <td>{{checkIn.state}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">City:</td>
              <td>{{checkIn.city}}</td>
            </tr>
            <tr>
              <td class="lb-t-w">Barcode Scanner:</td>
              <td>{{checkIn.code}}</td>
            </tr>
          </table>
        </div>
        <div class="float-div check-img-cont">
          <div style="margin-right: .1em;">
            <div class="b-t-w">Check In</div>
            <div class="img-cont">
              <div class="img-tag check-time">{{formatDateTime(checkIn.createdAt)}}</div>
              <div class="img-tag check-temp" :style="checkIn.temperature>37.3?'color: red;':''">{{checkIn.temperature}}°</div>
              <div class="img-tag check-dist">
                {{checkIn.checkInLocationLat?
                formatDistance(getDistance(checkIn.locationLat, checkIn.checkInLocationLat, checkIn.locationLon, checkIn.checkInLocationLon)):'Unknown Distance'}}
              </div>
              <image-component :imgURL="checkIn.checkInImage" imgWidth="100%" style="max-height: 15em"></image-component>
            </div>
          </div>
          <div>
            <div class="b-t-w" v-if="checkIn.checkOutAt">Check Out</div>
            <div class="img-cont" v-if="checkIn.checkOutAt">
              <div class="img-tag check-time">{{formatDateTime(checkIn.checkOutAt)}}</div>
              <div class="img-tag check-dist">
                {{checkIn.checkOutLocationLat?
                formatDistance(getDistance(checkIn.locationLat, checkIn.checkOutLocationLat, checkIn.locationLon, checkIn.checkOutLocationLon)):'Unknown Distance'}}
              </div>
              <div>{{checkIn.checkInAt}}</div>
              <image-component :imgURL="checkIn.checkOutImage" imgWidth="100%" style="max-height: 15em"></image-component>
            </div>
          </div>
        </div>
      </div>
      <page-selector v-if="checkIns&&checkIns.count" :count="checkIns.count" :selectedPage="page" :pageLimit="pageLimit"
        @pageSelected="updateQuery({page: $event})"></page-selector>
    </body-cont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import utils from '@/lib/util';
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar.vue'
import ImageComponent from '@/components/ImageComponent.vue'
import PageSelector from '@/components/PageSelector'

export default {
  name: 'checkIns',
  components:{
    BodyCont,
    NavBar,
    ImageComponent,
    PageSelector,
  },
  computed:{
    ...mapGetters({
      checkIns: 'checkIn/checkIns',
      locations: 'location/locations',
      region: 'location/region',
      state: 'location/state',
      account: 'location/account',
      city: 'location/city',
    }),
  },
  data() {
    return {
      selectedAgent: null,
      selectedLocation: null,
      selectedRegion: null,
      selectedState: null,
      selectedCity: null,
      selectedAccount: null,
      salesAgents: [],
      pageLimit: 10,
      page: 1
    }
  },
  methods: {
    ...mapActions({
      getCheckIns: 'checkIn/getCheckIns',
      getCheckInsReport: 'checkIn/getCheckInsReport',
      getLocations: 'location/getLocations',
      getLocationKey: 'location/getKeys',
      fetchUsersByType: 'userProfile/fetchUsersByType'
    }),
    downloadCSV() {
      let filename = "check_ins_report_" + utils.methods.formatDate(new Date()) + "_" + utils.methods.formatTime(new Date());
      filename = filename.replace(/-/g, "_").replace(/:/g, "_");
      this.getCheckInsReport({...this.$route.query})
        .then((result) => {
          utils.methods.downloadCSV({ name: filename, data: result.rows });
        })
        .catch((error) => {
          console.log('error > ', error);
        });
    },
    updateQuery(newQuery){
      let query = {...this.$route.query}

      Object.keys(newQuery).forEach(queryKey => query[queryKey] = newQuery[queryKey] ?? undefined);
      this.$router.push({query}).catch(err=>{})
      this.page = query.page ?? 1
      query.limit = this.pageLimit
      query.offset = this.pageLimit*(this.page-1)
      delete query.page
      console.log("FILTER QUERY",query)
      this.getCheckIns(query)
    },
  },
  async mounted() {
    this.updateQuery({})
    !this.locations?.length? await this.getLocations():false
    !this.region?.length?this.getLocationKey('account'):false
    !this.region?.length?this.getLocationKey('region'):false
    !this.state?.length?this.getLocationKey('state'):false
    !this.city?.length?this.getLocationKey('city'):false
    this.$route.query?.locationID ? this.selectedLocation = this.locations.find(location=>location.ID == this.$route.query.locationID) : null
    this.$route.query?.agentID ? this.selectedAgent = this.salesAgents.find(agent=>agent.ID == this.$route.query.agentID) : null
    this.selectedAccount = this.$route.query?.account
    this.selectedRegion = this.$route.query?.region
    this.selectedState = this.$route.query?.state
    this.selectedCity = this.$route.query?.city
    this.salesAgents = await this.fetchUsersByType('sales_agent')
  },
}
</script>

<style scoped>
.filter-box{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.7em;
  margin: 1em 0;
  justify-content: flex-start;
}
.filter-item{
    width:16%;
    min-width: 15em;
    margin-right: 0.5em;
}
.check-in-cont{
  display: flex;
  justify-content: space-between;
  border: 1px lightgray solid;
  padding: .4em .8em;
  border-radius: .8em;
  margin-bottom: 1em;
  max-width: 80em;
  margin: 0 auto 1em auto;
  box-shadow: 0 .3em .5em rgba(0, 0, 0, 0.3);
}
.check-in-info{
  width: 60%;
}
.float-div{
  display: flex;
  justify-content: space-between;
}
.check-img-cont{
  width: 40%;
}
.check-img-cont>*{
  position: relative;
  width: 50%;
  border-radius: .5em;
  overflow: hidden;
}
.check-img{
  width: 100%;
}
.img-tag{
  position: absolute;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 .2em;
}
.check-time{
  transform: translate(-50%, 0);
  left: 50%;
  width: 100%;
}
.check-temp{
  right: 0;
  bottom: 0;
  border-radius: .5em 0 0 0;
}
.check-dist{
  left: 0;
  bottom: 0;
  border-radius: 0 .5em 0 0;
}
@media only screen and (max-width: 600px) {
  .check-in-cont{
    display: inline-block;
    font-size: .7em;
  }
  .check-in-info{
    width: 100%;
  }
  .check-img-cont{
    width: 100%;
  }
}
.csv-btn{
  float: right;
  margin-bottom: 1em;
}
</style>
