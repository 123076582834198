<template>
  <div>
    <nav-bar :selected="'engagements'"></nav-bar>
    <body-cont>
      <div class="ms-t-s btns-cont">
        <BtnEnter v-on:clicked="downloadCSV()">CSV <font-awesome-icon :icon="['fas', 'download']"/></BtnEnter>
      </div>
      <div class="filter-box ms-t-s">
        <v-select class="filter-item m-t-s" placeholder="Location" v-model="selectedLocation" label='name' :options="locations" @input="updateQuery({page:1, locationID: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Region" v-model="selectedRegion" :options="region" @input="updateQuery({page:1, region: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="State"  v-model="selectedState" :options="state" @input="updateQuery({page:1, state: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="City"  v-model="selectedCity" :options="city" @input="updateQuery({page:1, city: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Account" v-model="selectedAccount" :options="account" @input="updateQuery({page:1, account: $event})"></v-select>
      </div>
      <div>
        <div v-for="engagement in engagements.rows" :key="engagement.ID" class="engagement-cont" @click="requestEngagement(engagement.ID)">
          <div class="s-t-s eng-header gray-t-c">
            <div>{{engagement.ID}}</div>
            <div>{{formatDateTime(engagement.createdAt)}}</div>
          </div>
          <div class="eng-body l-t-a">
            <table class="s-t-s stats-tbl">
              <tr>
                <td class="b-t-w">Sales Agent</td>
                <td class="gray-t-c">{{engagement.salesAgent}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Location</td>
                <td class="gray-t-c">{{engagement.location}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Region</td>
                <td class="gray-t-c">{{engagement.region}}</td>
              </tr>
              <tr>
                <td class="b-t-w">State</td>
                <td class="gray-t-c">{{engagement.state}}</td>
              </tr>
              <tr>
                <td class="b-t-w">City</td>
                <td class="gray-t-c">{{engagement.city}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Account</td>
                <td class="gray-t-c">{{engagement.account}}</td>
              </tr>
            </table>
            <table class="s-t-s">
              <tr>
                <td class="b-t-w">Age:</td>
                <td class="gray-t-c">{{engagement.age}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Race: </td>
                <td class="gray-t-c">{{engagement.race}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Use Brand: </td>
                <td class="gray-t-c">{{engagement.useBrand || brandName}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Products Sold: </td>
                <td class="gray-t-c">{{engagement.itemsSold || 0}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Products Redeemed: </td>
                <td class="gray-t-c">{{engagement.redeemedProducts || 0}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Demo Count:</td>
                <td class="gray-t-c">{{engagement.demoCount}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <page-selector v-if="engagements&&engagements.count" :count="engagements.count" :selectedPage="page" :pageLimit="pageLimit"
        @pageSelected="updateQuery({page: $event})"></page-selector>
    </body-cont>
    <popup-full-screen v-if="selectedEngagementID" @exit="selectedEngagementID=null;" title='Engagement'>
      <div v-if="!selectedEngagement"></div>
      <!-- full engagemnt details -->
      <!-- <div v-else>{{selectedEngagement}}</div> -->
      <div v-else style="padding: 1em;">
        <table class="dets-tbl ms-t-s">
          <tr>
            <td class="b-t-w">ID</td>
            <td>{{selectedEngagement.ID}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Race</td>
            <td>{{selectedEngagement.race}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Age</td>
            <td>{{selectedEngagement.age}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Use Brand</td>
            <td>{{selectedEngagement.competitor_company ? selectedEngagement.competitor_company.name : brandName}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Use Products</td>
            <td>
              <div v-if="selectedEngagement.competitor_products_users.length">
                <li v-for="comProd in selectedEngagement.competitor_products_users" :key="comProd.ID">{{ comProd.competitor_product.name }}</li>
              </div>
            </td>
          </tr>
          <tr>
            <td class="b-t-w">Location</td>
            <td>{{selectedEngagement.check_in.location.name}}</td>
          </tr>
          <tr>
            <td class="b-t-w">city</td>
            <td>{{selectedEngagement.check_in.location.city}}</td>
          </tr>
          <tr>
            <td class="b-t-w">state</td>
            <td>{{selectedEngagement.check_in.location.state}}</td>
          </tr>
          <tr>
            <td class="b-t-w">region</td>
            <td>{{selectedEngagement.check_in.location.region}}</td>
          </tr>
          <tr>
            <td class="b-t-w">account</td>
            <td>{{selectedEngagement.check_in.location.account}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Redemptions</td>
            <td class="sub-dets">
              <li v-for="redemption in selectedEngagement.redemptions" :key="redemption.ID">
                <span>{{redemption.giveaway_product.name}}</span>
                <span class="gray-t-c"> ({{redemption.quantity}})</span>
              </li>
            </td>
          </tr>
          <tr>
            <td class="b-t-w">Demo Count:</td>
            <td class="gray-t-c">{{selectedEngagement.demoCount}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Items Sold:</td>
            <td>
              <div v-if="!selectedEngagement.sales_groups[0]">None</div>
              <table v-else>
                <thead>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </thead>
                <tr v-for="sale in selectedEngagement.sales_groups[0].sales" :key="sale.ID">
                  <td>{{sale.inventory.product.name}}</td>
                  <td>{{sale.quantity}}</td>
                  <td>RM{{sale.price}}</td>
                  <td>RM{{sale.price * sale.quantity}}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td class="b-t-w">{{selectedEngagement.itemsSold ? 'Reason for sale' : 'Reason for no sale:'}}</td>
            <td>
              <li v-for="(reason,index) in selectedEngagement.saleReason" :key="index">
                {{reason}}
              </li>
            </td>
          </tr>
        </table>
      </div>
    </popup-full-screen>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import utils from '@/lib/util';
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar.vue'
import ModTable from '../../components/ModTable.vue'
import PopupFullScreen from '@/components/PopupFullScreen'
import PageSelector from '@/components/PageSelector'

export default {
  name: 'engagements',
  components:{
    NavBar,
    BodyCont,
    ModTable,
    PopupFullScreen,
    PageSelector,
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile',
      engagements: 'engagement/engagements',
      products: 'product/products',
      locations: 'location/locations',
      region: 'location/region',
      state: 'location/state',
      account: 'location/account',
      city: 'location/city',
    }),
    referenceLocationID(){
      return(this.$route.params.locationID)
    },
    brandName(){
      return(process.env.VUE_APP_BRAND_NAME)
    }
  },
  data() {
    return {
      selectedLocation: null,
      selectedRegion: null,
      selectedState: null,
      selectedCity: null,
      selectedAccount: null,
      selectedEngagement: null,
      selectedEngagementID: null,
      stockEntries: [],
      pageLimit: 20,
      page: 1
    }
  },
  methods:{
    ...mapActions({
      getAllEngagements: 'engagement/getAllEngagements',
      getEngagement: 'engagement/getEngagement',
      getEngagementsReport: 'engagement/getEngagementsReport',
      getLocations: 'location/getLocations',
      getLocationKey: 'location/getKeys',
    }),
    async downloadCSV() {
      let query = {
        ...this.$route.query
      }
      let report = await this.getEngagementsReport(query)
      let filename = `engagements_report_${utils.methods.formatDate(new Date())}`;
      filename = filename.replace(/-/g, "_").replace(/:/g, "_");
      utils.methods.downloadCSV({ name: filename, data: report });
    },
    updateQuery(newQuery){
      let query = {...this.$route.query}
      Object.keys(newQuery).forEach(queryKey => query[queryKey] = newQuery[queryKey] ?? undefined);
      this.$router.push({query}).catch(err=>{})
      this.page = query.page ?? 1
      query.limit = this.pageLimit
      query.offset = this.pageLimit*(this.page-1)
      delete query.page
      this.getAllEngagements(query)
    },
    requestEngagement(engagementID){
      this.selectedEngagement = null
      this.selectedEngagementID = engagementID
      this.getEngagement(engagementID)
      .then(res  => {
        let engagement = res
        if(!engagement.sales_groups[0]){
          engagement.itemsSold = false
          engagement.saleReason = [
            ... engagement.noSalesForPrice ? ['PRICE'] : [],
            ... engagement.noSalesForNotIntrested ? ['NOT INTRESTED'] : [],
            ... engagement.noSalesForLoyalty ? ['LOYALTY'] : [],
            ... engagement.noSalesForOthers ? ['OTHERS'] : [],
          ]
        }
        else{
          engagement.itemsSold = true
          engagement.saleReason = [
            ... engagement.salesForPrice ? ['PRICE'] : [],
            ... engagement.salesForPromotion ? ['PROMOTION'] : [],
            ... engagement.salesForBenefits ? ['BENEFITS'] : [],
            ... engagement.salesForFreeGift ? ['FREE GIFT'] : [],
            ... engagement.salesForTryNewThing ? ['TRY NEW THINGS'] : [],
            ... engagement.salesForOthers ? ['OTHERS'] : [],
          ]
        }
        this.selectedEngagement = engagement
      })
      .catch(err => {
        console.error(err)
      })
    }
  },
  async mounted() {
    this.updateQuery({})
    !this.locations?.length?await this.getLocations():false
    !this.region?.length?this.getLocationKey('account'):false
    !this.region?.length?this.getLocationKey('region'):false
    !this.state?.length?this.getLocationKey('state'):false
    !this.city?.length?this.getLocationKey('city'):false
    this.$route.query?.locationID ? this.selectedLocation = this.locations.find(location=>location.ID == this.$route.query.locationID) : null
    this.selectedAccount = this.$route.query?.account
    this.selectedRegion = this.$route.query?.region
    this.selectedState = this.$route.query?.state
    this.selectedCity = this.$route.query?.city
  }
}
</script>

<style scoped>
.filter-box{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.7em;
  margin: 1em 0;
  justify-content: flex-start;
}
.filter-item{
    width:16%;
    min-width: 15em;
    margin-right: 0.5em;
}
.btns-cont{
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-bottom: 1em;
}
.btns-cont>*{
  margin-left: .5em;
}
.engagement-cont{
  border: 1px lightgray solid;
  padding: 0.4em 0.8em;
  border-radius: 0.8em;
  margin-bottom: 1em;
  max-width: 80em;
  margin: 0 auto 1em auto;
  cursor: pointer;
  box-shadow: 0 0.3em 0.5em rgb(0 0 0 / 30%);
}
.eng-header{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px lightgray solid;
  width: 100%;
}
.eng-body{
  display: flex;
  justify-content: space-between;
}
.eng-body>*{
  width: 100%;
}
.eng-body table:first-child>tr>td:last-child{
  width: 80%;
}
.eng-body table>tr>td:last-child{
  width: 60%;

}

.brand-stats-cont{
  display: flex;
}
.brand-stats-cont>*{
  width: 100%;
}
.brand-stats-cont>*:first-child{
  margin-right: .5em;
  border-right: 1px lightgray solid;
}
.brand-stats-cont>div>div>span:first-child{
  min-width: 10em;
  display: inline-flex;
}

.stats-tbl{
  min-width:20%; 
}
.group-cont{
  margin-left: .5em;
  padding-left: .5em;
  border-left: 1px lightgray solid;
  min-width: 60%;
}
.group-box{
  display: flex;
  justify-content: stretch;
  width: 100%;
  word-break: break-all;
  border-bottom: 1px lightgray solid;
  margin-bottom: 1em;
}
.group-box:last-child{
  border-bottom: none;
}
.group-box>*{
  width: 100%;
  border-right: 1px lightgray solid;
}
.group-box>*:last-child{
  border-right: none;
}
.group-title{
  border-bottom: 1px lightgray solid;
}

.dets-tbl{
  width: 100%;
  vertical-align: top;
  text-align: left;
  border-collapse: collapse;
}
.dets-tbl>tr>td{
  border: 1px lightgray solid;
  padding: .2em .4em;
  vertical-align: top;
}
.sub-dets td{
  border-bottom: 1px lightgray solid;
  padding-right: .3em;
}
.sub-dets tr:last-child td{
  border-bottom: none;
}
@media only screen and (max-width: 600px) {
  .eng-body{
    display: inline-block;
    font-size: .7em;
  }
}
</style>
