<template>
    <div class="body-box white-b-c" >
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'bodyCont',
}
</script>
<style scoped>
.body-box{
    width: 90%;
    padding: 20px;
    margin: 10px auto;
    border-radius: 10px;
    overflow: auto;
}
</style>