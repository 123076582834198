<template>
  <div>
    <btn-enter @clicked="$router.push('/agent/engagement')" style="margin-bottom: 1em;">Submit Engagement</btn-enter>
    <div v-if="engagements">
    <div v-for="(engagement) in engagements.rows" :key="engagement.ID" class="eng-box" @click="$router.push(`/agent/engagement/${engagement.ID}`)">
      <div class="eng-header">
        <div class="s-t-s gray-t-c">{{formatDateTime(engagement.createdAt)}}</div>
        <div class="s-t-s gray-t-c">{{engagement.ID}}</div>
      </div>
      <div class="eng-body">
        <table class="s-t-s l-t-a">
          <tr>
            <td class="b-t-w">Age:</td>
            <td>{{engagement.age}}</td>
            <td class="b-t-w">Products Redeemed:</td>
            <td>{{engagement.redeemedProducts}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Race:</td>
            <td>{{engagement.race}}</td>
            <td class="b-t-w">Products Sold</td>
            <td>{{engagement.itemsSold}}</td>
          </tr>
          <tr>
            <td class="b-t-w">Use Brand:</td>
            <td>{{engagement.useBrand || brandName}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'agentEngagemetns',
  computed:{
    ...mapGetters({
      engagements: 'engagement/engagements'
    }),
    brandName(){
      return(process.env.VUE_APP_BRAND_NAME)
    },
  },
  data() {
    return {
      
    }
  },
  methods: {
    ...mapActions({
      getAllEngagements: 'engagement/getAllEngagements'
    })
  },
  mounted(){
    this.getAllEngagements()
  },
}
</script>

<style scoped>
.eng-box{
  background: white;
  padding: .3em;
  border-radius: .3em;
  margin-bottom: 1em;
  border: 1px lightgray solid;
  cursor: pointer;
}
.eng-header{
  width: 100%;
  border-bottom: 1px lightgray solid;
  display: flex;
  justify-content: space-between;
}
.eng-body table{
  border-collapse: collapse;
  width: 100%;
}
.eng-body table td{
  border-bottom: 1px lightgray solid;
  padding: .2em;
}
.eng-body table td:first-child{
  width: 6em,
}
.eng-body table tr:last-child td{
  border-bottom: none;
}
</style>