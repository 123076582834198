<template>
  <div>
    <nav-bar :selected="'products'"></nav-bar>
    <body-cont>
      <btn-enter v-if="myProfile.user_account.type == 'admin'"
      style="float: right; margin-bottom: 1em" class="ms-t-s"
      @clicked="$router.push('/cms/product-form/create')">Create Product</btn-enter>

      <mod-table v-if="products.length" :tableItems="products"
      v-on:clickedItem="$router.push(`/cms/product-form/edit/${$event.ID}`)"
        :cols="{
        ID:{class:'cell-mid', type:'text'},
        name:{class:'cell-left', type:'text', alias:'name'},
        sku:{class:'cell-left', type:'text', alias:'SKU'},
        description:{class:'cell-left', type:'text', alias:'Description'}
        }">
      </mod-table>
    </body-cont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BodyCont from '@/components/BodyCont'
import NavBar from '../../components/NavBar.vue'
import ModTable from '../../components/ModTable.vue'
export default {
  name: 'products',
  components:{
    NavBar,
    BodyCont,
    ModTable,
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile',
      products: 'product/products',
    })
  },
  methods:{
    ...mapActions({
      getProducts: 'product/getProducts'
    })

  },
  mounted() {
    this.getProducts()
  },
}
</script>

<style scoped>

</style>