<template>
  <div v-if="!loading">
    <div class="input-group">
      <div class="b-t-w l-t-s l-t-a title-t-c">{{engagementID ? 'Edit' : 'New'}} Engagement</div>
      <div class="select-group">
        <div class="l-t-a b-t-w m-t-s">Brand They Use:</div>
        <div v-for="brand in allBrands" :key="brand.ID" class="l-t-a">
          <input type="radio" :id="'comp-'+brand.ID" :value="brand.ID" v-model="selectedBrand" @input="selectedCompetitorProducts = []">
          <label :for="'comp-'+brand.ID">{{brand.name}}</label>
        </div>
        <div v-if="brandProducts.length">
          <div class="l-t-a b-t-w">Products They Use:</div>
          <multiselect
          label="name"
          :options="brandProducts"
          v-model="selectedCompetitorProducts"
          :multiple="true"
          track-by="ID"
          placeholder="Click to select"
          selectedLabel=" ✓ ">
          </multiselect>
        </div>
      </div>
      <div class="select-group">
        <div class="l-t-a b-t-w m-t-s">Age Group:</div>
        <div v-for="(ageGroup, index) in ageGroups" :key="ageGroup.index" class="l-t-a">
          <input type="radio" :id="'age-'+index" :value="ageGroup" v-model="selectedAgeGroup">
          <label :for="'age-'+index">{{ageGroup}}</label>
        </div>
      </div>
      <div class="select-group">
        <div class="l-t-a b-t-w m-t-s">Race:</div>
        <div v-for="(race, index) in raceGroups" :key="race.index" class="l-t-a">
          <input type="radio" :id="'race-'+index" :value="race" v-model="selectedRace">
          <label :for="'race-'+index">{{race}}</label>
        </div>
      </div>
    </div>

    <div class="input-group">
      <div class="b-t-w l-t-s l-t-a title-t-c">Redemptions</div>
      <transition-group name="open-horz" tag="p">
        <div v-for="(redemption, index) in redemptions" :key="redemption.giveaway_product.ID"
        class="cart-item-cont">
          <div class="del-btn" @click="redemptions.splice(index, 1)">✖</div>
          <div class="cart-item">
            <div class="title-t-c l-t-a lb-t-w ms-t-s">{{redemption.giveaway_product.name}}</div>
            <counter style="margin-right: 2em;" v-model="redemption.quantity"></counter>
          </div>
        </div>
      </transition-group>
      <div style="display: flex;">
        <v-select placeholder="Giveaway Product" class="select-box ms-t-s"
        label="name" v-model="selectedGiveawayProduct" :options="giveawayProducts" style="width: 100%;" @input="addRedemption"></v-select>
      </div>
    </div>
    <div class="input-group cart-item"><span class="l-t-a l-t-s b-t-w  title-t-c">Demo Count:</span><counter class="counter-cont" v-model="demoCount"></counter></div>
    <div class="action-btns">
      <btn-submit v-if="!engagementID" style="width: 100%;" :enabled="isSubmitEnabled" @clicked="showSalePopup = true">Submit</btn-submit>
      <btn-enter v-if="engagementID"  @clicked="$router.push('/agent/engagements')">Back</btn-enter>
      <btn-submit v-if="engagementID" :enabled="isSubmitEnabled" @clicked="requestSubmitEngagement()">Edit</btn-submit>
    </div>
    <popup-full-screen v-if="showSalePopup" @exit="performSale = null; showSalePopup = false">
      <div v-if="performSale == null" class="popup-cont">
        <div class="b-t-w">Do they want to perform a sale?</div>
        <div class="popup-action-btns">
          <btn-submit enabled="true" @clicked="performSale = false">No</btn-submit>
          <btn-submit enabled="true" @clicked="performSale = true">Yes</btn-submit>
        </div>
      </div>
      <div v-else class="popup-cont">
        <div class="l-t-a b-t-w">Please select reasons:</div>
        <div v-for="(reason, index) in performSale ? salesReasons : noSalesReasons" :key="index" class="reason">
          <check-box :title="reason.name" v-model="reason.selected" @input="updateReason(index, $event)"></check-box>
        </div>
        <div class="popup-action-btns">
          <btn-enter @clicked="performSale = null">Back</btn-enter>
          <btn-submit enabled="true" @clicked="requestSubmitEngagement()">Submit</btn-submit>
        </div>
      </div>
    </popup-full-screen>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Counter from '@/components/Counter.vue';
import ButtonWithConfirm from '@/components/ButtonWithConfirm.vue';
import Multiselect from 'vue-multiselect';
import PopupFullScreen from '@/components/PopupFullScreen.vue';
import CheckBox from '@/components/CheckBox.vue';
export default {
  name: 'agentEngagements',
  components:{
    Counter,
    ButtonWithConfirm,
    Multiselect,
    PopupFullScreen,
    CheckBox,
  },
  computed: {
    ...mapGetters({
      engagement: 'engagement/agentEngagement',
      giveawayProducts: 'engagement/giveawayProducts',
      checkIn: 'checkIn/checkIn',
      competitorCompanies: 'competitor/competitorCompanies',
    }),
    brandName(){
      return process.env.VUE_APP_BRAND_NAME
    },
    isSubmitEnabled(){
      return(!this.submitting && this.selectedBrand != null && this.selectedRace && this.selectedAgeGroup)
    },
    allBrands(){
      return([{ID: 0, name: this.brandName}, ...this.competitorCompanies])
    },
    engagementID(){
      return(this.$route?.params?.engagementID)
    }
  },
  data() {
    return {
      errorMessage: null,
      selectedBrand: null,
      raceGroups: ['Malay', 'Chinese', 'Indian', 'Other'],
      selectedRace: null,
      ageGroups: ['17 Years or younger', 'Between 18 - 22', 'Between 23 - 28', 'Between 29 - 33', 'Between 34 - 40', '40 Years and older'],
      selectedAgeGroup: null,
      demoCount: 0,
      selectedCompetitorProducts: [],
      redemptions: [],
      selectedGiveawayProduct: null,
      submitting: false,
      brandProducts: [],
      showSalePopup: false,
      performSale: null,
      salesReasons: [
        {
          name: 'PRICE',
          key: 'salesForPrice',
          selected: false
        },
        {
          name: 'PROMOTION',
          key: 'salesForPromotion',
          selected: false
        },
        {
          name: 'BENEFITS',
          key: 'salesForBenefits',
          selected: false
        },
        {
          name: 'FREE GIFT',
          key: 'salesForFreeGift',
          selected: false
        },
        {
          name: 'TRY NEW THING',
          key: 'salesForTryNewThing',
          selected: false
        },
        {
          name: 'OTHERS',
          key: 'salesForOthers',
          selected: false
        },
      ],
      noSalesReasons: [
        {
          name: 'PRICE',
          key: 'noSalesForPrice',
          selected: false
        },
        {
          name: 'NOT INTERESTED',
          key: 'noSalesForNotIntrested',
          selected: false
        },
        {
          name: 'LOYALTY',
          key: 'noSalesForLoyalty',
          selected: false
        },
        {
          name: 'OTHERS',
          key: 'noSalesForOthers',
          selected: false
        },
      ],
      loading: true,
    }
  },
  methods: {
    ...mapActions({
      getEngagement: 'engagement/getEngagement',
      getGiveawayProducts: 'engagement/getGiveawayProducts',
      submitEngagements: 'engagement/submitEngagements',
      editEngagements: 'engagement/editEngagements',
      getCompetitorCompanies: 'competitor/getCompetitorCompanies',
      getCompetitorCompanyProducts: 'competitor/getCompetitorCompanyProducts',
    }),
    ...mapMutations({
      pushAlert: 'pushAlert',
    }),
    addRedemption(){
      if(this.selectedGiveawayProduct){
        let alreadySelected = this.engagement.redemptions.find(redemption => redemption.giveaway_product.ID == this.selectedGiveawayProduct.ID)
        if(!alreadySelected){
          this.redemptions.push({
            quantity: 1,
            giveaway_product: this.selectedGiveawayProduct
          })
        }
        this.selectedGiveawayProduct = null
      }
    },
    async requestBrandProducts(companyID){
      this.brandProducts = []
      if(!companyID){
        return;
      }
      await this.getCompetitorCompanyProducts(companyID)
      .then(res => {
        this.brandProducts = res
      })
      .catch(err => {
        this.pushAlert({text:err.response.data.message, type: 'fail'})
      })
    },
    async requestSubmitEngagement(){
      this.showSalePopup = false
      this.submitting = true
      let redemptions = this.redemptions.map(redemption => {return({giveawayProductID: redemption.giveaway_product.ID, quantity: redemption.quantity })})
      let engagementData = {
        age: this.selectedAgeGroup,
        race: this.selectedRace,
        ...(this.selectedBrand  && {competitorCompanyID: this.selectedBrand}),
        competitorProductsID: this.selectedCompetitorProducts.map(x => x.ID),
        demoCount: this.demoCount,
        redemptions,
        competitorUsers: this.competitorUsers,
      }
      for (const reason of this.performSale ? this.salesReasons : this.noSalesReasons) {
        engagementData[reason.key] = reason.selected
      }
      if(this.engagementID){
        this.editEngagements({engagementData, engagementID: this.engagementID})
        .then(res => {
          this.pushAlert({text: res.message, type: 'success'})
          this.submitting = false
          this.$router.push('/agent/engagements')
        })
        .catch(err => {
          this.pushAlert({text:err.response.data.message, type: 'fail'})
          this.submitting = false
        })
      }
      else{
        this.submitEngagements(engagementData)
        .then(res => {
          this.pushAlert({text: res.message, type: 'success'})
          this.submitting = false
          if(this.performSale){
            this.$router.push('/agent/sell/' + res.engagementID)
          }
          else{
            this.$router.push('/agent/engagements')
          }
        })
        .catch(err => {
          this.pushAlert({text:err.response.data.message, type: 'fail'})
          this.submitting = false
        })
      }
    },
    updateReason(index, value){
      if (this.performSale){
        for (const reason of this.salesReasons) {
          reason.selected = false
        }
        this.salesReasons[index].selected = value
      } 
      else{
        for (const reason of this.noSalesReasons) {
          reason.selected = false
        }
        this.noSalesReasons[index].selected = value
      }
    }
  },
  async mounted(){
    await this.getGiveawayProducts({account: this.checkIn?.location?.account})
    await this.getCompetitorCompanies()
    .catch(err => {
      this.pushAlert({text:err.response.data.message, type: 'fail'})
    })
    if(this.engagementID){
      await this.getEngagement(this.engagementID)
      .then(engagement => {
        this.selectedBrand = engagement.competitor_company?.ID || 0
        if(engagement.competitor_products_users?.length){
          for (const compProd of engagement.competitor_products_users) {
            this.selectedCompetitorProducts.push(compProd.competitor_product)
          }
        }
        this.selectedAgeGroup = engagement.age
        this.selectedRace = engagement.race
        this.demoCount = engagement.demoCount
        this.redemptions = engagement.redemptions
        for (let i = 0; i < this.salesReasons.length; i++) {
          if(engagement[this.salesReasons[i].key]){
            this.salesReasons[i].selected = true
          }
        }
        for (let i = 0; i < this.noSalesReasons.length; i++) {
          if(engagement[this.noSalesReasons[i].key]){
            this.noSalesReasons[i].selected = true
          }
        }
      })
      .catch(err => {
        this.pushAlert({text:err.response.data.message, type: 'fail'})
      })
    }
    this.loading = false
  },
  watch:{
    selectedBrand(value){
      this.requestBrandProducts(value)
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.input-group{
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px lightgray solid;
}
.input-box{
  margin-bottom: .5em;
}
.engagemnts-tbl{
  background: white;
  border-collapse: collapse;
  border-radius: .6em;
  overflow: hidden;
  box-shadow:  0 0 .3em rgba(0, 0, 0, 0.3);
}
.engagemnts-tbl td{
  border: 1px lightgray solid;
  padding:  .2em .5em;
  min-width: 8em;
}

.popup-form{
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100%;
  padding: 3.5em 1em;
}

.del-btn{
  background: #ff4747;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 .3em;
  border-radius: 0 0.3em 0 0.3em;
}
.cart-item-cont{
  margin: 1em 0;
  position: relative;
  box-shadow: 0 0 .2em rgba(0, 0, 0, 0.5);
  border-radius: .5em;
  background: white;
  overflow: hidden;
  padding: .5em;
}
.cart-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-price{
  padding: 0;
  width: 3em;
  border: 1px lightgray solid;
  padding: 0 .1em;
}
.cart-item-nums{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cart-item-calc{
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: white;
  width: calc(100% - .5em);
  margin: auto;
  box-shadow: 0 0 .2em rgba(0, 0, 0, 0.5);
  padding: .2em;
  border-radius: 0 0 .5em .5em;
}
.add-btn{
  background: #48ac50;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.6em;
  min-height: 1.6em;
  margin-left: .5em;
  border-radius: 50%;
  color: white;
}
.add-btn.disabled{
  background: lightgray;
}
.counter-cont{
  display: flex;
  align-items: center;
  margin-bottom: .2em;
}
.counter-cont>*:first-child{
  width: 25em; 
  margin-right: 1em;
}

.expand-btn{
  background: #0D1A48;
  color: white;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: transform .3s;
}
.expand-btn.expanded{
  transform: rotate(180deg);
}

.select-group{
  background: white;
  width: 100%;
  padding: 1em;
  border: 1px lightgray solid;
  border-radius: .5em;
  margin-bottom: 1em;
}
.select-group:last-child{
  margin-bottom: 0;
}


.popup-action-btns{
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.reason{
  margin: .5em 0;
}
.action-btns{
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
}
.expand-enter-active, .expand-leave-active{
    transition: max-height .2s ease;
    max-height: 30em;
    overflow: hidden;
}
.expand-enter, .expand-leave-to{
    max-height: 0;
}
</style>