import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
	namespaced: true,
	state: {
		locations: [],
		region:  [],
		state: [],
		account: [],
		city: [],
	},
	getters: {
		locations: (state) => state.locations,
		region: (state) => state.region,
		state: (state) => state.state,
		account: (state) => state.account,
		city: (state) => state.city,
	},
	mutations: {
		commitLocations(state, locations) {
			console.log("MUTATION CALLED", locations);
			state.locations = locations;
		},
		commitLocationKey(state, {key, values}) {
			console.log("MUTATION CALLED", values);
			state[key] = values;
		},
	},
	actions: {
		async getLocations(context) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/locations`, {
					withCredentials: true,
				})
				.then((response) => {
					context.commit('commitLocations', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getLocation(context, locationID) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/location/${locationID}`, {
					withCredentials: true,
				})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getKeys(context, key) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/location-key/${key}`, {
					withCredentials: true,
				})
				.then((response) => {
					context.commit('commitLocationKey', {key, values: response.data})
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async createLocation(context, locationData) {
			return new Promise((resolve, reject)=>{
				axios
				.post(`${process.env.VUE_APP_ROOT_API_URL}/location`,
				locationData,
				{ withCredentials: true })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async editLocation(context, {locationData, locationID}) {
			return new Promise((resolve, reject)=>{
				axios
				.put(`${process.env.VUE_APP_ROOT_API_URL}/location/${locationID}`,
				locationData,
				{ withCredentials: true })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
	},
};
