var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('label',[(_vm.search)?_c('div',{staticClass:"search-form"},[_c('gmap-autocomplete',{staticClass:"search-txt m-t-s",on:{"place_changed":_vm.setPlace}}),_c('BtnEnter',{staticClass:"serch-btn",on:{"clicked":_vm.addMarker}},[_vm._v("Search")])],1):_vm._e()]),_c('br')]),_c('gmap-map',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":15,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: false,
      disableDefaultUI: false
    }}},_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position},on:{"click":function($event){_vm.center=m.position}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }