<template>
  <div>
    <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
    <div v-if="loaded">
      <div v-if="selectedProduct" class="prod-cont l-t-a">
        <div>
          <div class="lb-t-w">{{selectedProduct.name}}</div>
          <div class="s-t-s gray-t-c">{{selectedProduct.description}}</div>
        </div>
        <image-component :imgURL="selectedProduct.image" :imgWidth="'5em'" :imgHeight="'5em'"></image-component>
      </div>
      <br>
      <table class="input-tbl l-t-a">
        <tr>
          <td class="b-t-w">Price:</td>
          <td class="price-input"><span class="gray-t-c">RM </span><TextBox :type="'number'" v-on:valueChanged="price=$event"  :placeHolder="'Ex: 2.5'" :min="0"></TextBox></td>
        </tr>
        <tr>
          <td class="b-t-w" style="vertical-align: top;">Quantity:</td>
          <td>
            <input type="radio" v-model="quantity" id="q6" value="6">
            <label for="q6">6+ Items</label><br>
            <input type="radio" v-model="quantity" id="q5" value="5">
            <label for="q5">5 Item</label><br>
            <input type="radio" v-model="quantity" id="q4" value="4">
            <label for="q4">4 Items</label><br>
            <input type="radio" v-model="quantity" id="q3" value="3">
            <label for="q3">3 Items</label><br>
            <input type="radio" v-model="quantity" id="q2" value="2">
            <label for="q2">2 Items</label><br>
            <input type="radio" v-model="quantity" id="q1" value="1">
            <label for="q1">1 Item</label><br>
            <input type="radio" v-model="quantity" id="q0" value="0">
            <label for="q0">No Items</label><br>
          </td>
        </tr>
        <tr>
          <td class="b-t-w">Picture:</td>
          <td>
            <div class="img-div">
              <BtnEnter v-if="!picture" v-on:clicked="takePicture">Take Picture</BtnEnter>
              <image-uploader
                id="stock-pic"
                :debug="1"
                :maxWidth="1000"
                :maxHeight="1000"
                :quality="0.7"
                outputFormat="verbose"
                :preview=false
                :capture="true"
                accept="image/*"
                doNotResize="['gif', 'svg']"
                @input="picUpdate"
                style="display: none;"
              ></image-uploader>
              <img v-show="picture" @click="takePicture" src="#" id="imgshow" align="left">
            </div>
          </td>
        </tr>
        <tr>
          <td class="b-t-w" style="vertical-align: top;">Remarks:</td>
          <td colspan="2"><TextBox :type="'textarea'" :initVal="remarks" v-on:valueChanged="remarks=$event"  :placeHolder="''"></TextBox></td>
        </tr>
      </table>
      <btn-submit :enabled="btnEnabled&&(price==0||price)&&picture" style="margin: 2em auto;"
      @clicked="requestCountStock()">Submit Count</btn-submit>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ErrorComment from '@/components/ErrorComment'
import ImageUploader from 'vue-image-upload-resize'
import ImageComponent from '@/components/ImageComponent.vue'

export default {
  name: 'stockCount',
  components:{
    ImageUploader,
    ErrorComment,
    ImageComponent,
  },
  data() {
    return {
      quantity: 6,
      price: null,
      picture: null,
      remarks: null,
      btnEnabled: true,
      errorMessage: null,
      loaded: false
    }
  },
  computed:{
    ...mapGetters({
      selectedProduct: 'product/selectedProduct',
    }),
    productID(){
      return(this.$route.params.productID)
    }
  },
  methods:{
    ...mapActions({
      getProduct: 'product/getProduct',
      countStock: 'inventory/countStock',
    }),
    ...mapMutations({
      pushAlert: 'pushAlert'
    }),
    takePicture(){
      document.getElementById('stock-pic').click()
    },
    async picUpdate(value){
      this.picture = await this.formatImage(value)
      let img = document.getElementById('imgshow')
      img.src = value.dataUrl
    },
    async requestCountStock(){
      this.btnEnabled = false
      let inventoryData = {
        quantity: this.quantity,
        price: this.price,
        remarks: this.remarks,
        img: this.picture,
      }
      this.countStock({productID: this.productID, inventoryData})
      .then(res=>{
        this.pushAlert({text: 'Stock count submited', type: 'success'})
        this.$router.push('/agent/inventory')
      })
      .catch(err=>{
        this.btnEnabled = true
        console.log(err)
        this.errorMessage = err.response.data.message
        window.scroll(0,0)
      })
    },
  },
  async mounted() {
    await this.getProduct(this.productID)
    .then(()=>{
      this.loaded = true
    })
    .catch(err=>{
      this.errorMessage = err.response.data.message
      window.scroll(0,0)
    })
  },
}
</script>

<style scoped>
.prod-cont{
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: white;
  padding: .5em 0 .5em .5em;
  border-radius: .5em;
}
.price-input{
  display: flex;
  align-items: center;
}
.price-input>span{
  margin-right: .5em;
}
.prod-image{
  width: 6em;
}
#imgshow{
  max-width: 5em;
}
.input-tbl td{
  padding: .6em 0;
}
</style>