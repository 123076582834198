import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
	namespaced: true,
	state: {
		products: [],
		selectedProduct: null
	},
	getters: {
		products: (state) => state.products,
		selectedProduct: (state) => state.selectedProduct,
	},
	mutations: {
		commitProducts(state, products) {
			console.log("MUTATION CALLED", products);
			state.products = products;
		},
		commitSelectedProduct(state, selectedProduct) {
			console.log("MUTATION CALLED", selectedProduct);
			state.selectedProduct = selectedProduct;
		},
	},
	actions: {
		async getProducts(context, query) {
			query = util.methods.getQueryStringFromObj(query)
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/products${query}`, {
					withCredentials: true,
				})
				.then((response) => {
					context.commit('commitProducts', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async getProduct(context, productsID) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/product/${productsID}`, {
					withCredentials: true,
				})
				.then((response) => {
					context.commit('commitSelectedProduct', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async createProduct(context, productData) {
			return new Promise((resolve, reject)=>{
				let formData = new FormData();
				if (productData.img) {
					formData.append("file", productData.img, productData.img.fileName);
				}
				delete productData.img;
				for (let i = 0; i < Object.keys(productData).length; i++) {
					formData.append(
						Object.keys(productData)[i],
						Object.values(productData)[i]
					);
				}
			
				axios
				.post(`${process.env.VUE_APP_ROOT_API_URL}/product`,
				formData,
				{ withCredentials: true })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
		async editProduct(context, {productData, productID}) {
			let formData = new FormData();
			if (productData.img) {
				formData.append("file", productData.img, productData.img.fileName);
			}
			delete productData.img;
			for (let i = 0; i < Object.keys(productData).length; i++) {
				formData.append(
					Object.keys(productData)[i],
					Object.values(productData)[i]
				);
			}
			return new Promise((resolve, reject)=>{
				axios
				.put(`${process.env.VUE_APP_ROOT_API_URL}/product/${productID}`,
				formData,
				{ withCredentials: true })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
	},
};
