<template>
    <div v-if="errorAlert" id="error-alert">
        <ContBox class="cont-box">
            <div @click="closeAlert" class="exit-btn vl-t-s">✖</div>
            <br>
            <div class="error-t-c l-t-s">{{errorAlert}}</div>
            <BtnEnter v-on:clicked="closeAlert" class="btn">Ok</BtnEnter>
        </ContBox>
        
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  computed:{
    ...mapState({
      errorAlert: 'errorAlert'
    })
    },
  methods:{
      closeAlert(){
          this.$store.dispatch('clearErrorAlert')
      }
  }
}
</script>

<style scoped>
    #error-alert{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .cont-box{
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
    }
    .exit-btn{
        float: right;
        width: fit-content;
        cursor: pointer;
    }
    .btn{
        margin: 30px auto 0 auto;
    }
</style>