<template>
    <div @keyup.enter="postLogin">
       <img src="@/assets/header-logo-2.svg" alt="" class="intro-img only-small">
        <ContBox class="cont-box">
            <div class="cont-block">
                <span class="vl-t-s">Login</span>
                <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
                <TextBox ref="email" :title="'Email'" :type="'email'" v-on:valueChanged="email=$event" class="m-t-s"></TextBox><br>
                <TextBox :title="'Password'" :type="'loginPassword'" v-on:valueChanged="password=$event" class="m-t-s"></TextBox><br>
                <!-- <router-link to="forgotPassword" class="s-t-s only-large" style="float: right;">Forgot your password?</router-link> -->
                <BtnSubmit v-on:clicked="postLogin" :enabled="(email&&password)">Login</BtnSubmit>
                <!-- <router-link to="forgotPassword" class="s-t-s c-t-a only-small">Forgot your password?</router-link> -->
            </div>
            <div class="col-sep only-large"></div>
            <div class="logo-cont only-large">
                <img src="@/assets/header-logo.svg" alt="" class="intro-img">
                <img src="@/assets/header-logo-2.svg" alt="" class="intro-img">
            </div>
        </ContBox>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ErrorComment from '@/components/ErrorComment'
export default {
    name: 'login',
    components:{
        ErrorComment
    },
    data(){
        return{
            email: null,
            password: null,
            errorMessage: null
        }
    },
    computed:{
        ...mapGetters({
            myProfile: 'userProfile/myProfile'
        })
    },
    methods:{
        ...mapActions({
            login: 'login'
        }),
        async postLogin(){
            this.login({userIdentifier: this.email, password: this.password})
            .then(response => {
                if(this.myProfile.user_account.type == 'sales_agent'){
                    this.$router.push('/agent/inventory')
                }
                else{
                    this.$router.push('/cms/products')
                }
            })
            .catch(error => {
                window.scroll(0,0)
                this.errorMessage = error.response.data.message
            })
        }
    },
    mounted(){
        this.$refs.email.$refs.inputText.focus()
    },
}
</script>

<style scoped>
    .cont-box{
        display: flex;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
        max-width: 50em;
    }
    .col-sep{
        height: auto;
        width: 1px;
        background-color: #CCCCCC;
    }
    .cont-block{
        width: 100%;
        margin: 1em;
    }
    .logo-cont{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: 1em;
    }
    .logo-cont>*{
        width:50%;
        margin: 1em;
    }
    .intro-img{
        max-height: 10em;
    }
    @media screen and (max-width: 600px) {
        .intro-img{
            max-width: 50%;
            padding-top: 10%;
        }
    }
    
</style>