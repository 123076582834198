import { render, staticRenderFns } from "./PopupFullScreen.vue?vue&type=template&id=f148fb0c&scoped=true&"
import script from "./PopupFullScreen.vue?vue&type=script&lang=js&"
export * from "./PopupFullScreen.vue?vue&type=script&lang=js&"
import style0 from "./PopupFullScreen.vue?vue&type=style&index=0&id=f148fb0c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f148fb0c",
  null
  
)

export default component.exports