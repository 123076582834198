import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
  namespaced: true,
  state: {
    competitorCompanies: []
  },
  getters: {
    competitorCompanies: state => state.competitorCompanies
  },
  mutations: {
    commitCompetitorCompanies(state, competitorCompanies) {
      state.competitorCompanies = competitorCompanies;
    }
  },
  actions: {
    async getCompetitorCompanies(context) {
      return new Promise(resolve => {
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/competitor-companies`, {
            withCredentials: true
          })
          .then(response => {
            context.commit("commitCompetitorCompanies", response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    async getCompetitorCompanyProducts(context, companyID) {
      return new Promise(resolve => {
        axios
          .get(`${process.env.VUE_APP_ROOT_API_URL}/competitor-products/${companyID}`, {
            withCredentials: true
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    }
  }
};
