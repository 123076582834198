<template>
  <div>
    <btn-submit v-if="!selected" :enabled="true" @clicked="selected=true">
      <slot></slot>
    </btn-submit>
    <div v-else class="confirm-box">
      <div v-if="!submiting">
        <div class="m-t-s">{{confMsg||'Are you sure?'}}</div>
        <div class=" ms-t-s conf-items">
          <div @click="$emit('confirmed'); submiting = true" class="b-t-w conf-btn" style="color: green;">Yes</div>
          <div>-</div>
          <div @click="selected=false" class="b-t-w conf-btn" style="color: red;">No</div>
        </div>
      </div>
      <div v-else class="gray-t-c b-t-w l-t-s">Loading</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'button-with-confirm',
  props: ['confMsg'],
  data() {
    return {
      selected: false,
      submiting: false,
    }
  },
}
</script>

<style scoped>
.confirm-box{
  padding: 0 .5em;
  border: 1px lightgray solid;
  border-radius: .2em;
}
.conf-items{
  display: flex;
  justify-content: space-between;
  cursor: default;
}
.conf-btn{
  cursor: pointer;
}
</style>