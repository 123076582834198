<template>
  <div id="menu-bar">
    <transition name="fade2" v-if="menuOpen">
      <div class="menu-back" @click="menuOpen=false"></div>
    </transition>
    <transition name="slide">
      <div id="menu" v-if="menuOpen">
        <div class="header-padding"></div>
        <div class="side-menu primary-b-c">
          <table v-if="checkIn" class="menu-tbl l-t-a l-t-s">
            <tr @click="$router.push('/agent/engagements'); menuOpen=false">
              <td><img class="menu-icon" src="@/assets/menu-icons/requests.svg" alt=""></td>
              <td>Engagements</td>
            </tr>
            <tr v-if="inventoryEnabled" @click="$router.push('/agent/inventory'); menuOpen=false">
              <td><img class="menu-icon" src="@/assets/menu-icons/IPP.svg" alt=""></td>
              <td>Count Stock</td>
            </tr>
            <tr @click="$router.push('/agent/sell'); menuOpen=false">
              <td><img class="menu-icon" src="@/assets/menu-icons/requests.svg" alt=""></td>
              <td>Sales</td>
            </tr>
            <tr v-if="redemptionEnabled" @click="$router.push('/agent/redemptions'); menuOpen=false">
              <td><img class="menu-icon" src="@/assets/menu-icons/requests.svg" alt=""></td>
              <td>Redemptions</td>
            </tr>
          </table>
          <br>
          <table v-if="checkIn" class="s-t-s check-in-tbl l-t-a">
            <tr>
              <td>Location:</td>
              <td class="lgray-t-c">{{checkIn.location.name}}</td>
            </tr>
            <tr>
              <td>Account:</td>
              <td class="lgray-t-c">{{checkIn.location.account}}</td>
            </tr>
            <tr>
              <td>State:</td>
              <td class="lgray-t-c">{{checkIn.location.state}}</td>
            </tr>
            <tr>
              <td>City:</td>
              <td class="lgray-t-c">{{checkIn.location.city}}</td>
            </tr>
            <tr>
              <td>Check in Time:</td>
              <td class="lgray-t-c">{{formatDateTime(checkIn.createdAt)}}</td>
            </tr>
          </table>
          <div class="user-cont">
            <div v-if="myProfile" class="prof-box" @click="profExpanded=!profExpanded">
              <div class="prof-name ms-t-s">{{myProfile.displayName}}</div>
              <div class="prof-pic-cont"><img class="prof-pic" src="@/assets/prof-pic.jpg" alt=""></div>
            </div>
            <div v-else>
                Login
            </div>
            <transition name="expand">
              <div v-if="profExpanded">
                <table class="nav-tbl">
                  <tr>
                    <td><img src="@/assets/menu-icons/profile.svg" alt="" class="menu-icon"></td>
                    <td class="m-t-s menu-txt" @click="$router.push('/agent/myProfile')">My Profile</td>
                  </tr>
                  <tr v-if="checkIn" @click="$router.push('/agent/check-out'); menuOpen = false">
                    <td><img src="@/assets/menu-icons/checkout.svg" alt="" class="menu-icon"></td>
                    <td class="m-t-s menu-txt">Check Out</td>
                  </tr>
                  <tr v-if="!checkIn" @click="logout()">
                    <td><img src="@/assets/menu-icons/logout.svg" alt="" class="menu-icon"></td>
                    <td class="m-t-s menu-txt">Log Out</td>
                  </tr>
                </table>
              </div>
            </transition>
            <div class="side-menu-padding"></div>
          </div>
        </div>
      </div>
    </transition>
    <div ref="bar" class="l-t-s l-t-a primary-b-c header-bar">
      <div style="float: left;" class="menu-btn" @click="menuOpen=!menuOpen">
        <div class="burger-slice"></div>
        <div class="burger-slice"></div>
        <div class="burger-slice"></div>
      </div>
      <div class="ms-t-s">{{$route.name}}</div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'menuBar',
  data() {
    return {
      menuOpen: false,
      profExpanded: false
    }
  },
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile',
      checkIn: 'checkIn/checkIn',
    }),
    inventoryEnabled(){
      return(process.env.VUE_APP_FEATURE_INVENTORY != 'false')
    },
    redemptionEnabled(){
      return(process.env.VUE_APP_FEATURE_REDEMPTION != 'false')
    },
  },
  methods:{
    ...mapActions({
      checkOut: 'checkIn/checkOut',
      logout: 'logout'
    }),
    async callCheckOut(){
      await this.checkOut()
      this.$router.push('/agent/check-in')
    }
  }
}
</script>

<style scoped>
#menu-bar{
  position: absolute;
  top: 0;
  z-index: 1;
}
.header-bar{
  display: flex;
  align-items: center;
  color: white;
  padding: .3em;
  position: fixed;
  top: 0;
  width: 100%;
}
.menu-btn{
    margin: .3em;
    cursor: pointer;
}
.burger-slice{
    width: 30px;
    height: 5px;
    margin-bottom: 5px;
    background-color: white;
    border-radius: 5px;
}
.burger-slice:last-child{
    margin-bottom: 0;
}
.header-padding{
  width: 100%;
  padding: 1.3em;

}
#menu{
  position: fixed;
  height: 100vh;
  top: 0;
  color: white;
}
.menu-back{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.side-menu{
  position: absolute;
  width: 15em;
  height: calc(100% - 2.6em);
  z-index: 1;
}
.user-cont{
  position: fixed;
  width: 15em;
  bottom: 0;
  background-color: #18273A;
}
.prof-box{
    display: flex;
    padding: .4em .8em;
    width: 100%;
    justify-content: space-between;
    border-top: 1px black solid;
    align-items: center;
}
.prof-pic-cont{
    width: 2em;
    height: 2em;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
}
.prof-name{
    display: flex;
    max-width: 75%;
    justify-items: center;
}
.prof-pic{
    height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}
.nav-tbl{
  width: 100%;
}
.menu-icon{
  width: 2.5em;
  padding: 0em .5em;
}
.menu-txt{
  width: 100%;
}
.menu-tbl{
  width: 100%
}
.menu-tbl td{
  padding: 1em 0;
  border-bottom: 1px black solid;
}

.check-in-tbl{
  background: rgba(0,0,0,0.15);
}
.check-in-tbl td{
  padding: .2em .4em;
  border-bottom: 1px black solid;
  vertical-align: top;
}

.slide-enter-active, .slide-leave-active{
    position: absolute;
    transition: left .2s ease-in-out;
    left: 0;
}
.slide-enter, .slide-leave-to{
    position: absolute;
    left: -60%;
}

.expand-enter-active, .expand-leave-active{
    transition: max-height .2s ease;
    max-height: 5em;
    overflow: hidden;
}
.expand-enter, .expand-leave-to{
    max-height: 0;
}

.fade2-enter-active, .fade2-leave-active{
    opacity: 1;
    transition: opacity .5s;
}
.fade2-enter, .fade2-leave-to{
    opacity: 0;
}
</style>
