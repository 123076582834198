<template>
  <div>
    <NavBar :selected="'products'"></NavBar>
    <BodyCont class="mini-form">
      <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
      <TextBox :initVal="sku" class="input-box" :type="'text'" title="SKU Code:" v-on:valueChanged="sku=$event" :placeHolder="'Ex: A-001'"></TextBox>
      <TextBox :initVal="name" class="input-box" :type="'text'" title="Product Name:" v-on:valueChanged="name=$event" :placeHolder="'Ex: Apple iPhone SE A2296 Dual sim'"></TextBox>
      <div class="img-div">
          <BtnEnter v-on:clicked="takePicture">{{!referenceImage?'Add Picture':'Change Picture'}}</BtnEnter>
          <!-- <input id="product-pic" type="file" accept="image/*" capture @change="picUpdate" style="display:none"> -->
          <image-uploader
          id="product-pic"
          :debug="1"
          :maxWidth="1000"
          :maxHeight="1000"
          :quality="0.7"
          outputFormat="verbose"
          :preview=false
          :capture="true"
          accept="image/*"
          doNotResize="['gif', 'svg']"
          @input="picUpdate"
          style="display: none;"
          ></image-uploader>
          <img v-show="referenceImage" :src="referenceImage" id="imgshow" align="left">
      </div>
      <br>
      <TextBox :initVal="description" :type="'textarea'" title="Description:" v-on:valueChanged="description=$event" style="width: 100%;"></TextBox><br>
      <BtnSubmit :enabled="enableBtn&&sku&&name" v-on:clicked="submitProduct">{{btnText}}</BtnSubmit>
    </BodyCont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ErrorComment from '@/components/ErrorComment'
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar'
import ImageUploader from 'vue-image-upload-resize'

export default {
    name: 'add-product',
    components: {
        ErrorComment,
        BodyCont,
        NavBar,
        ImageUploader
    },
    data() {
        return {
            sku: null,
            name: null,
            btnText: "Add Product",
            enableBtn: true,
            errorMessage: null,
            description: null,
            picture: null,
            referenceImage: null
        }
    },
    computed:{
        ...mapGetters({
            // myProfile: 'userProfile/myProfile'
        }),
        submitMethod(){
            return(this.$route.params.method)
        },
        referenceProductID(){
            return(this.$route.params.productID)
        },

    },
    methods:{
        ...mapActions({
            getProduct: 'product/getProduct',
            createProduct: 'product/createProduct',
            editProduct: 'product/editProduct',
        }),
        async submitProduct(){
            this.btnText = "Submitting.."
            this.errorMessage = null
            this.enableBtn=false
            let productData = {
                sku: this.sku,
                name: this.name,
                description: this.description,
                img: this.picture
            }
            if(this.submitMethod == 'create'){
                await this.createProduct(productData)
                .then(res=>{
                    this.$router.push('/cms/products')
                })
                .catch(err=>{
                    this.errorMessage = err.response.data.message
                    window.scroll(0,0)
                    this.btnText = "Add Product"
                    this.enableBtn = true
                })
            }
            else{
                await this.editProduct({productData, productID: this.referenceProductID})
                .then(res=>{
                    this.$router.push('/cms/products')
                })
                .catch(err=>{
                    console.log(err)
                    this.errorMessage = err.response.data.message
                    window.scroll(0,0)
                    this.btnText = "Edit Product"
                    this.enableBtn = true
                })
            }
        },
        async picUpdate(value){
            this.picture = await this.formatImage(value)
            this.referenceImage = value.dataUrl
        },
        takePicture(){
            document.getElementById('product-pic').click()
        }
    },
    async mounted(){
        if(this.submitMethod == 'edit'){
            this.btnText = 'Edit Product'
            await this.getProduct(this.referenceProductID)
            .then(referenceProduct=>{
                this.sku = referenceProduct.sku
                this.name = referenceProduct.name
                this.description = referenceProduct.description
                this.referenceImage = referenceProduct.image
            })
            .catch(err=>{
                this.errorMessage = err.response.data.message
                window.scroll(0,0)
            })
        }
    }
}
</script>

<style scoped>
.mini-form{
    max-width: 40em;
}
.switch{
    text-align: left;
}
.dimension-input{
    width: 30%;
    padding-right: 1em;
}
.dimension-box{
    display: flex;
}
.input-box{
    margin-bottom: 1em;
}
.img-div{
display: flex;
width: 100%;
justify-content: space-between;
}
#imgshow{
    height: 2em;
    overflow: hidden;
}
</style>
