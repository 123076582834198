<template>
  <div>
    <div>
      <label>
        <div v-if="search" class="search-form">
            <gmap-autocomplete
            @place_changed="setPlace"
            class="search-txt m-t-s">
            </gmap-autocomplete>
            <BtnEnter class="serch-btn" v-on:clicked="addMarker">Search</BtnEnter>
        </div>
      </label>
      <br/>
    </div>
    <gmap-map
      :center="center"
      :zoom="15"
      style="width:100%;  height: 400px;"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUI: false
      }"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: ['search', "selectedLocation", "myLocation"],
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 3.1132884, lng: 101.5767683 },
      markers: [],
      places: [],
      currentPlace: null
    };
  },

  mounted() {
    this.geolocate();
    if(this.selectedLocation){
      this.center = { lat: this.selectedLocation.lat, lng: this.selectedLocation.lng }
      this.markers.push({ position: this.selectedLocation });
    }
  },

  methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
          console.log(this.currentPlace)
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
          address: this.currentPlace.formatted_address
        };
        this.$emit('locationMarked', marker)
        console.log(marker)
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        console.log(this.marker)
        this.currentPlace = null;
        // console.log(this.markers)
      }
    },
    geolocate: function() {
      console.log(this.center)
      navigator.geolocation.getCurrentPosition(position => {
        if(this.search&&this.myLocation){
            this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
        }
      });
    }
  }
};
</script>

<style scoped>
.search-form{
    width: 100%;
    display: flex;
}
.search-txt{
    padding: .4em;
    width: 90%;
}
.serch-btn{
    width: 20%;
}
</style>