<template>
    <div id="tab-btn" :class="selected?'selected':'not-selected'" class="ms-t-s" @click="tabClicked">
        {{tabTitle}}
    </div>
</template>

<script>
export default {
    props: ['tabTitle','selected'],
    methods:{
        tabClicked(){
            this.$emit('tabClicked')
        }
    }
}
</script>

<style scoped>
    #tab-btn{
        padding: 5px 10px;
        outline: 1px #E3E3E3 solid;
        cursor: pointer;
    }
    .selected{
        background-color: #F36711;
        color: white;
    }
    .not-selected{
        background-color: #F9FBFC;
    }
</style>