<template>
    <div v-if="myProfile" id="top-bar" class="white-b-c">
        <div class="header-props-left" @click="goHome">
            <img class="header-logo" src="@/assets/header-logo.svg" alt="">
            <img class="header-logo-text only-large" src="@/assets/header-logo-2.svg" >
        </div>
        <div class="header-props-right">
            <div class="props">
                <drop-down-menu :options="menuOptions"
                v-on:viewProfileClicked="$router.push('/cms/myProfile')"
                v-on:logoutClicked="logout()"></drop-down-menu>
                
                <div class="user-dets only-large">
                    <span class="primary-t-c m-t-s">{{myProfile.displayName}}</span>
                    <span class="gray-t-c s-t-s">
                        {{myProfile.user_account.type=='admin'?'Administrator':this.capitalizeFirstLetter(myProfile.user_account.type)}}
                    </span>
                </div>
                <div class="prof-pic-cont">
                    <img class="prof-pic" src="@/assets/prof-pic.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DropDownMenu from '@/components/DropDownMenu'

export default {
    name: 'topBar',
    data(){
        return{
            menuOptions:[
                {
                    title: 'View Profile',
                    name: 'viewProfile',
                    icon: 'view-profile.svg'
                },
                {
                    title: 'Logout',
                    name: 'logout',
                    icon: 'logout.svg'
                }
            ]
        }
    },
    components:{
        DropDownMenu
    },
    computed:{
        ...mapGetters({
            myProfile: 'userProfile/myProfile'
        }),
    },
    methods:{
        ...mapActions({
            getMyProfile: 'userProfile/getMyProfile',
            logout: 'logout',
            getMyProfile: 'userProfile/getMyProfile',
        }),
        goHome(){
            if (this.$router.currentRoute.fullPath!='/'){
                this.$router.push('/')
            }
        }
    },
    async mounted() {
       console.log(this.myProfile)
    },
}
</script>

<style scoped>
    #top-bar{
        position: relative;
        padding: 0 10%;
        height: 3em;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 0 4px rgb(184, 184, 184);
        background: white;
    }
    .header-props-left{
        display: flex;
        height: 100%;
        cursor: pointer;
    }
    .header-logo{
        height: 100%;
        padding: .5em;
    }
    .header-logo-text{
        width: 6em;
    }
    .header-props-right{
        position: relative;
        height: 100%;
    }
    .props{
        display: flex;
        direction: rtl;
        align-items: center; 
        float: right;
        height: 100%;
        margin: auto 0;
        padding: 0 0.5em;
    }
    .prof-pic-cont{
        height: 3.5em;
        width: 3.5em;
        display: flex;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        float: left;
    }
    .prof-pic{
        width: 100%;
        overflow: hidden;
        display: block;
    }
    .user-dets{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: left;
    }
</style>