<template>
    <div>
        <NavBar :selected="'locations'"></NavBar>
        <BodyCont class="mini-form m-t-s">
            <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>

            <div v-if="!loading">
                <TextBox :type="'text'" :initVal="name" title="Name:" v-on:valueChanged="name=$event"  :placeHolder="'Ex: Intermark Mall'"></TextBox><br>
                <google-map :search="true" v-on:locationMarked="setLocation($event)"
                :selectedLocation="referenceGeoLocation"/><br/>
                <div class="selectors">
                    <div class="select-cont">
                        <div class="m-t-s b-t-w">Account:</div>
                        <v-select v-if="!addAccount" placeholder="Account" class="select-box m-t-s" v-model="selectedAccount" :options="account">
                            <span slot="no-options" style="cursor: pointer;" @click="addAccount=true">Click to add new Account</span>
                        </v-select>
                        <TextBox v-else :type="'text'" v-on:valueChanged="selectedAccount=$event.toUpperCase()" :placeHolder="'TESCO'"></TextBox>
                    </div>
                    <div class="select-cont">
                        <div class="m-t-s b-t-w">Region:</div>
                        <v-select v-if="!addRegion" placeholder="regions" class="select-box m-t-s" v-model="selectedRegion" :options="region">
                            <span slot="no-options" style="cursor: pointer;" @click="addRegion=true">Click to add new Region</span>
                        </v-select>
                        <TextBox v-else :type="'text'" v-on:valueChanged="selectedRegion=$event.toUpperCase()" :placeHolder="'SELANGOR'"></TextBox>
                    </div>
                    <div class="select-cont">
                        <div class="m-t-s b-t-w">State:</div>
                        <v-select v-if="!addState" placeholder="state" class="select-box m-t-s" v-model="selectedState" :options="state">
                            <span slot="no-options" style="cursor: pointer;" @click="addState=true">Click to add new State</span>
                        </v-select>
                        <TextBox v-else :type="'text'" v-on:valueChanged="selectedState=$event.toUpperCase()" :placeHolder="'SELANGOR'"></TextBox>
                    </div>
                    <div class="select-cont">
                        <div class="m-t-s b-t-w">City:</div>
                        <v-select v-if="!addCity" placeholder="city" class="select-box m-t-s" v-model="selectedCity" :options="city">
                            <span slot="no-options" style="cursor: pointer;" @click="addCity=true">Click to add new City</span>
                        </v-select>
                        <TextBox v-else :type="'text'" v-on:valueChanged="selectedCity=$event.toUpperCase()" :placeHolder="'PETALING JAYA'"></TextBox>
                    </div>
                    

                    <div class="select-cont">
                        <div class="m-t-s b-t-w">Manager:</div>
                        <v-select class="select-box m-t-s" label="displayName" v-model="manager" :options="managers" :menu-props="{ top: true, offsetY: true }"
                        append-to-body :calculate-position="withPopper"></v-select>
                    </div>
                    <br>
                </div>

                <BtnSubmit :enabled="name&&address&&locationLat&&locationLon&&enableBtn&&selectedAccount&&selectedCity&&selectedState&&selectedRegion&&(myProfile.user_account.Type!='admin'||manager)" v-on:clicked="submitLocation">{{btnText}}</BtnSubmit>
            </div>
        </BodyCont>
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import { createPopper } from '@popperjs/core'
import Switches from 'vue-switches';
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar'
import ErrorComment from '@/components/ErrorComment'
import GoogleMap from "@/components/GoogleMap"

export default {
    name: 'locationForm',
    components: {
        BodyCont,
        NavBar,
        ErrorComment,
        GoogleMap,
        Switches,
    },
    data() {
        return {
            name: null,
            selectedAccount: null,
            addAccount: false,
            selectedCity: null,
            addCity: false,
            selectedState: null,
            addState: false,
            selectedRegion: null,
            addRegion: false,
            address: null,
            locationLat: null,
            locationLon: null,
            btnText: "Create Location",
            managers: [],
            manager: null,
            enableBtn: true,
            errorMessage: null,
            referenceGeoLocation: null,
            loading: true
        }
    },
    computed:{
        ...mapState({
                users: 'users'
            }),
        ...mapGetters({
            myProfile: 'userProfile/myProfile',
            region: 'location/region',
            state: 'location/state',
            account: 'location/account',
            city: 'location/city',
        }),
        submitMethod(){
            return(this.$route.params.method)
        },
        referenceLocationID(){
            return(this.$route.params.locationID)
        }
    },
    methods:{
        ...mapActions({
            createLocation: 'location/createLocation',
            editLocation: 'location/editLocation',
            fetchUsersByType: 'userProfile/fetchUsersByType',
            getLocationKey: 'location/getKeys',
            getLocation: 'location/getLocation',
        }),
        setLocation(geo){
            console.log(geo)
            this.locationLat = geo.lat
            this.locationLon = geo.lng
            this.address = geo.address
        },
        async submitLocation(){
            this.btnText = "Submitting.."
            this.errorMessage = null
            this.enableBtn=false
            let locationData = {
                name: this.name,
                address: this.address,
                locationLat: this.locationLat,
                locationLon: this.locationLon,
                account: this.selectedAccount,
                city: this.selectedCity,
                state: this.selectedState,
                region: this.selectedRegion,
            }
            if (this.manager){
                locationData.managerID = this.manager.ID
            }
            if(this.submitMethod == 'create'){
                this.createLocation(locationData)
                .then(res=>{
                this.$router.push('/cms/locations')
                })
                .catch(error=>{
                    this.errorMessage = error.response.data.message
                    window.scroll(0,0)
                    this.btnText = "Create Location"
                    this.enableBtn = true
                })
            }
            else{
                this.editLocation({locationData, locationID: this.referenceLocationID}) 
                .then(res=>{
                    this.$router.push('/cms/locations')
                })
                .catch(error=>{
                    this.errorMessage = error.response.data.message
                    window.scroll(0,0)
                    this.btnText = "Edit Location"
                    this.enableBtn = true
                })
            }
        },

        withPopper (dropdownList, component, {width}) {
            dropdownList.style.width = width;
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: 'top',
                modifiers: [
                {
                    name: 'offset', options: {
                    offset: [0, -1]
                    }
                },
                {
                    name: 'toggleClass',
                    enabled: true,
                    phase: 'write',
                    fn ({state}) {
                    component.$el.classList.toggle('drop-up', state.placement === 'top')
                    },
                }]
            });
            return () => popper.destroy();
        }
    },
    async mounted(){
        if (this.myProfile.user_account.type=='admin'){
            this.managers = await this.fetchUsersByType('manager')
        }
        await this.getLocationKey('account')
        await this.getLocationKey('city')
        await this.getLocationKey('region')
        await this.getLocationKey('state')
        if(this.submitMethod == 'edit'){
            this.btnText = "Edit Location"
            this.getLocation(this.referenceLocationID)
            .then(referenceLocation=>{
                this.name = referenceLocation.name,
                this.selectedRegion = referenceLocation.region
                this.selectedState = referenceLocation.state
                this.selectedCity = referenceLocation.city
                this.selectedAccount = referenceLocation.account
                this.manager = this.managers.find(manager=>manager.ID = referenceLocation.managerID)
                this.referenceGeoLocation = {lat: referenceLocation.locationLat, lng: referenceLocation.locationLon}
                this.locationLat = referenceLocation.locationLat
                this.locationLon = referenceLocation.locationLon
                this.address = referenceLocation.address
                this.loading = false
            })
            .catch(err=>{
                this.errorMessage = err.response.data.message
                window.scroll(0,0)
            })
        }
        else{
            this.loading = false
        }
    }
}
</script>

<style scoped>
.mini-form{
    max-width: 40em;
}
.switch{
    text-align: left;
}
.selectors{
    width: 70%;
}
.select-cont{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}
.select-box{
    width: 70%;
}
.v-select.drop-up.vs--open .vs__dropdown-toggle {
border-radius: 0 0 4px 4px;
border-top-color: transparent;
border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
border-radius: 4px 4px 0 0;
border-top-style: solid;
border-bottom-style: none;
box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15)
}

</style>
