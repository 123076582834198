<template>
  <div class="l-t-a">
    <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
    <div v-if="enabled" class="alert-msg">
      <p class="m-t-s">Are you sure you want to check out?</p>
      <div class="alert-btns ms-t-s">
          <BtnSubmit :enabled="enabled" v-on:clicked="confirmCheckOut">Check Out</BtnSubmit>
          <BtnEnter v-on:clicked="clearAlert">Cancel</BtnEnter>
      </div>
    </div>
    <div>
      <span>To check out, please take a photo at your location</span>
    </div>
    <BtnSubmit :enabled="true" class="camera-button" @clicked="takePicture">
      {{!this.picture?'Take Photo':'Change Photo'}}
      </BtnSubmit>
      <image-uploader
        id="check-out-pic"
        :debug="1"
        :maxWidth="1000"
        :maxHeight="1000"
        :quality="0.7"
        outputFormat="verbose"
        :preview=false
        capture="user"
        class="camera"
        accept="image/*"
        doNotResize="['gif', 'svg']"
        @input="picUpdate"
      ></image-uploader>
    
    <div class="flex-row justify-center align-center w-100">
      <img v-show="picture" src="#" ref="checkOutImg" style="max-height: 10em;">
    </div>
    <BtnSubmit :enabled="picture" v-on:clicked="enabled = true" class="checkin-btn">Check Out</BtnSubmit>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ImageUploader from 'vue-image-upload-resize'
import ErrorComment from '@/components/ErrorComment'


export default {
  name: 'checkOut',
  components:{
    ErrorComment,
    ImageUploader,
  },
  data() {
    return {
      userLocation: null,
      enabled: false,
      picture: null,
      errorMessage: null,
      btnEnabled: true
    }
  },
  methods:{
    ...mapActions({
      checkOut: 'checkIn/checkOut'
    }),
    takePicture() {
      document.getElementById('check-out-pic').click()
    },
    async picUpdate(value){
      this.picture = await this.formatImage(value)
      let img = this.$refs.checkOutImg;
      img.src = value.dataUrl
    },
    async confirmCheckOut(){
      this.btnEnabled = false
      let checkOutData = {
        file: this.picture,
      }
      if(this.userLocation){
        checkOutData.locationLon = this.userLocation.lng
        checkOutData.locationLat = this.userLocation.lat
      }
      this.checkOut(checkOutData)
        .then((result) => {
          this.$router.push('/agent/check-in');
        })
        .catch((error) => {
          this.btnEnabled = true
          console.log('error in checkout > ', error);
          this.errorMessage = error.response.data.message
        });
      this.enabled = false
      this.clearAlert();
    },
    clearAlert(){
      this.enabled = false;
    }
  },
  async mounted() {
    this.$getLocation({
      enableHighAccuracy: false, //defaults to false
      timeout: Infinity, //defaults to Infinity
      maximumAge: 0 //defaults to 0
    })
    .then(coordinates => {
      this.userLocation = coordinates
    });
  }
}
</script>

<style scoped>
.checkin-btn{
    margin: 5em auto;
    display: flex !important;
    justify-content: center;
    width: 100% !important;
}
.alert-msg{
    position: fixed;
    width: 80%;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    min-width: 13em;
    padding: 1em;
    background-color: white;
    border: 1px #ff9393 solid;
    border-radius: 8px;
    z-index: 9999;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}
.alert-msg p{
    margin-bottom: 1em;
}
.alert-btns{
    display: flex;
    justify-content: space-around;
}

.camera-button {
  position: relative;
  margin: 1.5em auto;
  display: flex !important;
  justify-content: center;
  width: 100% !important;
}

.camera {
  display: none;
}


</style>
