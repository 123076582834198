<template>
    <div v-if="myProfile" id="nav-bar" class="white-b-c ms-t-s">
        <!-- <router-link v-if="myProfile.Type=='admin'||myProfile.Type=='employer'||myProfile.Type=='recruiter'||myProfile.Type=='finance'" to="/"><NavBtn :selected="selected=='dashboard'?true:false" class="nav-btn m-t-s" :navIcon="'dashboard.svg'">Dashboard</NavBtn></router-link> -->
        <router-link to="/cms/products"><NavBtn :selected="selected=='products'?true:false" class="nav-btn m-t-s" :navIcon="'product.svg'">Products</NavBtn></router-link>
        <router-link to="/cms/barcode-scanners"><NavBtn :selected="selected=='barcodes'?true:false" class="nav-btn m-t-s" :navIcon="'scanner.svg'">Barcode Scanners</NavBtn></router-link>
        <router-link to="/cms/locations"><NavBtn :selected="selected=='locations'?true:false" class="nav-btn m-t-s" :navIcon="'location.svg'">Locations</NavBtn></router-link>
        <router-link to="/cms/users"><NavBtn :selected="selected=='users'?true:false" class="nav-btn m-t-s" :navIcon="'users.svg'">Users</NavBtn></router-link>
        <router-link to="/cms/check-ins"><NavBtn :selected="selected=='checkIns'?true:false" class="nav-btn m-t-s" :navIcon="'todo.svg'">Check Ins</NavBtn></router-link>
        <router-link to="/cms/inventory"><NavBtn :selected="selected=='inventory'?true:false" class="nav-btn m-t-s" :navIcon="'inventory.svg'">Inventory</NavBtn></router-link>
        <router-link to="/cms/sales"><NavBtn :selected="selected=='sales'?true:false" class="nav-btn m-t-s" :navIcon="'stock.svg'">Sales</NavBtn></router-link>
        <router-link to="/cms/engagements"><NavBtn :selected="selected=='engagements'?true:false" class="nav-btn m-t-s" :navIcon="'stock.svg'">Engagements</NavBtn></router-link>
        <router-link to="/cms/redemptions"><NavBtn :selected="selected=='redemptions'?true:false" class="nav-btn m-t-s" :navIcon="'stock.svg'">Redemptions</NavBtn></router-link>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NavBtn from '@/components/NavBtn'
export default {
    components:{
        NavBtn
    },
    props:['selected'],
    computed:{
        ...mapGetters({
            myProfile: 'userProfile/myProfile'
        })
    }
}
</script>

<style scoped>
    #nav-bar{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
        border-top: 2px #e6e6e6 solid;
        flex-wrap: wrap;
        font-weight: 700;
        user-select: none;
    }
    a{
        text-decoration: none;
        height: 100%;
        color: #0D1A48;
    }
</style>