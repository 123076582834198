import axios from "axios";
import util from "../../lib/util";

axios.defaults.withCredentials = true;

export default {
	namespaced: true,
	state: {
		sales: [],
	},
	getters: {
		sales: (state) => state.sales,
	},
	mutations: {
		commitSales(state, sales) {
			console.log("MUTATION CALLED", sales);
			state.sales = sales;
		},
	},
	actions: {
		async sell(context, saleData) {
			return new Promise((resolve, reject)=>{
				axios
				.post(`${process.env.VUE_APP_ROOT_API_URL}/sell`,
				saleData,
				{	withCredentials: true, })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},

		async getSales(context, query) {
			context.commit('commitSales', {})
			return new Promise((resolve, reject)=>{
				query = util.methods.getQueryStringFromObj(query)
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/sales${query}`,
				{	withCredentials: true, })
				.then((response) => {
					context.commit('commitSales', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},

    async getSalesReport(context) {
      return new Promise((resolve, reject) => {
        axios
        .get(`${process.env.VUE_APP_ROOT_API_URL}/report`,
        { withCredentials: true })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
      })
    },

		async getInventorySales (context, inventoryID) {
			return new Promise((resolve, reject)=>{
				axios
				.get(`${process.env.VUE_APP_ROOT_API_URL}/sales/inventory/${inventoryID}`,
				saleData,
				{	withCredentials: true, })
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			})
		},
	},
};
