<template>
  <div>
    <btn-enter @clicked="$router.push('/agent/redemption');">Submit new Redemption</btn-enter>
    <br>
    <div class="m-t-s b-t-w l-t-a">Submition History:</div>
    <div v-if="redemptions && redemptions.rows">
      <div v-for="redemption in redemptions.rows" :key="redemption.ID" class="redemption-box">
        <div class="redemption-header s-t-s gray-t-c">
          <div>{{formatDateTime(redemption.createdAt)}}</div>
          <div>{{redemption.ID}}</div>
        </div>
        <div class="redemption-body">
          <div class="l-t-a" style="width: 100%;">
            <ul class="m-t-s">
              <li v-for="giveawayProduct in redemption.giveawayProducts" :key="giveawayProduct.ID" class="ms-t-s">
                ({{giveawayProduct.quantity}}) {{giveawayProduct.giveawayProduct}}
              </li>
            </ul>
            <div class="s-t-s" v-if="redemption.remarks" style="background: rgba(0,0,0,0.05); padding-left: .5em; width: 100%;">
              <span class="b-t-w">Remarks: </span> <span class="gray-t-c">{{redemption.remarks}}</span>
            </div>
          </div>
          <button-with-confirm @confirmed="requestRemoveRedemption(redemption.ID)" class="ms-t-s">Remove</button-with-confirm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import ButtonWithConfirm from '../../components/ButtonWithConfirm.vue'
export default {
  components: { ButtonWithConfirm },
  name: 'redemptions',
  computed:{
    ...mapGetters({
      redemptions: 'redemption/redemptions',
    })
  },
  methods: {
    ...mapActions({
      getRedemptions: 'redemption/getRedemptions',
      removeRedemption: 'redemption/removeRedemption',
    }),
    ...mapMutations({
      pushAlert: 'pushAlert',
    }),
    async requestRemoveRedemption(redemptionID){
      await this.removeRedemption(redemptionID)
      .then(res => {
        this.pushAlert({text: res.message, type: 'success'})
      })
      .catch(err => {
        this.pushAlert({text:err.response.data.message, type: 'fail'})
      })
      this.getRedemptions()
    }
  },
  mounted() {
    this.getRedemptions()
    .catch(err => {
      this.pushAlert({text:err.response.data.message, type: 'fail'})
      this.submitting = false
    })
  },
}

</script>

<style scoped>
.redemption-box{
  margin: .5em 0;
  border: 1px lightgray solid;
  background:  white;
  border-radius: .3em;
}
.redemption-header{
  display: flex;
  justify-content: space-between;
  padding: .5em;
  border-bottom: 1px lightgray solid;
}
.redemption-body{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: .5em;
  width: 100%;
}
</style>