<template>
    <div>
        <table>
            <thead>
                <th v-for="(key, index) of visCols" :key="index" class="ms-t-s b-t-w">{{alias(key)}}</th>
            </thead>
            <tbody>
                <tr v-for="(row, index) of tableItems" :key="index"
                :ref="'row_'+index">
                    <td v-for="(key, index) of visCols" :key="index"
                    :class="'s-t-s' + ' ' + (cols[key].class ? cols[key].class : '')"
                     @click="cols[key].type!='btns'?$emit('clickedItem',row):false">
                        <div v-if="cols[key].type=='btns'">
                            <div v-for="(btn, index) in cols[key].btns" :key="index"
                            class="btn"
                            :class="{
                                greenBtn: btn=='Approve'|| btn=='accept' || btn=='shortlist',
                                redBtn: btn=='Reject'|| btn=='reject' || btn=='delist',
                                disabled: row['Status']!='pending'&&(btn=='Approve'||btn=='Reject')
                            }" @click="$emit('btn-click',{btn: btn, row: row})">
                                {{capitalizeFirstLetter(btn)}}
                            </div>
                        </div> 
                        <div v-else :class="getClass(cols[key].type, row[key])">
                            {{row[key]!=null?
                            formatters[cols[key].type]?formatters[cols[key].type](row[key]):row[key]:
                            '-'}}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props:['tableItems', 'cols'],
    data() {
        return {
            classes:{},
            types:{},
            formatters: {
                'text': (value)=>value,
                'date': this.formatDate,
                'time': this.formatTime,
                'datetime': this.formatDateTime,
                'number': this.formatNumber,
                'money': this.formatMoney,
                'quantity': this.formatQuantity
            }
        }
    },
    computed:{
        visCols(){
            let visCols = []
            Object.keys(this.cols).forEach(col => {
                if(Object.keys(this.tableItems[0]).includes(col)||!Object.keys(this.formatters).includes(this.cols[col].type)){
                    visCols.push(col)
                }
            })
            return(visCols)
        }
    },
    methods:{
        getFormated(type, value){
            switch (type) {
                case 'text':return value;break;
                case 'date':return this.formatDate(value);break;
                case 'time':return this.formatTime(value);break;
                case 'datetime':return this.formatDateTime(value);break;
                case 'number':return this.formatNumber(value);break;
                case 'money':return this.formatMoney(value);break;
                case 'quantity':return(value<6?value:value+"+");break;
            
                default:return value;break;
            }
        },
        blinkRow(index){
            this.$refs['row_'+index][0].classList.add('blink')
            console.log(this.$refs['row_'+index][0])
        },
        alias(key) {
          return this.cols[key].alias ? this.cols[key].alias : key;
        },
        getClass(type, value){
            if(type=='status'){
                if(value == 'published'|| value == 'approved')
                    return('greenCapsule')
                else if(value == 'completed'|| value == 'unpublished')
                    return('grayCapsule')
                else if(value == 'deleted'||value == 'rejected')
                    return('redCapsule')
                else if(value == 'ongoing'|| value == 'pending')
                    return('orangeCapsule')
                else if(value == 'draft')
                    return('yellowCapsule')
            }
            else if(type=='applicationStatus'){
                if(value == 'accepted')
                    return('greenCapsule')
                else if(value == 'declined')
                    return('darkRedCapsule')
                else if(value == 'rejected')
                    return('redCapsule')
                else if(value == 'kiv')
                    return('orangeCapsule')
                else if(value == 'applied')
                    return('yellowCapsule')
                else if(value == 'shortlisted')
                    return('lightGreenCapsule')
                else if(value == 'delisted')
                    return('lightRedCapsule')
                else if(value == 'confirmed')
                    return('darkGreenCapsule')
            }

        }
    }
}
</script>

<style scoped>
    #tbl-cont{
        border: 1px #E3E3E3 solid;
        border-radius: .4em;
        margin-top: 20px;
        width: max-content;
        min-width: 100%;
        overflow: hidden;
    }
    table{
        width: 100%;
        border-collapse: collapse;
    }
    th{
        padding: .8em .2em;
        border: 1px solid #E3E3E3;
    }
    td{
        padding: .8em .5em;
        border: 1px solid #E3E3E3;
        overflow: hidden;
        min-width: min-content;
    }
    tbody tr{
        transition: background-color .2s;
        cursor: pointer;
    }
    tbody tr:hover{
        background-color: rgba(0, 0, 0, 0.03)
    }
    .cell-left{
        text-align: left;
    }
    .greenCapsule{
        background-color: #d6ffcc;
        color: #00ad4e;
        padding: .2em .4em;
        border-radius: 1em;
    }
    .darkGreenCapsule{
        background-color: #d6ffcc;
        color: #00ad4e;
        border: 1px #00ad4e solid;
        padding: .2em .4em;
        border-radius: 1em;
    }
    .lightGreenCapsule{
        background-color: #e7ffd2;
        color: #00ad4e;
        padding: .2em .4em;
        border-radius: 1em;
    }
    .yellowCapsule{
        background-color: #fff5be;
        color: #ff6600;
        padding: .2em .4em;
        border-radius: 1em;
    }
    .redCapsule{
        background-color: #fdb3b3;
        color: #df0000;
        padding: .2em .4em;
        border-radius: 1em;
    }
    .darkRedCapsule{
        background-color: #fdb3b3;
        color: #df0000;
        border: 1px #df0000 solid;
        padding: .2em .4em;
        border-radius: 1em;
    }
    .lightRedCapsule{
        background-color: #ffe0e0;
        color: #ff1f1f;
        padding: .2em .4em;
        border-radius: 1em;
    }
    .orangeCapsule{
        background-color: #FFE5D6;
        color: #F36711;
        padding: .2em .4em;
        border-radius: 1em;
    }
    .grayCapsule{
        background-color: #cccccc;
        color: #414141;
        padding: .2em .4em;
        border-radius: 1em;
    }

.btn{
    padding: .2em 1em;
    margin-bottom: .4em;
    border-radius: .3em;
    color: white;
}
.btn:last-child{
    margin-bottom: 0em;
}
.redBtn{
  background-color: rgb(233, 110, 110);
}
.redBtn:hover{
  background-color: rgb(228, 70, 70);
}
.greenBtn{
  background-color: rgb(138, 187, 81);
}
.greenBtn:hover{
  background-color: rgb(122, 209, 22);
}
.disabled{
    background-color: lightgray;
}
.blink{
    background-color: rgb(255, 246, 220);
}
</style>