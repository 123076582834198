<template>
  <div>
    <div class="search-box">
      <input type="text" v-model="searchInput"
      :placeholder="searchPlaceHolder" id="search-input" class="search-input"
      @keydown="$event.code=='Enter'?search(searchInput):false">
      <div class="search-icons">
        <div class="gray-t-c" @click="search()">✖</div>
        <font-awesome-icon class="gray-t-c" :icon="['fas', 'search']" @click="search(searchInput)"/>
      </div>
    </div>
    <div v-for="product in locationInventory" :key="product.ID" class="prod-cont"
     :style="{
        borderColor: product.inventory?'lightgray':'#ffccad'
      }"
      @click="inventoryEnabled ? $router.push(`/agent/stock-count/${product.ID}`) :  false">
      
      <div class="prod-det-cont">
        <div class="prod-info l-t-a">
          <div class="lb-t-w prod-name">{{product.name}}</div>
          <div class="vs-t-s gray-t-c">{{product.sku}}</div>
          <table class="l-t-a s-t-s"  v-if="inventoryEnabled" >
            <tr>
              <td class="b-t-w">Quantity: </td><td class="ms-t-s gray-t-c">{{product.inventory?formatNumber(product.inventory.Quantity):'Unknown'}}</td>
              <td class="b-t-w">Price: </td><td class="ms-t-s gray-t-c">{{product.inventory?'RM'+formatNumber(product.inventory.Price):'Unknown'}}</td>
            </tr>
            <tr>
              <td class="b-t-w">Last Count: </td>
              <td class="gray-t-c" colspan="3"
              :style="{
                color: !product.inventory||!product.inventory.LastCounted?'#ff4747':formatDate(product.inventory.LastCounted)!=formatDate(new Date())?'#F36711':'gray'
                }">
                {{(product.inventory&&product.inventory.LastCounted)?formatDateTime(product.inventory.LastCounted):'NEVER'}}
              </td>
            </tr>
          </table>
        </div>
        <div><image-component :imgURL="product.image" :imgWidth="'5em'" :imgHeight="'5em'"></image-component></div>
      </div>
      <div v-if="inventoryEnabled" class="b-t-w prod-btn" :style="{
        background: !product.inventory||!product.inventory.LastCounted?'#ff4747':formatDate(product.inventory.LastCounted)!=formatDate(new Date())?'#F36711':'#48ac50'
      }">
        COUNT
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ImageComponent from '@/components/ImageComponent.vue'
export default {
  components: { ImageComponent },
  name: 'agentInventory',
  computed:{
    ...mapGetters({
      products: 'product/products',
      inventories: 'inventory/inventories',
      checkIn: 'checkIn/checkIn',
    }),
    inventoryEnabled(){
      return(process.env.VUE_APP_FEATURE_INVENTORY != 'false')
    }
  },
  data() {
    return {
      locationInventory: [],
      searchInput: '',
      searchPlaceHolder: null
    }
  },
  methods:{
    ...mapActions({
      getProducts: 'product/getProducts',
      getInventories: 'inventory/getInventories'
    }),
    addInventoriesToProducts(){
      this.locationInventory = []
      this.products.forEach(product=>{
        this.locationInventory.push({
          ...product,
          inventory: this.inventories.rows.find(inventory=>inventory.ProductID == product.ID)
        })
      })
    },
    keyPressHandler(event){
      // console.log(event.key)
      if(event.key){
        if(event.key=='Enter'&&document.activeElement.tagName!='INPUT'){
          this.search(this.searchInput)
        }
        else if (document.activeElement.tagName!='INPUT'){
          this.searchInput += event.key
        }
      }
    },
    async search(value){
      this.searchPlaceHolder = value
      this.searchInput = ''
      await this.getProducts({sku: value})
      this.addInventoriesToProducts()
    }
  },
  async mounted() {
    if(!this.products.length){
      await this.getProducts()
    }
    this.getInventories({locationID: this.checkIn.locationID})
    .then(res => {
      this.addInventoriesToProducts()
      })
    window.addEventListener("keyup", this.keyPressHandler, false)
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.keyPressHandler, false)
  },
}
</script>

<style scoped>
.prod-cont{
  width: 100%;
  background: white;
  margin-bottom: .8em;
  border-radius: .5em;
  overflow: hidden;
  border: 1px white solid;
}
.prod-name{
  width: 100%;
  border-bottom: 1px lightgray solid;
}
.prod-det-cont{
  display: flex;
  justify-content: space-between;
}
.prod-img{
  width: 5em;
}
.prod-info{
  width: 100%;
  padding: .2em .5em;
}

.prod-btn{
  width: 100%;
  color: white;
}
.search-input{
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
}
.search-box{
  width: 100%;
  background: lightgray;
  border-radius: 10em;
  margin-bottom: 1em;
  display: flex;
}
.search-icons{
  display: flex;
  align-items: center;
  margin-right: .3em;
}
.search-icons>*{
  margin: 0 .3em;
  cursor: pointer;
}
</style>