<template>
  <div>
    <nav-bar :selected="'redemptions'"></nav-bar>
    <body-cont>
      <BtnEnter class="ms-t-s csv-btn" v-on:clicked="downloadCSV">CSV Report <font-awesome-icon :icon="['fas', 'download']"/></BtnEnter>
      <div class="filter-box ms-t-s">
        <v-select class="filter-item m-t-s" placeholder="Sales Agent" v-model="selectedAgent" label='displayName' :options="salesAgents" @input="updateQuery({page:1, agentID: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Location" v-model="selectedLocation" label='name' :options="locations" @input="updateQuery({page:1, locationID: $event?$event.ID:null})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Region" v-model="selectedRegion" :options="region" @input="updateQuery({page:1, region: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="State"  v-model="selectedState" :options="state" @input="updateQuery({page:1, state: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="City"  v-model="selectedCity" :options="city" @input="updateQuery({page:1, city: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Account" v-model="selectedAccount" :options="account" @input="updateQuery({page:1, account: $event})"></v-select>
        <v-select class="filter-item m-t-s" placeholder="Giveaway Product" v-model="selectedGiveawayProduct" label='name' :options="giveawayProducts" @input="updateQuery({page:1, giveawayProductID: $event ? $event.ID : null})"></v-select>
      </div>
      <div v-if="redemptions && redemptions.rows">
      <div v-for="redemption in redemptions.rows" :key="redemption.ID" class="redemption-cont">
        <div class="redemption-header s-t-s gray-t-c">
          <div>{{redemption.ID}}</div>
          <div>{{formatDateTime(redemption.createdAt)}}</div>
        </div>
        <div class="redemption-body">
          <div>
            <table class="ms-t-s l-t-a">
              <tr>
                <td class="b-t-w">Sales Agent:</td>
                <td>{{redemption.salesAgent}}</td>
              </tr>
              <tr>
                <td class="b-t-w">Location:</td>
                <td>{{redemption.location}}</td>
              </tr>
              <tr v-if="redemption.remarks">
                <td class="b-t-w">Remarks:</td>
                <td>{{redemption.remarks}}</td>
              </tr>
            </table>
            <div class="products-cont">
              <div v-for="giveawayProduct in redemption.giveawayProducts" :key="giveawayProduct.ID">
                <div class="ms-t-s l-t-a"><span>{{giveawayProduct.giveawayProduct}}</span><span class="title-t-c"> ({{giveawayProduct.quantity}})</span></div>
              </div>
            </div>
          </div>
          <image-component :imgURL="redemption.image" imgHeight="100%" style="max-height: 5em; overflow: hidden;"></image-component>
          <button-with-confirm class="s-t-s del-btn" @confirmed="requestRemoveRedemption(redemption.ID)">Remove</button-with-confirm>
        </div>
      </div>
      <page-selector v-if="redemptions&&redemptions.count" :count="redemptions.count" :selectedPage="page" :pageLimit="pageLimit"
        @pageSelected="updateQuery({page: $event})"></page-selector>
      </div>
    </body-cont>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import utils from '@/lib/util';
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar.vue'
import ImageComponent from '@/components/ImageComponent.vue'
import PageSelector from '@/components/PageSelector'
import ButtonWithConfirm from '@/components/ButtonWithConfirm.vue';

export default {
  name: 'redemptions',
  components:{
    BodyCont,
    NavBar,
    ImageComponent,
    PageSelector,
    ButtonWithConfirm,
  },
  computed:{
    ...mapGetters({
      redemptions: 'redemption/redemptions',
      locations: 'location/locations',
      region: 'location/region',
      state: 'location/state',
      account: 'location/account',
      city: 'location/city',
      giveawayProducts: 'engagement/giveawayProducts',
    }),
  },
  data() {
    return {
      selectedAgent: null,
      selectedLocation: null,
      selectedRegion: null,
      selectedState: null,
      selectedCity: null,
      selectedAccount: null,
      selectedGiveawayProduct: null,
      salesAgents: [],
      pageLimit: 10,
      page: 1
    }
  },
  methods: {
    ...mapActions({
      getRedemptions: 'redemption/getRedemptions',
      getRedemptionsReport: 'redemption/getRedemptionsReport',
      removeRedemption: 'redemption/removeRedemption',
      getLocations: 'location/getLocations',
      getLocationKey: 'location/getKeys',
      fetchUsersByType: 'userProfile/fetchUsersByType',
      getGiveawayProducts: 'engagement/getGiveawayProducts',
    }),
    ...mapMutations({
      pushAlert: 'pushAlert',
    }),
    downloadCSV() {
      let filename = "redemptions" + utils.methods.formatDate(new Date()) + "_" + utils.methods.formatTime(new Date());
      filename = filename.replace(/-/g, "_").replace(/:/g, "_");
      this.getRedemptionsReport({...this.$route.query})
        .then((result) => {
          let rows = []
          result.rows.forEach(item => {
            item.giveawayProducts.forEach(giveawayProduct => {
              rows.push({
                ID: giveawayProduct.ID,
                submissionID: item.ID,
                image: item.image,
                remarks: item.remarks,
                locationID: item.locationID,
                location: item.location,
                city: item.city,
                state: item.state,
                region: item.region,
                account: item.account,
                agentID: item.agentID,
                salesAgent: item.salesAgent,
                managerID: item.managerID,
                giveawayProduct: giveawayProduct.giveawayProduct,
                quantity: giveawayProduct.quantity,
                createdAt: item.createdAt,
              })
            });
          });
          utils.methods.downloadCSV({ name: filename, data: rows });
        })
        .catch((error) => {
          console.log('error > ', error);
        });
    },
    updateQuery(newQuery){
      let query = {...this.$route.query}

      Object.keys(newQuery).forEach(queryKey => query[queryKey] = newQuery[queryKey] ?? undefined);
      this.$router.push({query}).catch(err=>{})
      this.page = query.page ?? 1
      query.limit = this.pageLimit
      query.offset = this.pageLimit*(this.page-1)
      delete query.page
      console.log('QUERY: ', query)
      this.getRedemptions(query)
    },
    async requestRemoveRedemption(redemptionID){
      await this.removeRedemption(redemptionID)
      .then(res => {
        console.log('success')
        this.pushAlert({text: res.message, type: 'success'})
        this.updateQuery({})
      })
      .catch(err => {
        this.pushAlert({text:err.response.data.message, type: 'fail'})
        this.updateQuery({})
      })
    }
  },
  async mounted() {
    this.updateQuery({})
    !this.locations?.length? await this.getLocations():false
    !this.region?.length?this.getLocationKey('account'):false
    !this.region?.length?this.getLocationKey('region'):false
    !this.state?.length?this.getLocationKey('state'):false
    !this.city?.length?this.getLocationKey('city'):false
    !this.giveawayProducts?.length?await this.getGiveawayProducts():false
    this.$route.query?.locationID ? this.selectedLocation = this.locations.find(location=>location.ID == this.$route.query.locationID) : null
    this.$route.query?.giveawayProductID ? this.selectedGiveawayProduct = this.giveawayProducts.find(giveawayProduct => giveawayProduct.ID == this.$route.query.giveawayProductID) : null
    this.selectedAccount = this.$route.query?.account
    this.selectedRegion = this.$route.query?.region
    this.selectedState = this.$route.query?.state
    this.selectedCity = this.$route.query?.city
    this.salesAgents = await this.fetchUsersByType('sales_agent')
    this.$route.query?.agentID ? this.selectedAgent = this.salesAgents.find(agent=>agent.ID == this.$route.query.agentID) : null
  },
}
</script>

<style scoped>
.filter-box{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.7em;
  margin: 1em 0;
  justify-content: flex-start;
}
.filter-item{
    width:16%;
    min-width: 15em;
    margin-right: 0.5em;
}
.redemption-cont{
  border: 1px lightgray solid;
  padding: .4em .8em;
  border-radius: .8em;
  margin-bottom: 1em;
  width: 100%;
  max-width: 80em;
  margin: 0 auto 1em auto;
  box-shadow: 0 .3em .5em rgba(0, 0, 0, 0.3);
  position: relative;
}
.redemption-header{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px lightgray solid;
  width: 100%;
}
.redemption-body{
  display: flex;
  justify-content: space-between;
}
.del-btn{
  position: absolute;
  bottom: .5em;
  right: .5em;
  background: white;
}
.products-cont{
  background: lightgray;
  padding: .3em;
  border-radius: .3em;
  min-width: 50vw;
}
.products-cont>div{
  width: 100%;
  background: white;
  padding: .15em .3em;
  border-radius: .3em;
  margin-bottom: .3em;
}
.products-cont>div:last-child{
  margin-bottom: 0;
}
@media only screen and (max-width: 600px) {
  .redemption-cont{
    display: inline-block;
    font-size: .7em;
  }
  .redemption-body{
    flex-direction: column;
  }
}
.csv-btn{
  float: right;
  margin-bottom: 1em;
}
</style>
