<template>
  <div>
    <NavBar :selected="'barcodes'"></NavBar>
    <BodyCont class="mini-form">
      <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
      <TextBox :initVal="code" class="input-box" :type="'text'" title="Item code:" v-on:valueChanged="code=$event" :placeHolder="'Ex: AT001'"></TextBox>
      <TextBox :initVal="model" class="input-box" :type="'text'" title="Item model:" v-on:valueChanged="model=$event" :placeHolder="'Ex: iPhone'"></TextBox>
      <br>
      <BtnSubmit :enabled="enableBtn&&code&&model" v-on:clicked="submitBarcode">{{btnText}}</BtnSubmit>
    </BodyCont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ErrorComment from '@/components/ErrorComment'
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar'

export default {
    name: 'add-barcode',
    components: {
        ErrorComment,
        BodyCont,
        NavBar,
    },
    data() {
        return {
            code: null,
            model: null,
            btnText: "Add Barcode",
            enableBtn: true,
            errorMessage: null,
        }
    },
    computed:{
        ...mapGetters({
            // myProfile: 'userProfile/myProfile'
        }),
        submitMethod(){
            return(this.$route.params.method)
        },
        referenceBarcodeID(){
            return(this.$route.params.barcodeID)
        },

    },
    methods:{
        ...mapActions({
            getBarcode: 'barcode/getBarcode',
            createBarcode: 'barcode/createBarcode',
            editBarcode: 'barcode/editBarcode',
        }),
        async submitBarcode(){
            this.btnText = "Submitting.."
            this.errorMessage = null
            this.enableBtn=false
            let barcodeData = {
                code: this.code,
                model: this.model
            }
            if(this.submitMethod == 'create'){
                await this.createBarcode(barcodeData)
                .then(res=>{
                    this.$router.push('/cms/barcode-scanners')
                })
                .catch(err=>{
                    this.errorMessage = err.response.data.message
                    this.btnText = "Add Barcode"
                    this.enableBtn = true
                })
            }
            else{
                await this.editBarcode({barcodeData, barcodeID: this.referenceBarcodeID})
                .then(res=>{
                    this.$router.push('/cms/barcode-scanners')
                })
                .catch(err=>{
                    console.log(err)
                    this.errorMessage = err.response.data.message
                    this.btnText = "Edit Barcode"
                    this.enableBtn = true
                })
            }
        }, 
    },
    async mounted(){
        if(this.submitMethod == 'edit'){
            this.btnText = 'Edit Barcode'
            await this.getBarcode(this.referenceBarcodeID)
            .then(referenceBarcode=>{
                this.code = referenceBarcode.code
                this.model = referenceBarcode.model
            })
            .catch(err=>{
                this.errorMessage = err.response.data.message
            })
        }
    }
}
</script>

<style scoped>
.mini-form{
    max-width: 40em;
}
.switch{
    text-align: left;
}
.dimension-input{
    width: 30%;
    padding-right: 1em;
}
.dimension-box{
    display: flex;
}
.input-box{
    margin-bottom: 1em;
}
.img-div{
display: flex;
width: 100%;
justify-content: space-between;
}
#imgshow{
    height: 2em;
    overflow: hidden;
}
</style>
