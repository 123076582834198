<template>
  <div id="img-comp">
    <div class="t-img-cont" @click="expanded=true, rotation = 0"
    :style="{
      width: imgWidth?imgWidth: '10em',
      height: imgHeight?imgHeight:imgWidth?imgWidth:'10em',
    }">
      <img class="t-img" :src="imgURL" alt="">
    </div>
    <transition name="fade">
      <div v-if="expanded" class="f-img-cont">
        <div class="f-img-back" @click="expanded=false"></div>
        <img class="f-img" ref="img" :src="imgURL" alt="" @click="expanded=false">
        <img class="rotate-btn" src="@/assets/menu-icons/return.svg" @click="rotateImg()"/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'image-component',
  props: ['imgURL', 'imgWidth', 'imgHeight'],
  data() {
    return {
      expanded: false,
      rotation: 0
    }
  },
  methods:{
    rotateImg(){
      console.log(this.rotation)
      this.rotation-=90
      this.$refs.img.style.transform = `rotate(${this.rotation}deg)`
      console.log(this.$refs.img.style.transform)
    }
  },
  mounted(){
    this.rotation = 0
  }
}
</script>

<style scoped>
#img-comp{
  user-select: none;
  
}
.t-img-cont{
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: auto;
}
.t-img{
  width: 100%
}
.f-img-cont{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.f-img-back{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
}
.f-img{
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  transition: transform 0.3s ease;
}
.rotate-btn{
  position: absolute;
  top: 0;
  cursor: pointer;
  color: white;
  padding: 1em;
}
</style>