import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import LoginWithToken from "../views/LoginWithToken.vue";

import Cms from "../views/Cms.vue";
import CMSMyProfile from "../views/cms/CMSMyProfile.vue";
import Users from "../views/cms/Users.vue";
import EditUser from "../views/cms/EditUser.vue";
import CreateUser from "../views/cms/CreateUser.vue";
import Locations from "../views/cms/Locations.vue";
import LocationForm from "../views/cms/LocationForm.vue";
import CMSInventory from "../views/cms/Inventory.vue";
import CMSSales from "../views/cms/Sales.vue";
import Products from "../views/cms/Products.vue";
import ProductForm from "../views/cms/ProductForm.vue";
import CheckIns from "../views/cms/CheckIns.vue";
import BarcodeScanners from "../views/cms/BarcodeScanners.vue";
import BarcodeForm from "../views/cms/BarcodeForm.vue";
import Engagements from "../views/cms/Engagements.vue";
import CMSRedemptions from "../views/cms/Redemptions.vue";

import Agent from "../views/Agent.vue";
import AgentMyProfile from "../views/agent/MyProfile.vue";
import CheckIn from "../views/agent/CheckIn.vue";
import CheckOut from "../views/agent/CheckOut.vue";
import AgentInventory from "../views/agent/Inventory.vue";
import AgentStockCount from "../views/agent/StockCount.vue";
import Sell from "../views/agent/Sell.vue";
import AgentEngagementForm from "../views/agent/EngagementForm.vue";
import AgentRedemptions from "../views/agent/Redemptions.vue";
import AgentRedemptionForm from "../views/agent/RedemptionForm.vue";
import AgentEngagements from "../views/agent/Engagements.vue";

axios.defaults.withCredentials = true;

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: "/login-with-token/:token",
    name: "LoginWithToken",
    component: LoginWithToken
  },
  {
    path: "/cms",
    component: Cms,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/",
        name: "home",
        component: Products,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "myProfile",
        name: "CMSMyProfile",
        component: CMSMyProfile,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "users",
        name: "users",
        component: Users,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "users/:type",
        name: "user type",
        component: Users,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "edit-user/:type/:id",
        name: "editUser",
        component: EditUser,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "check-ins",
        name: "check ins",
        component: CheckIns,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create-user",
        name: "create user",
        component: CreateUser,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "locations",
        name: "locations",
        component: Locations,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "location-form/:method",
        name: "create location",
        component: LocationForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "location-form/:method/:locationID",
        name: "edit location",
        component: LocationForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "products",
        name: "products",
        component: Products,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "product-form/:method",
        name: "create product",
        component: ProductForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "product-form/:method/:productID",
        name: "edit product",
        component: ProductForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "inventory",
        name: "CMSinventory",
        component: CMSInventory,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "sales",
        name: "CMSSales",
        component: CMSSales,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "barcode-scanners",
        name: "barcode scanners",
        component: BarcodeScanners,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "barcode-form/:method",
        name: "create barcode",
        component: BarcodeForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "barcode-form/:method/:barcodeID",
        name: "edit barcode",
        component: BarcodeForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "engagements",
        name: "engagements",
        component: Engagements,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "redemptions",
        name: "redemptions",
        component: CMSRedemptions,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/agent",
    component: Agent,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/",
        name: "agent",
        component: AgentEngagements,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "myProfile",
        name: "CMSMyProfile",
        component: AgentMyProfile,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "check-in",
        name: "Check In",
        component: CheckIn,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "check-out",
        name: "Check Out",
        component: CheckOut,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "inventory",
        name: "Inventory",
        component: AgentInventory,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "stock-count/:productID",
        name: "Stock Count",
        component: AgentStockCount,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "sell",
        name: "Sales",
        component: Sell,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "sell/:engagementID",
        name: "Sales",
        component: Sell,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "engagement",
        name: "Submit Engagement",
        component: AgentEngagementForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "engagement/:engagementID",
        name: "Edit Engagement",
        component: AgentEngagementForm,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "engagements",
        name: "Engagements",
        component: AgentEngagements,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "redemptions",
        name: "Redemptions",
        component: AgentRedemptions,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "redemption",
        name: "Giveaway Redemption",
        component: AgentRedemptionForm,
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

let validateSessoin = () => {
  return new Promise(resolve => {
    axios
      .get(`${process.env.VUE_APP_ROOT_API_URL}/checkcookie`, {
        withCredentials: true
      })
      .then(response => {
        resolve(true);
      })
      .catch(error => {
        resolve(false);
      });
  });
};

router.beforeEach(async (to, from, next) => {
  const session = await validateSessoin();
  console.log(to);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("session > ", session);
    if (!session) {
      console.log("NOT LOGGED IN");
      // Redirect to the Login Page
      next("/login");
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (session) {
      console.log("ALREADY LOGGED IN");
      // Redirect to the Login Page
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
