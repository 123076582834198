<template>
  <div id="home">
    <ErrorComment class="m-t-s" v-if="errorMessage">{{errorMessage}}</ErrorComment>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions} from 'vuex'
import ErrorComment from '@/components/ErrorComment'
export default {
  name: 'loginWithToken',
  components: {
    ErrorComment
  },
  computed:{
    token(){
      return(this.$route.params.token)
    }
  },
  data() {
    return {
      errorMessage: null,
    }
  },
  methods:{
    ...mapActions({
      login: 'login'
    })
  },
  async mounted(){
    
    const res = await this.login({
      userIdentifier: 'token',
      password: this.token,
    })
    .then(() => {
      this.$router.push('/')
    })
    .catch(err => {
      this.errorMessage = err.response.data.message
    })
  },

}
</script>

<style scoped>
</style>
