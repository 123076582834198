import dayjs from "dayjs";
import "dayjs/locale/ms-my";
const { Parser } = require("json2csv");

export default {
  methods: {
    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatNumber: function(amount) {
      try {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch (e) {
        return amount;
      }
    },
    formatMoney: function(amount) {
      try {
        amount = parseFloat(amount);
        return (
          "RM " +
          amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } catch (e) {
        return amount;
      }
    },
    formatQuantity: function(amount) {
      return amount > 5 ? amount + "+" : amount;
    },
    formatDate: function(date) {
      try {
        return dayjs(date).format("DD-MMM-YYYY");
      } catch (e) {
        return date;
      }
    },
    formatISODate: function(date) {
      try {
        return dayjs(date).format("YYYY-MM-DD");
      } catch (e) {
        return date;
      }
    },
    formatDateTime: function(date) {
      try {
        return dayjs(date).format("DD-MMM-YYYY hh:mm a");
      } catch (e) {
        return date;
      }
    },
    formatTime: function(date) {
      try {
        return dayjs(date).format("hh:mm:ss");
      } catch (e) {
        return date;
      }
    },
    fetchMonth: date => {
      return dayjs(date).format("MMM");
    },
    fetchDay: date => {
      return dayjs(date).format("D");
    },
    fetchDayName: date => {
      return dayjs(date).format("dddd");
    },
    getAge: date => {
      return dayjs().diff(date, "year");
    },
    getDaysInRange: (start, end) => {
      if (start && end) {
        const date1 = dayjs(end);
        console.log(date1);
        return date1.diff(start, "day");
      } else {
        return 0;
      }
    },
    getDateTomorrow: () => {
      return dayjs()
        .add(1, "day")
        .format("YYYY-MM-DD");
    },
    dateMonthCalc(date, months) {
      if (date && (months || months === 0)) {
        const date1 = dayjs(date);
        return date1.add(months, "month").format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    dateDayCalc(date, days) {
      if (date && (days || days === 0)) {
        const date1 = dayjs(date);
        return date1.add(days, "day").format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    isDateAfter(date1, date2) {
      return dayjs(date1).isAfter(dayjs(date2));
    },
    isSameDate(date1, date2) {
      return dayjs(date1).format("YYYYMMDD") === dayjs(date2).format("YYYYMMDD");
    },
    calcDuration(time1, time2) {
      time1 = dayjs("2020-01-01 " + time1);
      time2 = dayjs("2020-01-01 " + time2);
      time1.isAfter(time2) ? (time2 = time2.add(1, "day")) : false;
      return time2.diff(time1, "minute");
    },
    formatImage: img => {
      return new Promise(resolve => {
        let arr = img.dataUrl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        resolve(new File([u8arr], img.info.name, { type: mime }));
      });
    },
    getQueryStringFromObj: query => {
      if (!query) {
        return "";
      }
      let queries = [];
      for (let i = 0; i < Object.keys(query).length; i++) {
        if (Object.values(query)[i]) {
          queries.push(Object.keys(query)[i] + "=" + Object.values(query)[i]);
        }
      }
      if (queries.length) {
        return `?${queries.join("&")}`;
      } else {
        return "";
      }
    },
    getDistance(x1, x2, y1, y2) {
      var radlat1 = (Math.PI * x1) / 180;
      var radlat2 = (Math.PI * x2) / 180;
      var theta = y1 - y2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
    },
    formatDistance(distance) {
      if (distance > 1) return distance.toFixed(1) + " km Away";
      return (distance * 1000).toFixed(0) + " meter Away";
    },
    async downloadCSV(csvData) {
      const fileName = csvData.name + ".csv";
      try {
        const parser = new Parser({ fields: Object.keys(csvData.data[0]), excelStrings: true });
        const csv = parser.parse(csvData.data);

        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, fileName);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
};
