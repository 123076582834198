<template>
  <div>
    <nav-bar :selected="'users'"></nav-bar>
    <body-cont v-if="this.selectedUser" class="mini-form">
      <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
      <table class="w-100">
        <tr>
          <td class="b-t-w l-t-a">Display Name:</td>
          <td><text-box type="text" :initVal="displayName" v-on:valueChanged="displayName=$event" ></text-box></td>
        </tr>
        <tr v-if="selectedType == 'sales_agent' && myProfile.user_account.type == 'admin'">
          <td class="b-t-w l-t-a">Employer:</td>
          <td><v-select placeholder="manager" class="select-box m-t-s" v-model="selectedManager" :options="managers"
          label="displayName">
          </v-select></td>
        </tr>
      </table>
      <br>
      <btn-submit :enabled="displayName&&btnEnabled" @clicked="requestUpdateProfile" style="float: right;">Update</btn-submit>
      <BtnEnter v-if="!changePassword" v-on:clicked="changePassword = true" class="button" style="float: left;">Change Password</BtnEnter>
      <div v-if="changePassword" class="input-container">
        <div class="input-header">
          <span class="input-title">Change Password</span>
          <span class="input-close" @click="changePassword = false; password=null">✖</span>
        </div>
        <TextBox :type="'password'" title="Password:" v-on:valueChanged="password=$event" :min=6 :placeHolder="'Enter a password'"></TextBox>
        <TextBox :type="'password'" title="Confirm Password:" v-on:valueChanged="confirmPassword=$event" :min=6 :placeHolder="'Re-Enter password'"></TextBox>
        <BtnSubmit :enabled="enableUpdatePasswordButton" v-on:clicked="requestChangeUserPassword" class="button">Update Password</BtnSubmit>
      </div>
    </body-cont>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ErrorComment from '@/components/ErrorComment'
import BodyCont from '@/components/BodyCont'
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'users',
  components:{
    BodyCont,
    NavBar,
    ErrorComment,
  },
  computed:{
    ...mapGetters({
      userTypes: 'userProfile/userTypes',
      users: 'userProfile/users',
      myProfile: 'userProfile/myProfile',
      selectedUser: 'userProfile/selectedUser',
    }),
    enableUpdatePasswordButton() {
      return (this.password && this.password.length && this.confirmPassword == this.password && this.passwordBtnEnabled);
    },
    selectedType(){
      return(this.$route.params.type)
    },
    selectedID(){
      return(this.$route.params.id)
    },
  },
  data() {
    return {
      displayName: null,
      managers: [],
      selectedManager: null,
      errorMessage: null,
      btnEnabled: true,
      changePassword: false,
      password: null,
      confirmPassword: null,
      passwordBtnEnabled: true,
    }
  },
  methods: {
    ...mapActions({
      getUserTypes: 'userProfile/getUserTypes',
      fetchUsersByType: 'userProfile/fetchUsersByType',
      fetchUserByTypeAndID: 'userProfile/fetchUserByTypeAndID',
      updateUserProfile: 'userProfile/updateUserProfile',
      changeUserPassword: 'userProfile/changeUserPassword',
    }),
    async requestUpdateProfile(){
      this.btnEnabled = false
      await this.updateUserProfile({
        type: this.selectedType,
        ID: this.selectedID,
        data: {
          displayName: this.displayName,
          managerID: this.selectedManager?.ID
        }
      })
      .then(()=>{
        this.$router.push('/cms/users/'+this.selectedType)
      })
      .catch(err=>{
        this.errorMessage = err.response.data.message
        this.btnEnabled = true
        window.scroll(0,0)
      })
    },
    async requestChangeUserPassword(){
      this.passwordBtnEnabled = false
      let passwordDetails = {
        type: this.selectedType,
        profileID: this.selectedID,
        password: this.password,
      }
      await this.changeUserPassword(passwordDetails)
      .then(()=>{
        this.$router.push('/cms/users/'+this.selectedType)
      })
      .catch(err=>{
        console.log(err)
        this.passwordBtnEnabled = true
        this.errorMessage =err.response.data.message
      })
    },
  },
  async mounted() {
    await this.fetchUserByTypeAndID({type: this.selectedType, ID: this.selectedID})
    if(this.selectedType == 'sales_agent' && this.myProfile.user_account.type == 'admin'){
      this.managers = await this.fetchUsersByType('manager')
      this.selectedManager = this.managers.find(manager=>{return(manager.ID == this.selectedUser.managerID)})
    }
    this.displayName = this.selectedUser.displayName
  },
}
</script>

<style scoped>
.mini-form{
    max-width: 40em;
}
.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.input-container>*{
  margin-bottom: 1em;
}
.input-header {
  font-size: 24px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  width: 100%;
  border-bottom: 1px solid;
  justify-content: flex-start;
  margin: 5px;
}

.input-close {
  margin-left: auto;
}

.input-close:hover {
  cursor: pointer;
}

</style>