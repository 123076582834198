<template>
    <div id='icon-cont' :class="selected?'selected':'unselected'">
        <img class="icon" :src="require(`@/assets/nav-icons/${navIcon}`)" alt="">
        <div class="icon-txt only-large">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props:['navIcon', 'selected']
}
</script>

<style scoped>
    #icon-cont{
        padding: 1em; 
        height: 100%;
        display: flex;
    }
    #icon-cont:hover{
        color: #F36711;
    }
    .icon{
        max-height: 1.5em;
    }
    .selected{
        color: #F36711;
        background-color: #fff4eb;
    }
    .icon-txt{
        padding-left: 1em;
    }
</style>