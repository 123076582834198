<template>
    <div id="popup-full-screen">
        <div  @click="$emit('exit')" class="back-div"></div>
        <div class="front-div">
            <div class="top-title vl-t-s white-t-c">
                {{title}}
                <div class="close-btn vl-t-s" @click="$emit('exit')">✖</div>
            </div>
            <div class="pop-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'popup-full-screen',
    props: ['title']
}
</script>
<style scoped>
    #popup-full-screen{
        z-index: 90;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
    .back-div{
        background-color: rgba(0, 0, 0, .3);
        width: 100%;
        height: 100%;
        
    }
    .top-title{
        height: 2em;
        background-color: #0D1A48;
        padding: 0.3em;
    }
    .front-div{
        width: 90vw;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        max-height: 80vh;
        max-width: 40em;
        border-radius: 10px;
        overflow: hidden;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    }
    .close-btn{
        float: right;
        margin-top: -0.15em;
        cursor: pointer;
    }
    .pop-content{
        width: 100%;
        padding: 1em;
        overflow: auto;
    }

</style>