<template>
  <div class="home">
    <top-bar></top-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'cms',
  computed:{
    ...mapGetters({
      myProfile: 'userProfile/myProfile'
    })
  },
  async mounted(){
    if(this.myProfile.user_account.type ==  'sales_agent'){
      this.$router.push('/agent')
    }
  }
}
</script>

<style scoped>
</style>