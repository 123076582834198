<template>
  <div class="sell-page">
    <ErrorComment v-if="errorMessage">{{errorMessage}}</ErrorComment>
    <v-select v-if="products" placeholder="Product" class="select-box ms-t-s"
    label="sku" v-model="selectedProduct" :options="products"
    @input="productSelected"
    style="width: 100%;">
      <template v-slot:option="option">
        <div class="m-t-s">{{ option.name }}</div>
        <div class="ms-t-s gray-t-c">{{option.sku}}</div>
      </template>
    </v-select>
    <div class="sale-cont">
      <transition-group name="open-horz" tag="p">
        <div v-for="(item,index) in cart" :key="item.ID"
        class="cart-item-cont">
          <div class="del-btn" @click="cart.splice(index, 1)">✖</div>
          <div class="cart-item">
            <image-component :imgURL="item.image" :imgWidth="'3.5em'" :imgHeight="'3.5em'"></image-component>
            <div style="width: 100%; padding-left: .5em">
              <div class="title-t-c l-t-a lb-t-w ms-t-s">{{item.name}}</div>
              <div class="cart-item-nums">
                <div><span class="lb-t-w ms-t-s">In stock: </span><span></span><span class="ms-t-s">{{item.stockQuantity}}</span></div>
                <div><span class="lb-t-w ms-t-s">Price: </span><span class="s-t-s gray-t-c">RM</span><input class="item-price" type="number" v-model="item.price"></div>
              </div>
            </div>
          </div>
          <div class="cart-item-calc">
            <counter v-model="item.soldQuantity"></counter>
            <div class="title-t-c" style="padding-right: .2em">RM {{(item.soldQuantity*item.price).toFixed(2)}}</div>
          </div>
        </div>
      </transition-group>
      <div class="b-t-w l-t-a m-t-s">Remarks</div>
      <textarea v-model="remarks" class="remarks-input"/>
    </div>
    <div class="action-bar">
      <btn-submit :enabled="btnEnabled&&cart.length" @clicked="requestSell()">Submit</btn-submit>
      <div class="title-t-c l-t-s">RM{{totalPrice.toFixed(2)}}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ErrorComment from '@/components/ErrorComment'
import Counter from '@/components/Counter.vue'
import ImageComponent from '@/components/ImageComponent.vue'

export default {
  name: 'sell',
  components:{
    ErrorComment,
    Counter,
    ImageComponent,
  },
  computed:{
    ...mapGetters({
      checkIn: 'checkIn/checkIn',
      products: 'product/products',
    }),
    totalPrice(){
      let total = 0
      this.cart.forEach(item => {
        total +=( item.price * item.soldQuantity)
      });
      return(total)
    },
    engagementID(){
      return(this.$route.params.engagementID)
    }
  },
  data() {
    return {
      errorMessage: null,
      selectedProduct: null,
      cart: [],
      btnEnabled: true,
      searchInput: '',
      remarks: null,
    }
  },
  methods:{
    ...mapActions({
      getProducts: 'product/getProducts',
      getStock: 'inventory/getStock',
      sell: 'sale/sell',
    }),
    ...mapMutations({
      pushAlert: 'pushAlert',
    }),
    async productSelected(product){
      let cartIndex = this.cart.findIndex(item => item.ID == product.ID)
      if(cartIndex == -1){
        let stock = {
          ...product,
          price: null,
          stockQuantity: 0,
          soldQuantity: 1
        }
        this.getStock({locationID: this.checkIn.locationID, productID: product.ID})
        .then(res=>{
          stock.price = res.price
          stock.stockQuantity = res.quantity
        })
        .catch(()=>{
          stock.price = 0
          stock.stockQuantity = 0
        })
        this.cart.unshift(stock)
      }
      else{
        this.cart[cartIndex].soldQuantity++;
      }
      this.selectedProduct = null
    },
    requestSell(){
      this.btnEnabled = false
      let saleData = {
        sales: [],
        remarks: this.remarks,
        engagementID: this.engagementID,
      }
      this.cart.forEach(item => {
        saleData.sales.push({
          quantity: item.soldQuantity,
          price: item.price || 0,
          productID: item.ID,
          remarks: null,
        })
      });
      this.sell(saleData)
      .then(res=>{
        this.pushAlert({text: 'Sale submited', type: 'success'})
        this.errorMessage = null
        this.selectedProduct = null
        this.cart = []
        this.btnEnabled = true
        this.searchInput = ''
        this.remarks = null
        // this.$router.push('/agent/inventory')
      })
      .catch(err => {
        this.errorMessage = err.response.data.message
        window.scroll(0,0)
        this.btnEnabled = true
      })
    },
    keyPressHandler(event){
      if(event.key){
        if(event.key=='Enter'&&document.activeElement.tagName!='INPUT'){
          let selectedProduct = this.products.find(product=>{
            return(product.sku.indexOf(this.searchInput)!=-1)
          })
          this.productSelected(selectedProduct)
          this.searchInput = ''
        }
        else if (document.activeElement.tagName!='INPUT'){
          this.searchInput += event.key
        }
      }
    },
  },
  async mounted() {
    await this.getProducts()
    window.addEventListener("keyup", this.keyPressHandler, false)
  },
  beforeDestroy(){
    window.removeEventListener("keyup", this.keyPressHandler, false)
  },
}
</script>

<style scoped>

.sale-cont{
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 1em;
  overflow: auto;
}
.cart-item-cont{
  margin: 1em 0;
  position: relative;
}
.del-btn{
  background: #ff4747;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 .3em;
  border-radius: 0 0.3em 0 0.3em;
}
.cart-item{
  display: flex;
  box-shadow: 0 0 .2em rgba(0, 0, 0, 0.5);
  border-radius: .5em;
  background: white;
  overflow: hidden;
  padding: .5em;
}
.item-price{
  padding: 0;
  width: 3em;
  border: 1px lightgray solid;
  padding: 0 .1em;
}
.cart-item-nums{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cart-item-calc{
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: white;
  width: calc(100% - .5em);
  margin: auto;
  box-shadow: 0 0 .2em rgba(0, 0, 0, 0.5);
  padding: .2em;
  border-radius: 0 0 .5em .5em;
}
.action-bar{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background: white;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
}
.remarks-input{
  width: 100%;
  border: 1px lightgray solid;
  border-radius: .3em;
  height: 4em;
}


.open-horz-enter-active {
  transition: all .2s ease;
}
.open-horz-leave-active {
  transition: all .2s ease;
}
.open-horz-enter, .open-horz-leave-to{
  transform: translateX(30px);
  opacity: 0;
}
</style>